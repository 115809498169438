import React from "react";
import "./Message.scss";

export default function Message(props: MessageProperties) {
    return (
        <>
            <div className="messageDiv" style={{marginLeft: "25%", marginRight: "25%", marginTop: "5%"}}>
                <h5>{props.message}</h5>
            </div>
            {props.redirectUrl &&
                <div className="messageDiv">
                    <br />
                    <br />
                    <a className="btn btn-primary" href={props.redirectUrl}>{props.redirectText}</a>
                </div>
            }
        </>
    )
}

interface MessageProperties{
    message: string;
    redirectText?: string;
    redirectUrl?: string;
}