import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Button, Card } from "react-bootstrap";
import UserEntry from "../../../utils/UserEntry";
import { activateUserAPI, deleteUserAPI, getEmployeesForAdminAPI } from "../../../core/apiFunctions";
import { userDetailsForAdminDTO } from "../../../core/dto/admin.models";
import "./Admin.scss";
import { AiFillLinkedin, AiFillHome } from "react-icons/ai";
import { FaXingSquare } from "react-icons/fa";
import { convertDateTimeToDate } from "../../../core/helperFunctions";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import useFeedback from "../../../utils/useFeedback";
import { Title } from "../../../config/Types/GeneralEnumDefinitions";
import { State } from "../../../config/Types/PlaceEnumDefinitions";
import { enumToString } from "../../../core/helperConst";

export default function EmployeeManagement() {

    const [employees, setEmployees] = useState<userDetailsForAdminDTO[]>([]);
    const [stats, setStats] = useState({ activeUsers: 0, inactiveUsers: 0 });
    const { showSuccess, showError }  = useFeedback(); 

    const { t } = useTranslation();

    function updateStats(employees: userDetailsForAdminDTO[]) {
        const activeUsers = employees.filter(e => e.isActive).length;
        const inactiveUsers = employees.length - activeUsers;
        setStats({ activeUsers, inactiveUsers });
    }

    useEffect(() => {
        getEmployeesForAdminAPI().then((response) => {
            const sortedEmployees = response.data.sort((a, b) =>
                // sort by createdDate
                new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
            );
            setEmployees(sortedEmployees);

            // Calculate stats
            updateStats(sortedEmployees);
        });
    }, []);

    // Update stats when employees change
    useEffect(() => {
        updateStats(employees);
    } , [employees]);

    const exportToExcel = () => {
        try {
            // Create a sorted copy for exporting
            const sortedCopy = [...employees].sort((a, b) =>
                // sort alphabetically by last name
                a.lastName.localeCompare(b.lastName)
    );
            // Create a new workbook
            const workbook = XLSX.utils.book_new();
         // Create a new worksheet and map employees data to exclude unwanted properties
         const worksheet = XLSX.utils.json_to_sheet(
            sortedCopy.map((employee) => ({
                createdDate: employee.createdDate,
                title: enumToString(Title, employee.title),
                lastName: employee.lastName,
                firstName: employee.firstName,
                dateOfBirth: employee.dateOfBirth,
                nationality: employee.nationality,
                state: enumToString(State, employee.state),
                residencePermit: employee.residencePermit,
                email: employee.email,
                phoneNumber: employee.phoneNumber,
                mobileNumber: employee.mobileNumber,
                linkedIn: employee.linkedIn,
                xing: employee.xing,
                website: employee.website,
                isProfileComplete: employee.isProfileComplete,
                isActive: employee.isActive,
                isPublic: employee.isPublic,
                notificationsEnabled: employee.notificationsEnabled,
            }))
        );
            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, "Employees");
            // Save the workbook as an Excel file
            XLSX.writeFile(workbook, "employees.xlsx");
            showSuccess(t("successfullySaved"));
        } catch (error) {
            showError(t("errorOccuredWhileExporting"));
        }
    };

    return (
        <>
            <div className="text-end mt-3">
                <Button className="btn btn-primary" onClick={exportToExcel}>
                    {t("exportToExcel")} 
                </Button>
            </div>
            <h5>Employee Management</h5>
            <div>
                <h6>Total Users: {employees.length}</h6>
                <h6>Active Users: {stats.activeUsers}</h6>
                <h6>Inactive Users: {stats.inactiveUsers}</h6>
            </div>

            <Accordion className="admin">
                {employees.map((employee) => (
                  <Card key={(employee as userDetailsForAdminDTO).id} className="mb-4 shadow bg-secondary rounded-3">
                    <Accordion.Item eventKey={employee.id}>
                        <Accordion.Header>
                            <UserEntry user={employee} displayMail={true} displayPhone={true}
                                displayChat={true} displayActivationStatus={true} />
                        </Accordion.Header>
                        <Accordion.Body style={{ padding: "0" }}>
                            <div className="companiesAndUserDetailsList">
                                <div>
                                    <div className="deleteUserBtn">
                                        <Button
                                            variant="outline-danger"
                                            onClick={(e) => {
                                                e.stopPropagation();

                                                // Display a confirmation dialog
                                                if (window.confirm('Are you sure you want to delete this user?')) {
                                                    deleteUserAPI(employee.id);
                                                    setEmployees(employees.filter((e) => e.id !== employee.id));
                                                }
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTrash} /> Delete
                                        </Button>
                                    </div>

                                    <div className="form-check form-switch public-toggle" style={{ width: 150 }}>
                                        <input className="form-check-input mb-1" type="checkbox" id="flexSwitchCheckDefault"
                                            checked={employee.isActive} onChange={(e) => {
                                                e.stopPropagation();
                                                activateUserAPI(employee.id, !employee.isActive);
                                                employee.isActive = !employee.isActive;
                                                setEmployees([...employees]);
                                            }} />
                                        <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault">
                                            {employee.isActive ? t("Active") : t("Inactive")}
                                        </label>
                                    </div>
                                </div>

                                <div> {employee.dateOfBirth && <> <h6>Date of birth</h6> <p>{convertDateTimeToDate(employee.dateOfBirth)}</p> </>} </div>
                                <div> {employee.nationality && <> <h6>Nationality</h6> <p>{employee.nationality}</p> </>} </div>
                                <div> {employee.mobileNumber && <> <h6>Mobile number</h6> <p>{employee.mobileNumber}</p> </>} </div>
                                <div>
                                    <h6>Websites</h6>
                                    <p>
                                        {employee.linkedIn && <> <AiFillLinkedin /> <Link to={employee.linkedIn}>{employee.linkedIn}</Link> <br /> </>}
                                        {employee.website && <> <AiFillHome /> <Link to={employee.website}>{employee.website}</Link> <br /> </>}
                                        {employee.xing && <> <FaXingSquare /> <Link to={employee.xing}>{employee.xing}</Link> <br /> </>}
                                    </p>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                  </Card>
                ))}
            </Accordion>
        </>
    )
}