import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { workExperienceDTO } from '../../core/dto/user.models';
import { getWorkExperienceAPI } from '../../core/apiFunctions';

export default function EmployeeWorkExperience(props: EmployeeWorkExperienceProperties) {

    const [workExperience, setWorkExperience] = useState<workExperienceDTO[]>();
    const { t } = useTranslation();
    //const sample = [{"id":"d2234752-6712-4caa-83d9-74c1b208a98b","jobTitle":"Manager","company":"TDC","address":"Jöchlerweg 4, 6340 Baar","startDate":"2023-02-14","endDate":"2023-02-27"},{"id":"e0ef3d8b-4d08-44c9-9c5d-d0d31af55203","jobTitle":"Juristin","company":"Gericht","address":"Bezirksgericht","startDate":"2023-02-01","endDate":"2023-02-04"}];

    useEffect(() => {
        const fetchWorkExperience = async () => {
            const workExperience = await getWorkExperienceAPI(props.userId);
            setWorkExperience(workExperience.data);
        }
        fetchWorkExperience();
        //setWorkExperience(sample);

    }, []);


    return (
        <>
        <div className="cvCard">
            <div className="cvCardHeader">
                <p>{t('workExperience')}</p>
            </div>
            <div>
            {workExperience?.map((work, index) => {
                const dotDimentinos = (index+1)*5 >= 25? 25: (index+1)*5;

                return (
                    <div key={index} className="cvTimeLine">
                        <div>
                            <div></div>
                            <div className='cvTimeLineDot' style={{ width: `calc(40px - ${dotDimentinos}px)`, height: `calc(40px - ${dotDimentinos}px)` }}></div>
                            <div></div>
                        </div>
                        <div>
                            <p>{new Date(work.startDate).getMonth() + 1}/{new Date(work.startDate).getFullYear()} - {new Date(work.endDate).getMonth() + 1}/{new Date(work.endDate).getFullYear()}</p>
                            <p>{work.jobTitle}</p>
                            <p>{work.company}, {work.address}</p>
                        </div>
                    </div>
                )
            })}
            </div>
        </div>
        </>
    )
}

interface EmployeeWorkExperienceProperties {
    userId: string;
}