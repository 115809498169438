import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup'
import FormikTextField from '../utils/FormikTextField';
import React from 'react';
import DisplayErrors from '../utils/DisplayErrors';
import { Button } from 'react-bootstrap';
import { requestPasswordResetAPI } from '../core/apiFunctions';
import useFeedback from '../utils/useFeedback';
import { FeedbackMode } from '../config/Types/GeneralEnumDefinitions';

export default function RequestNewPasswordContent({ handleCloseModal }: PasswordChangeContentProps) {
    const { t } = useTranslation();
    const [errors, setErrors] = useState<string[]>([]);

    const { showSuccess } = useFeedback();

    const validationSchema = yup.object({
        email: yup.string()
            .required(t("emailRequired"))
            .email(t("emailNotValid"))
    });

    async function submitPasswordChange(values: { email: string; }) {
        try {
            const response = await requestPasswordResetAPI(values.email);
            if (response.status === 200) {
                handleCloseModal();
                showSuccess(t("passwordResetRequested"), FeedbackMode.Success, 10000);
            }
            else {
                setErrors(response.data);
            }
        }
        catch (error: any) {
            if (error && error.response) {
                setErrors(error.response.data);
            }
        }
    }

    return (
        <Formik
            initialValues={{ email: '' }}
            onSubmit={async (values) => {
                await submitPasswordChange(values);
            }}
            validationSchema={validationSchema}
            validateOnBlur={false}
        >

            {() => (
                <Form>
                    <FormikTextField displayName={t("email")} field="email" type="text" autoFocus={true} />
                    <DisplayErrors errors={errors} />
                    <div className="buttons">
                        <Button className="btn btn-primary me-3" type="submit">{t("requestPassword")}</Button>
                        <Button className="btn btn-secondary" onClick={handleCloseModal}>{t("cancel")}</Button>
                    </div>
                </Form>
            )}

        </Formik>

    );
}

interface PasswordChangeContentProps {
    handleCloseModal: () => void;
}