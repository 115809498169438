import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function TextField(props: textFieldProps) {

    const [fieldText, setFieldText] = React.useState<string>();
    const { t } = useTranslation();

    React.useEffect(() => {
        setFieldText(props.value);
    }, [props.value]);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange(e);
            setFieldText(e.target.value);
        }
    }

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (props.onBlur) {
            setFieldText(e.target.value);
            props.onBlur(e);
        }
    }

    const handleOnClick = (e: React.MouseEvent<HTMLInputElement>) => {
        if (props.onClick) {
            props.onClick(e);
        }
    }

    return (
        <>
            <div className={`${props.class} ${props.formFloating ? 'form-floating' : ''}`}>
                {
                    props.displayName && !props.formFloating &&
                    <label htmlFor={props.field}>{t(props.displayName)}</label>
                }
                {
                    props.icon &&
                    <img src={props.icon} alt={props.iconAlt} />
                }


                <Form.Control disabled={props.disabled} onBlur={handleOnBlur} type={props.type} className="form-control" id={props.field} name={props.field}
                    onChange={handleOnChange} value={fieldText} as={props.as} placeholder={props.placeholder} onClick={handleOnClick}
                    style={{ height: props.as === 'textarea' ? props.height : '' }} />


                {props.formFloating && props.displayName && <label htmlFor={props.field}>{t(props.displayName)}{props.isMandatory === true ? <b> *</b> : ""}</label>}
            </div>
            {props.validationMessage && <label className="text-danger">{props.validationMessage}</label>}
        </>
    )
}

interface textFieldProps {
    field: string;
    as?: 'textarea',
    height?: string;
    placeholder?: string;
    displayName?: string;
    icon?: string;
    iconAlt?: string;
    formFloating: boolean;
    type: 'text' | 'password' | 'date';
    class: string;
    value?: string;
    disabled?: boolean;
    onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
    onBlur?(event: React.FocusEvent<HTMLInputElement>): void;
    validationMessage?: string;
    isMandatory?: boolean;
    onClick?(event: React.MouseEvent<HTMLInputElement>): void;
}

TextField.defaultProps = {
    type: 'text',
    formFloating: false,
    class: 'mb-3',
    isRequired: false,
    isMandatory: false,
    disabled: false
}