import React, { useEffect, useState } from "react";
import { submitFileAPI } from "../../core/apiFunctions";
import { FileType } from "../../config/Types/GeneralEnumDefinitions";
import Button from "../../utils/Button";
import { useTranslation } from "react-i18next";
import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fireEvent } from "@testing-library/react";
import { fileUploadDTO } from "../../core/dto/user.models";
import DisplayErrors from "../../utils/DisplayErrors";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function ProfileHeader(props: ProfileHeaderProps) {

    const { t } = useTranslation();

    const [headerPhoto, setHeaderPhoto] = useState<string>();
    const [headerFile, setHeaderFile] = useState<File>();

    const [profilePhoto, setProfilePhoto] = useState<string>();
    const [profileFile, setProfileFile] = useState<File>();

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [newHeaderPhoto, setNewHeaderPhoto] = useState<boolean>(false);
    const [newProfilePhoto, setNewProfilePhoto] = useState<boolean>(false);

    const [isUploading, setIsUploading] = useState<boolean>(false);

    const [errors, setErrors] = useState<string[]>([]);

    const setProfilePhotoFromServer = () => {
        props.apiCallToLoadProfile()
            .then((response) => {
                const url = URL.createObjectURL(response.data);
                setProfilePhoto(url);
                if (props.profilePhotolocalStoreKey) {
                    localStorage.setItem(props.profilePhotolocalStoreKey, url);
                    // TODO: only works in dev mode, not in prod
                    // fireEvent(window, new StorageEvent(props.profilePhotolocalStoreKey));
                }
            })
    }

    const setOrigValues = () => {
        // reset profile
        if (props.profilePhotolocalStoreKey) {
            setProfilePhoto(localStorage.getItem(props.profilePhotolocalStoreKey)!);
        }
        else {
            setProfilePhotoFromServer();
        }

        // reset header
        setHeaderPhotoFromServer();

        setIsEditing(false);
        setNewHeaderPhoto(false);
        setNewProfilePhoto(false);

    }

    const setHeaderPhotoFromServer = () => {
        props.apiCallToLoadHeader()
            .then((response) => {
                const url = URL.createObjectURL(response.data);
                setHeaderPhoto(url);
            })
    }

    const openUploadDialog = (
        setNewImageFlag: (status: boolean) => void,
        setFile: (file: File) => void,
        setPhoto: (photo: string) => void
    ) => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.onchange = (e: any) => {
            const file = e.target.files[0];
            if (validate(file)) {
                setIsEditing(true);

                // depending on the parameters, set the corresponding image
                setPhoto(URL.createObjectURL(file));
                setFile(file);
                setNewImageFlag(true);
            }
        };

        input.click();
    };

    const validate = (file: any) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            alert('You can only upload JPG/PNG file!');
            return false;
        }

        const isLt25M = file.size / 1024 / 1024 < 25;
        if (!isLt25M) {
            alert('Image must smaller than 25MB!');
            return false;
        }
        return isJpgOrPng && isLt25M;
    }

    const upload = () => {
        setIsUploading(true);
        if (newHeaderPhoto) {
            const fileUploadDto = { file: headerFile!, type: props.headerPhotoType }

            try {
                submitFileAPI(fileUploadDto)
                    .then(() => {
                        setHeaderPhotoFromServer();
                        setNewHeaderPhoto(false);
                    })
            } catch (error: any) {
                setErrors(error);
            }
        }

        if (newProfilePhoto) {
            const fileUploadDto = { file: profileFile!, type: props.profilePhotoType }

            try {
                submitFileAPI(fileUploadDto)
                    .then(() => {
                        setProfilePhotoFromServer();
                        setNewProfilePhoto(false);
                    })
            } catch (error: any) {
                setErrors(error);
            }
        }
    }

    useEffect(() => {
        // load header
        setHeaderPhotoFromServer();

        // load profile
        if (localStorage.getItem(props.profilePhotolocalStoreKey!) !== null) {
            setProfilePhoto(localStorage.getItem(props.profilePhotolocalStoreKey!)!);
        }
        else {
            setProfilePhotoFromServer();
        }

    }, []);

    useEffect(() => {
        if (!newHeaderPhoto && !newProfilePhoto) {
            setIsUploading(false);
            setIsEditing(false);
        }
    }, [newHeaderPhoto, newProfilePhoto])

    return (
        <>
            <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="tooltip">{t("recommendedMinHeader")}</Tooltip>}
            >
            <img src={headerPhoto} className="header-picture" alt="header"
                onClick={() => openUploadDialog(setNewHeaderPhoto, setHeaderFile, setHeaderPhoto)} /> 
            </OverlayTrigger>    
            <div className="row-center">
                <div className="image"
                    onClick={() => openUploadDialog(setNewProfilePhoto, setProfileFile, setProfilePhoto)}>
                    <img src={profilePhoto} className="image_img" alt="avatar" />
                    <div className="image_overlay image_overlay-primary">
                        <p className="image_description">
                            {t("uploadPhoto")}
                        </p>
                    </div>
                </div>
            </div>
            <div className="row-center mt-4">

                <Button class="btn btn-primary" hidden={!isEditing} type="button" onClick={upload} disabled={isUploading}>
                    {isUploading ?
                        <div className="spinner-border spinner-border-sm" role="status" />
                        :
                        <FontAwesomeIcon icon={faSave} />
                    } {t("save")}
                </Button>

                <Button class="btn btn-danger ms-3" hidden={!isEditing} type="button"
                    onClick={setOrigValues}>
                    <FontAwesomeIcon icon={faUndo} /> {t('cancel')}
                </Button>
            </div>

            <DisplayErrors errors={errors} />
        </>
    )
}


interface ProfileHeaderProps {
    profilePhotolocalStoreKey?: string;

    apiCallToLoadProfile: () => Promise<any>,
    profilePhotoType: FileType,

    apiCallToLoadHeader: () => Promise<any>,
    headerPhotoType: FileType,

    apiCallToSubmitFile: (dto: fileUploadDTO) => Promise<any>,
}