import React from "react"
import { useTranslation } from 'react-i18next';

export default function ContentPrivacyPolicy() {
    const { t } = useTranslation();
    return (
        <>
            <h5><strong>{t("titlePrivacyPolicy")}</strong></h5>
            <br />
            <br />
            <p>{t("contentPrivacyPolicy")}</p>
            <br />
            <h5><strong>{t("titleGeneralDataProcecssing")}</strong></h5>
            <br />
            <h5><strong>{t("titleScopePersonalDataProcessing")}</strong></h5>
            <br />
            <p>{t("contentScopePersonalDataProcessing")}</p>
            <br />
            <h5><strong>{t("titlePurposeLegalBasisProcessingPersonalData")}</strong></h5>
            <br />
            <p>{t("contentPurposeLegalBasisProcessingPersonalData")}</p>
            <br />
            <h5><strong>{t("titleCategoriesPersonalDataOrigin")}</strong></h5>
            <br />
            <p>{t("contentCategoriesPersonalDataOrigin")}</p>
            <br />
            <h5><strong>{t("titleTransmissionThirdCountries")}</strong></h5>
            <br />
            <p>{t("contentTransmissionThirdCountries")}</p>
            <br />
            <h5><strong>{t("titleDataDeletionStorage")}</strong></h5>
            <br />
            <p>{t("contentDataDeletionStorage")}</p>
            <br />
            <h5><strong>{t("titleProvisionWebsiteCreationLogFiles")}</strong></h5>
            <br />
            <h5><strong>{t("titleDescriptionScopeDataProcessing")}</strong></h5>
            <br />
            <p>{t("contentDescriptionScopeDataProcessing1")}</p>
            <br />
            <h5><strong>{t("titleLegalBasisDataProcessing")}</strong></h5>
            <br />
            <p>{t("contentLegalBasisDataProcessing1")}</p>
            <br />
            <h5><strong>{t("titlePurposeDataProcessing")}</strong></h5>
            <br />
            <p>{t("contentPurposeDataProcessing1")}</p>
            <br />
            <h5><strong>{t("titleDurationStorage")}</strong></h5>
            <br />
            <p>{t("contentDurationStorage1")}</p>
            <br />
            <h5><strong>{t("titlePossibilityObjection")}</strong></h5>
            <br />
            <p>{t("contentPossibilityObjection1")}</p>
            <br />
            <h5><strong>{t("titleUseCookies")}</strong></h5>
            <br />
            <h5><strong>{t("titleDescriptionScopeDataProcessing")}</strong></h5>
            <br />
            <p>{t("contentDescriptionScopeDataProcessing2")}</p>
            <br />
            <h5><strong>{t("titleLegalBasisDataProcessing")}</strong></h5>
            <br />
            <p>{t("contentLegalBasisDataProcessing2")}</p>
            <br />
            <h5><strong>{t("titlePurposeDataProcessing")}</strong></h5>
            <br />
            <p>{t("contentPurposeDataProcessing2")}</p>
            <br />
            <h5><strong>{t("titleDurationStorageWithDiscussion")}</strong></h5>
            <br />
            <p>{t("contentDurationStorage2")}</p>
            <br />
            <h5><strong>{t("titleRegister")}</strong></h5>
            <br />
            <h5><strong>{t("titleDescriptionScopeDataProcessing")}</strong></h5>
            <br />
            <p>{t("contentDescriptionScopeDataProcessing3")}</p>
            <br />
            <h5><strong>{t("titleLegalBasisDataProcessing")}</strong></h5>
            <br />
            <p>{t("contentLegalBasisDataProcessing3")}</p>
            <br />
            <h5><strong>{t("titlePurposeDataProcessing")}</strong></h5>
            <br />
            <p>{t("contentPurposeDataProcessing3")}</p>
            <br />
            <h5><strong>{t("titleDurationStorage")}</strong></h5>
            <br />
            <p>{t("contentDurationStorage3")}</p>
            <br />
            <h5><strong>{t("titlePossibilityObjection")}</strong></h5>
            <br />
            <p>{t("contentPossibilityObjection2")}</p>
            <br />
            <h5><strong>{t("titleContactFormEmail")}</strong></h5>
            <br />
            <h5><strong>{t("titleDescriptionScopeDataProcessing")}</strong></h5>
            <br />
            <p>{t("contentDescriptionScopeDataProcessing4")}</p>
            <br />
            <h5><strong>{t("titleLegalBasisDataProcessing")}</strong></h5>
            <br />
            <p>{t("contentLegalBasisDataProcessing4")}</p>
            <br />
            <h5><strong>{t("titlePurposeDataProcessing")}</strong></h5>
            <br />
            <p>{t("contentPurposeDataProcessing4")}</p>
            <br />
            <h5><strong>{t("titleDurationStorage")}</strong></h5>
            <br />
            <p>{t("contentDurationStorage4")}</p>
            <br />
            <h5><strong>{t("titlePossibilityObjection")}</strong></h5>
            <br />
            <p>{t("contentPossibilityObjection3")}</p>
            <br />
            <h5><strong>{t("titleRightsPersonConcerned")}</strong></h5>
            <br />
            <p>{t("contentRightsPersonConcerned")}</p>
            <br />
            <h5><strong>{t("titleRightOfInformation")}</strong></h5>
            <br />
            <p>{t("contentRightOfInformation1")}</p>
            <br />
            <h5><strong>{t("titleLawOfInformation")}</strong></h5>
            <br />
            <p>{t("contentLawOfInformation1")}</p>
            <br />
            <h5><strong>{t("titleRightOfRectification")}</strong></h5>
            <br />
            <p>{t("contentRightOfRectification")}</p>
            <br />
            <h5><strong>{t("titleRightOfLimitationDataProcessing")}</strong></h5>
            <br />
            <p>{t("contentRightOfLimitationDataProcessing")}</p>
            <br />
            <h5><strong>{t("titleRightofDeletion")}</strong></h5>
            <br />
            <p>{t("contentRightofDeletion")}</p>
            <br />
            <h5><strong>{t("titleRightDataPortability")}</strong></h5>
            <br />
            <p>{t("contentRightDataPortability")}</p>
            <br />
            <h5><strong>{t("titleRightToInformation")}</strong></h5>
            <br />
            <h5><strong>{t("titleRightToBeInformed")}</strong></h5>
            <br />
            <p>{t("contentRightToBeInformed")}</p>
            <br />
            <h5><strong>{t("titleRightDataTransferability")}</strong></h5>
            <br />
            <p>{t("contentRightDataTransferability")}</p>
            <br />
            <h5><strong>{t("titleRightObjection")}</strong></h5>
            <br />
            <p>{t("contentRightObjection")}</p>
            <br />
            <h5><strong>{t("titleRevoke")}</strong></h5>
            <br />
            <p>{t("contentRevoke")}</p>
            <br />
            <h5><strong>{t("titleRightRevokeConsentDataProtectionLaw")}</strong></h5>
            <br />
            <p>{t("contentRightRevokeConsentDataProtectionLaw")}</p>
            <br />
            <h5><strong>{t("titleAutomatedDecisionMaking")}</strong></h5>
            <br />
            <p>{t("contentAutomatedDecisionMaking")}</p>
            <br />
            <h5><strong>{t("titleRightComplainSupervisoryAuthority")}</strong></h5>
            <br />
            <p>{t("contentRightComplainSupervisoryAuthority")}</p>
            <br />
            <h5><strong>{t("titleChangeTerms")}</strong></h5>
            <br />
            <p>{t("contentChangeTerms")}</p>
        </>
    )
}