import React from "react";
import styled from 'styled-components'
import "./Header.scss"

export default function Header(props: headerProperties) {

    const image = require(`../../img/header/${props.imageUrl}`);
    const HeaderContainer = styled.div`
        background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${image});
    `

    return (
        <>
            <HeaderContainer className="headerContainer">
                <div className="headerText">
                    <h1>{props.title}</h1>
                    <span>{props.text}</span>
                </div>

            </HeaderContainer >
        </>
    )
}

interface headerProperties {
    title: string;
    text: string;
    imageUrl: string;
}
