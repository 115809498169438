import { icon } from '@fortawesome/fontawesome-svg-core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadFileAPI, getCertificatesAPI, isUserConnectedAPI } from '../../core/apiFunctions';
import { certificateDTO } from '../../core/dto/user.models';
import { downloadFile } from '../../core/helperFunctions';
import fileIcon from '../../img/icon/pdf.png';
import "./CVFiles.scss";

export default function CVFiles(props: CVFilesProperties) {

    const [files, setFiles] = useState<certificateDTO[]>();
    const [blurred, setBlurred] = useState<boolean>();
    const { t } = useTranslation();
    //const sample = [{"isAnonymized":false,"id":"c010b641-6f0e-4fe4-8171-82bd66ac4546","title":"Not Connected","description":"Not Connected","institution":"Not Connected","fileName":"Not Connected","filePath":"..\\avatar.jpg","startDate":"2023-02-22","endDate":"2023-02-22"}];

    useEffect(() => {
        const fetchFiles = async () => {
            const files = await getCertificatesAPI(props.userId);
            const isConnected = await isUserConnectedAPI(props.userId);
            setFiles(files.data);
            setBlurred(!isConnected.data);
        }
        fetchFiles();
        // setFiles(sample);
        // setBlurred(true);
    }, []);


    return (
        <>
            <div className="cvCard">
                <div className="cvCardHeader">
                    <p>{t('certificates')}</p>
                </div>
                <div className={"cvFiles" + (blurred? " cvContentBlured disable-text-selection": "")}>
                    {files?.map((file, index) => (
                        <a key={index} style={{cursor:'pointer'}} onClick={() =>
                            downloadFileAPI(file.filePath).then((response) => {
                                // create file link in browser's memory
                                const href = URL.createObjectURL(response.data);
                                downloadFile(href, file.fileName);
                            })
                        }>
                            <img alt='file icon' src={fileIcon} />
                            <div>
                                <p>{file.title}</p>
                                <p>{file.fileName}</p>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </>
    )
}

interface CVFilesProperties {
    userId: string;
}