import React from "react";
import { NavDropdown } from "react-bootstrap";

export default function IconDropdown (props: dropdownProps) {
    return(
        <>
            <NavDropdown title={
                <img src={props.icon} alt={props.iconAlt} className={props.class} />
            } id="collasible-nav-dropdown" align="end">
                {props.children}
            </NavDropdown>
        </>
    )
}

interface dropdownProps{
    children: React.ReactNode;
    class: string;
    icon: string;
    iconAlt: string;
}

IconDropdown.defaultProps = {
}