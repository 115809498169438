import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { JobPosition, Workload, Salary, Availability, EmploymentType } from "../../config/Types/GeneralEnumDefinitions";
import { MedicalDivision } from "../../config/Types/MedicalEnumDefinitions";
import { getDreamJobAPI } from "../../core/apiFunctions";
import { enumToPercentage } from "../../core/helperConst";
import ProgressWithToolTip from "../../utils/ProgressWithToolTip";
import { Country, State } from "../../config/Types/PlaceEnumDefinitions";
import { internalJobDTO } from "../../core/dto/dto.models";

export default function CVDreamJob(props: CVDreamJobProperties) {

    const { t } = useTranslation();
    const [cvDetails, setCVDetails] = useState<internalJobDTO>();

    useEffect(() => {
        const fetchCVDetails = async () => {
            const cvDetails = await getDreamJobAPI(props.userId);
            setCVDetails(cvDetails.data);
        }
        fetchCVDetails();
    }, []);

    return (
        <>
            <div className="cvCard">
                <div className="cvCardHeader">
                    <p>{t('myDreamJob')}</p>
                </div>
                {cvDetails?
                <>
                <div className="cvInfo">
                    <div>
                        <p>{t('country')}:</p>
                        <p>{t(Country[cvDetails.country])}</p>
                    </div>
                    {
                        cvDetails.country === Country.Switzerland ?
                            <div>
                                <p>{t('canton')}:</p>
                                <p>{t(State[cvDetails.state!])}</p>
                            </div>
                            :
                            <div>
                                <p>{t('state')}:</p>
                                <p>{t(State[cvDetails.state!])}</p>
                            </div>
                    }
                    <div>
                        <p>{t('division')}:</p>
                        <p>{t(MedicalDivision[cvDetails.division])}</p>
                    </div>
                    <div>
                        <p>{t('headerEmployementType')}:</p>
                        <p>{t(EmploymentType[cvDetails.employmentType!])}</p>
                    </div>
                    <div>
                        <p>{t('position')}:</p>
                        <p>{t(JobPosition[cvDetails.jobPosition!])}</p>
                    </div>
                </div>
                <ProgressWithToolTip value={enumToPercentage(cvDetails.workload, Workload)} title={t('pensum')} toolTip={enumToPercentage(cvDetails.workload, Workload)+'%'} />
                <ProgressWithToolTip value={enumToPercentage(cvDetails.salary, Salary)} title={t('headerSalary')} toolTip={t(Salary[cvDetails.salary!])} />
                <div className="cvInfo">
                    <div>
                        <p>{t('startDate')}:</p>
                        <p>{t(Availability[cvDetails.availability!])}</p>
                    </div>
                </div>
                </>
                : null}
            </div>
        </>
    )
}

interface CVDreamJobProperties {
    userId: string;
}