export enum Country {
    None = 0,
    Afghanistan= 100,
    ÅlandIslands= 200,
    Albania= 300,
    Algeria= 400,
    AmericanSamoa= 500,
    Andorra= 600,
    Angola= 700,
    Anguilla= 800,
    Antarctica= 900,
    AntiguaAndBarbuda= 1000,
    Argentina= 1100,
    Armenia= 1200,
    Aruba= 1300,
    Australia= 1400,
    Austria= 1500,
    Azerbaijan= 1600,
    Bahamas= 1700,
    Bahrain= 1800,
    Bangladesh= 1900,
    Barbados= 2000,
    Belarus= 2100,
    Belgium= 2200,
    Belize= 2300,
    Benin= 2400,
    Bermuda= 2500,
    Bhutan= 2600,
    BoliviaPlurinationalStateOf= 2700,
    BonaireSintEustatiusAndSaba= 2800,
    BosniaAndHerzegovina= 2900,
    Botswana= 3000,
    BouvetIsland= 3100,
    Brazil= 3200,
    BritishIndianOceanTerritory= 3300,
    BruneiDarussalam= 3400,
    Bulgaria= 3500,
    BurkinaFaso= 3600,
    Burundi= 3700,
    CaboVerde= 3800,
    Cambodia= 3900,
    Cameroon= 4000,
    Canada= 4100,
    CaymanIslands= 4200,
    CentralAfricanRepublic= 4300,
    Chad= 4400,
    Chile= 4500,
    China= 4600,
    ChristmasIsland= 4700,
    CocosKeelingIslands= 4800,
    Colombia= 4900,
    Comoros= 5000,
    Congo= 5100,
    CongoDemocraticRepublicOfThe= 5200,
    CookIslands= 5300,
    CostaRica= 5400,
    CôteDIvoire= 5500,
    Croatia= 5600,
    Cuba= 5700,
    Curaçao= 5800,
    Cyprus= 5900,
    Czechia= 6000,
    Denmark= 6100,
    Djibouti= 6200,
    Dominica= 6300,
    DominicanRepublic= 6400,
    Ecuador= 6500,
    Egypt= 6600,
    ElSalvador= 6700,
    EquatorialGuinea= 6800,
    Eritrea= 6900,
    Estonia= 7000,
    EswatiniSwaziland= 7100,
    Ethiopia= 7200,
    FalklandIslandsMalvinas= 7300,
    FaroeIslands= 7400,
    Fiji= 7500,
    Finland= 7600,
    France= 7700,
    FrenchGuiana= 7800,
    FrenchPolynesia= 7900,
    FrenchSouthernTerritories= 8000,
    Gabon= 8100,
    Gambia= 8200,
    Georgia= 8300,
    Germany= 8400,
    Ghana= 8500,
    Gibraltar= 8600,
    Greece= 8700,
    Greenland= 8800,
    Grenada= 8900,
    Guadeloupe= 9000,
    Guam= 9100,
    Guatemala= 9200,
    Guernsey= 9300,
    Guinea= 9400,
    GuineaBissau= 9500,
    Guyana= 9600,
    Haiti= 9700,
    HeardIslandAndMcDonaldIslands = 9800,
    HolySee = 9900,
    Honduras = 10000,
    HongKong = 10100,
    Hungary = 10200,
    Iceland = 10300,
    India = 10400,
    Indonesia = 10500,
    Iran = 10600,
    Iraq = 10700,
    Ireland = 10800,
    IsleOfMan = 10900,
    Israel = 11000,
    Italy = 11100,
    Jamaica = 11200,
    Japan = 11300,
    Jersey = 11400,
    Jordan = 11500,
    Kazakhstan = 11600,
    Kenya = 11700,
    Kiribati = 11800,
    KoreaDemocraticPeoplesRepublicOf = 11900,
    KoreaRepublicOf = 12000,
    Kuwait = 12100,
    Kyrgyzstan = 12200,
    LaoPeoplesDemocraticRepublic = 12300,
    Latvia = 12400,
    Lebanon = 12500,
    Lesotho = 12600,
    Liberia = 12700,
    Libya = 12800,
    Liechtenstein = 12900,
    Lithuania = 13000,
    Luxembourg = 13100,
    Macao = 13200,
    Madagascar = 13300,
    Malawi = 13400,
    Malaysia = 13500,
    Maldives = 13600,
    Mali = 13700,
    Malta = 13800,
    MarshallIslands = 13900,
    Martinique = 14000,
    Mauritania = 14100,
    Mauritius = 14200,
    Mayotte = 14300,
    Mexico = 14400,
    MicronesiaFederatedStatesOf = 14500,
    MoldovaRepublicOf = 14600,
    Monaco = 14700,
    Mongolia = 14800,
    Montenegro = 14900,
    Montserrat = 15000,
    Morocco = 15100,
    Mozambique = 15200,
    Myanmar = 15300,
    Namibia = 15400,
    Nauru = 15500,
    Nepal = 15600,
    Netherlands = 15700,
    NewCaledonia = 15800,
    NewZealand = 15900,
    Nicaragua = 16000,
    Niger = 16100,
    Nigeria = 16200,
    Niue = 16300,
    NorfolkIsland = 16400,
    NorthMacedonia = 16500,
    NorthernMarianaIslands = 16600,
    Norway = 16700,
    Oman = 16800,
    Pakistan = 16900,
    Palau = 17000,
    PalestineStateOf = 17100,
    Panama = 17200,
    PapuaNewGuinea = 17300,
    Paraguay = 17400,
    Peru = 17500,
    Philippines = 17600,
    Pitcairn = 17700,
    Poland = 17800,
    Portugal = 17900,
    PuertoRico = 18000,
    Qatar = 18100,
    Reunion = 18200,
    Romania = 18300,
    RussianFederation = 18400,
    Rwanda = 18500,
    SaintBarthelemy = 18600,
    SaintHelenaAscensionAndTristanDaCunha = 18700,
    SaintKittsAndNevis = 18800,
    SaintLucia = 18900,
    SaintMartin = 19000,
    SaintPierreAndMiquelon = 19100,
    SaintVincentAndTheGrenadines = 19200,
    Samoa = 19300,
    SanMarino = 19400,
    SaoTomeAndPrincipe = 19500,
    SaudiArabia = 19600,
    Senegal = 19700,
    Serbia = 19800,
    Seychelles = 19900,
    SierraLeone = 20000,
    Singapore= 20100,
    SintMaartenDutchPart= 20200,
    Slovakia= 20300,
    Slovenia= 20400,
    SolomonIslands= 20500,
    Somalia= 20600,
    SouthAfrica= 20700,
    SouthGeorgiaAndTheSouthSandwichIslands= 20800,
    SouthSudan= 20900,
    Spain= 21000,
    SriLanka= 21100,
    Sudan= 21200,
    Suriname= 21300,
    SvalbardAndJanMayen= 21400,
    Sweden= 21500,
    Switzerland= 21600,
    SyrianArabRepublic= 21700,
    TaiwanProvinceOfChina= 21800,
    Tajikistan= 21900,
    TanzaniaUnitedRepublicOf= 22000,
    Thailand= 22100,
    TimorLeste= 22200,
    Togo= 22300,
    Tokelau= 22400,
    Tonga= 22500,
    TrinidadAndTobago= 22600,
    Tunisia= 22700,
    Turkey= 22800,
    Turkmenistan= 22900,
    TurksAndCaicosIslands= 23000,
    Tuvalu= 23100,
    Uganda= 23200,
    Ukraine= 23300,
    UnitedArabEmirates= 23400,
    UnitedKingdomOfGreatBritainAndNorthernIreland= 23500,
    UnitedStatesMinorOutlyingIslands= 23600,
    UnitedStatesOfAmerica= 23700,
    Uruguay= 23800,
    Uzbekistan= 23900,
    Vanuatu= 24000,
    VenezuelaBolivarianRepublicOf= 24100,
    VietNam= 24200,
    VirginIslandsBritish= 24300,
    VirginIslandsUS= 24400,
    WallisAndFutunaIslands= 24500,
    WesternSahara= 24600,
    Yemen= 24700,
    Zambia= 24800,
    Zimbabwe= 24900
}

export enum NationalityType
{
    GERMAN = 'GERMAN',
    AUSTRIAN = 'AUSTRIAN',
    SWISS = 'SWISS'
}

export enum State
{
    None = 0,

    // Austria - 15
    Burgenland = 1501,
    Kaernten = 1502,
    Niederoesterreich = 1503,
    Oberoesterreich = 1504,
    Salzburg = 1505,
    Steiermark = 1506,
    Tirol = 1507,
    Vorarlberg = 1508,
    Wien = 1509,

    // Germany - 84
    BadenWuerttemberg = 8401,
    Bayern = 8402,
    Berlin = 8403,
    Brandenburg = 8404,
    Bremen = 8405,
    Hamburg = 8406,
    Hessen = 8407,
    MecklenburgVorpommern = 8408,
    Niedersachsen = 8409,
    NordrheinWestfalen = 8410,
    RheinlandPfalz = 8411,
    Saarland = 8412,
    Sachsen = 8413,
    SachsenAnhalt = 8414,
    SchleswigHolstein = 8415,
    Thueringen = 8416,

    // Switzerland - 216
    Aargau = 21601,
    AppenzellAusserrhoden = 21602,
    AppenzellInnerrhoden = 21603,
    BaselLandschaft = 21604,
    BaselStadt = 21605,
    Bern = 21606,
    Freiburg = 21607,
    Genf = 21608,
    Glarus = 21609,
    Graubuenden = 21610,
    Jura = 21611,
    Luzern = 21612,
    Neuenburg = 21613,
    Nidwalden = 21614,
    Obwalden = 21615,
    Schaffhausen = 21616,
    Schwyz = 21617,
    Solothurn = 21618,
    StGallen = 21619,
    Tessin = 21620,
    Thurgau = 21621,
    Uri = 21622,
    Waadt = 21623,
    Wallis = 21624,
    Zug = 21625,
    Zuerich = 21626
}