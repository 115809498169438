import React from "react";
import css from "./LanguageSwitch.module.css";
import i18n from '../../../../config/i18n';
import { Dropdown } from "react-bootstrap";

export default function LanguageSwitchLandingpage() {

    const german = "Deutsch";
    const english = "English";
    const germanshort = "de";
    const englishshort = "en";

    const getLanguage = () => {
        const lang = i18n.language || window.localStorage.i18nextLng;
        if (lang === germanshort) {
            return german;
        } else if (lang === englishshort) {
            return english;
        }
    }

    const setLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
    }

    return (

        <Dropdown className={css.languageSwitch}>
            <Dropdown.Toggle>
                {getLanguage()}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item active={getLanguage() === german}
                    onClick={() => setLanguage(germanshort)}>{german}</Dropdown.Item>
                <Dropdown.Item active={getLanguage() === english}
                    onClick={() => setLanguage(englishshort)}>{english}</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}