import React from "react";
import { ProgressBar } from "react-bootstrap";

import "./ProgressWithToolTip.scss";

export default function ProgressWithToolTip(props: ProgressWithToolTipProperties) {
    return (
        <div className="progressWithToolTip">
            {props.title?
            <p>{props.title}</p>
            : null}
            <div className='progressWithToolTipToolTip' style={{ paddingLeft: `calc(${props.value}% - 25px)` }}><div>{props.toolTip}</div></div>
            <ProgressBar now={props.value} />
        </div>
    )
}

interface ProgressWithToolTipProperties {
    value: number;
    title?: string;
    toolTip: string;
}