import React from "react"
import { useTranslation } from 'react-i18next';

export default function ContentTermsAndConditions() {
    const { t } = useTranslation();
    return (
        <>
            <h5><strong>{t("titleTerms")}</strong></h5>
            <br />
            <h5><strong>{t("titleTermsPlattform")}</strong></h5>
            <br />
            <p>{t("contentCompany")}</p>
            <br />
            <h5><strong>{t("titleIntro")}</strong></h5>
            <br />
            <p>{t("contentIntro")}</p>
            <br />
            <h5><strong>{t("titleGlossar")}</strong></h5>
            <br />
            <p>{t("contentGlossar")}</p>
            <br />
            <h5><strong>{t("titleLanguage")}</strong></h5>
            <br />
            <p>{t("contentLanguage")}</p>
            <br />
            <h5><strong>{t("titleServiceDescription")}</strong></h5>
            <br />
            <p>{t("contentServiceDesription")}</p>
            <br />
            <h5><strong>{t("titleLoginAndAccount")}</strong></h5>
            <br />
            <p>{t("contentLoginAndAccount")}</p>
            <br />
            <h5><strong>{t("titleUsagePlattformForbiddenUploads")}</strong></h5>
            <br />
            <p>{t("contentUsagePlattformForbiddenUploads")}</p>
            <br />
            <h5><strong>{t("titleSanctionSuspensionTermination")}</strong></h5>
            <br />
            <p>{t("contentSanctionSuspensionTermination")}</p>
            <br />
            <h5><strong>{t("titleFees")}</strong></h5>
            <br />
            <p>{t("contentFees")}</p>
            <br />
            <h5><strong>{t("titleOfferFormatsContractConclusion")}</strong></h5>
            <br />
            <p>{t("contentOfferFormatsContractConclusion")}</p>
            <br />
            <h5><strong>{t("titleReviews")}</strong></h5>
            <br />
            <p>{t("contentReviews")}</p>
            <br />
            <h5><strong>{t("titleLimitationLiability")}</strong></h5>
            <br />
            <p>{t("contentLimitationLiability")}</p>
            <br />
            <h5><strong>{t("titleExemption")}</strong></h5>
            <br />
            <p>{t("contentExemption")}</p>
            <br />
            <h5><strong>{t("titleFinalProvisions")}</strong></h5>
            <br />
            <p>{t("contentFinalProvisions")}</p>
            <br />
            <h5><strong>{t("titleCancellationPolicy")}</strong></h5>
            <br />
            <p>{t("contentCancellationPolicy")}</p>
            <br />
            <h5><strong>{t("titleFeeSchedulePlattform")}</strong></h5>
            <br />
            <p>{t("contentFeeSchedulePlattform")}</p>
        </>
    )
}