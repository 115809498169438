import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Availability, EmploymentType, JobPosition, EducationLevel, Salary, Workload } from "../../config/Types/GeneralEnumDefinitions";
import { MedicalDivision } from "../../config/Types/MedicalEnumDefinitions";
import { getAdminIdAPI, getDreamJobAPI, submitDreamJobAPI } from "../../core/apiFunctions";
import { handleOnChange } from "../../core/helperConst";
import DisplayErrors from "../../utils/DisplayErrors";
import DropdownField from "../../utils/DropdownField";
import TextField from "../../utils/TextField";
import { Country, State } from "../../config/Types/PlaceEnumDefinitions";
import "../../utils/ProgressWithToolTip.scss";
import { useNavigate } from "react-router-dom";
import useFeedback from "../../utils/useFeedback";
import { internalJobDTO } from "../../core/dto/dto.models";

export default function DreamJob() {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [errors, setErrors] = useState<string[]>([]);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const [dreamJobInitial, setDreamJobInitial] = useState<internalJobDTO>();
    const [dreamJob, setDreamJob] = useState<internalJobDTO>();

    const [adminId, setAdminId] = React.useState<string>("");
    const { showSuccess }  = useFeedback();

    // We added them in the PlaceEnumDefinitions.ts file (or Backend)
    const listOfCountriesWithStates = [Country.Switzerland, Country.Germany, Country.Austria];

    useEffect(() => {
        // Load dream job from API
        getDreamJobAPI().then(response => {
            setDreamJob(response.data);
            setDreamJobInitial(response.data);
        }).catch(error => {
            setErrors(error.response.data);
        });

        getAdminIdAPI().then((res) => {
            setAdminId(res.data);
        });
    }, []);

    // checks if the about me data has changed and sets the isEditing flag
    useEffect(() => {
        if (dreamJobInitial !== undefined && dreamJobInitial !== dreamJob) {
            setIsEditing(true);
        } else {
            setIsEditing(false);
        }
    }, [dreamJobInitial, dreamJob]);

    // is called when the text fields are changed
    const onChange = handleOnChange(dreamJob, setDreamJob);

    const setOrigValues = () => {
        setDreamJob(dreamJobInitial!);
        setIsEditing(false);
        setErrors([]);
    }

    const submit = () => {
        submitDreamJobAPI(dreamJob!).then(() => {
            setDreamJobInitial(dreamJob);
            setErrors([]);
            showSuccess(t("successfullySaved"));
        }).catch(error => {
            setErrors(error.response.data);
        });
    }

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">{t("myDreamJob")}</h5>

                    <div className="row">
                        <TextField field="description" value={dreamJob?.description} as="textarea"
                            formFloating={false} onChange={onChange} placeholder={t("dreamJobPlaceholder")} height="125px" />
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <DropdownField displayName="headerCountry" field="country" enumType={Country}
                                selected={dreamJob?.country!}
                                onChange={onChange} />
                        </div>
                        {
                            dreamJob?.country === Country.None || !listOfCountriesWithStates.includes(dreamJob?.country) &&
                            <div className="col-md-4">
                                <DropdownField displayName="headerState" field="state" enumType={State}
                                    onChange={onChange} disabled={true} />
                            </div>
                        }
                        {
                            dreamJob?.country === Country.Switzerland &&
                            <div className="col-md-4">
                                <DropdownField displayName="headerCanton" field="state" enumType={State}
                                    index={21600} indexSize={27}
                                    selected={dreamJob?.state!}
                                    onChange={onChange} />
                            </div>
                        }
                        {
                            dreamJob?.country !== Country.Switzerland && (dreamJob?.country === Country.Germany || dreamJob?.country === Country.Austria) && dreamJob?.country !== Country.None &&
                            <div className="col-md-4">
                                <DropdownField displayName="headerState" field="state" enumType={State}
                                    index={dreamJob?.country! === Country.Germany ? 8400 : 1500} indexSize={dreamJob?.country! === Country.Germany ? 17 : 10}
                                    selected={dreamJob?.state!}
                                    onChange={onChange} />
                            </div>
                        }
                        <div className="col-md-4">
                            <DropdownField displayName="headerEmployementType" field="employmentType" enumType={EmploymentType}
                                selected={dreamJob?.employmentType!}
                                onChange={onChange} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <DropdownField displayName="headerAvailability" field="availability" enumType={Availability}
                                selected={dreamJob?.availability!}
                                onChange={onChange} />
                        </div>
                        <div className="col-md-4">
                            <DropdownField displayName="headerPosition" field="jobPosition" enumType={JobPosition}
                                selected={dreamJob?.jobPosition!}
                                onChange={onChange} />
                        </div>
                        <div className="col-md-4">
                            <DropdownField displayName="headerPensum" field="workload" enumType={Workload}
                                selected={dreamJob?.workload!}
                                onChange={onChange} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <DropdownField displayName="headerSalary" field="salary" enumType={Salary}
                                selected={dreamJob?.salary!}
                                onChange={onChange} />
                        </div>
                        <div className="col-md-4">
                            <DropdownField displayName="headerDivision" field="division"
                                enumType={MedicalDivision} selected={dreamJob?.division} onChange={onChange} />
                        </div>
                        {/* <div className="col-md-4">
                            <DropdownField displayName="headerJobType" field="jobType" enumType={JobType}
                                selected={dreamJob?.jobType!} index={dreamJob?.division!}
                                onChange={onChange} disabled={dreamJob?.division === 0} />
                        </div> */}
                        <div className="col-md-4">
                            <DropdownField displayName="headerEducationLevel" field="educationLevel" enumType={EducationLevel}
                                selected={dreamJob?.educationLevel} onChange={onChange} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <Button className="btn btn-primary" hidden={!isEditing} type="button" onClick={submit}>
                                <FontAwesomeIcon icon={faSave} /> {t("save")}
                            </Button>
                            <Button className="btn btn-danger ms-3" hidden={!isEditing} type="button" onClick={setOrigValues}>
                                <FontAwesomeIcon icon={faUndo} /> {t("cancel")}
                            </Button>
                        </div>
                    </div>
                    <div className="row">
                    {
                        <div style={{marginTop: "1%"}}  className="form-check form-switch public-toggle progressWithToolTip left-align-missing-profession">
                        {
                            <div className='progressWithToolTipToolTip'>
                                <div>{t("message.informAdmin")}</div>
                            </div>
                        }
                        <Button className="suggestionButton" onClick={() => navigate("/chat/" + adminId + "/" + t("message.support.hello") + t("message.support.concern"))} >
                            <svg
                                fill="none"
                                stroke="white"
                                strokeWidth="1.5"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                style={{ width: '24px', height: '24px' }}
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"></path>
                            </svg>
                        </Button>
                        <label className="form-check-label ms-2" htmlFor="suggestionButton">{t("missingJob")}</label>
                    </div>
                    }
                    </div>

                    <DisplayErrors errors={errors} />
                </div>
            </div>
        </>
    )
}
