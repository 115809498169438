import React from "react";
import { Row, Col } from "react-bootstrap";
import Logo from "../../../../img/logo_horizontal.png";
import "./LandingPageHeader.scss";
import { Link } from "react-router-dom";
import LanguageSwitchLandingpage from "./LanguageSwitchLandingpage";

export default function LandingPageHeader (){
    return(
        <div className="headerContainer">
            <Row>
                <Col sm={12} md={10}>
                    <Link to="/" style={{ textDecoration: 'none'}}>
                        <img className="logo" src={Logo} alt="Logo Digital ReCREWter" />
                    </Link>
                </Col>
                <Col>
                    <LanguageSwitchLandingpage />
                </Col>
            </Row>
        </div>
    )
}