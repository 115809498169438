import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getJobDescriptionAPI } from "../../core/apiFunctions";
import { AlertAction } from "../../core/helperConst";
import RichTextEditor from "../../utils/RichTextEditor";
import TextField from "../../utils/TextField";
import { useSetState } from "../../utils/UseSetState";
import * as yup from 'yup'
import { jobDescriptionDTO } from "../../core/dto/dto.models";

export default function JobOfferDescription(props: JobOfferDescriptionProperties) {

    const { t } = useTranslation();

    const newJobOffer = 'create';
    const fieldErrorEmptyValues = {
        title: '',
        companyDescription: '',
    }

    const [title, setTitle] = useState('');
    const [companyDescription, setCompanyDescription] = useState<string>('');
    const [jobDescription, setJobDescription] = useState<string>('');
    const [description, setDescription] = useState<jobDescriptionDTO>();
    const [fieldErrors, setFieldErrors, getFieldErrors] = useSetState(fieldErrorEmptyValues);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const schema = yup.object({
        title: yup.string().required(t("jobTitleRequired")),
        companyDescription: yup.string().required(t("companyDescriptionRequired")),
    });

    const validateNestedSchema = async () => {
        try {
            await schema.validate({ title, companyDescription }, { abortEarly: false });

            // If validation is successful, reset the field errors
            setFieldErrors(fieldErrorEmptyValues);
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                const validationErrors: any = {};

                error.inner.forEach((innerError: any) => {
                    if (innerError.type === 'required') {
                        validationErrors[innerError.path] = innerError.message;
                    }
                });

                setFieldErrors(validationErrors);
            }
        }
    };

    useEffect(() => {
        // Validate when there is user input (title or companyDescription)
        if (title.trim() !== '' || companyDescription.trim() !== '') {
            validateNestedSchema();
        }
        // Check if initial values are empty and trigger validation accordingly
        if (title.trim() === '' || companyDescription.trim() === '') {
            props.onErrorsChange(true);
        } else {
            props.onErrorsChange(false);
        }
    }, [title, companyDescription]);

    const handleOnChange = async (evnt: React.ChangeEvent<HTMLInputElement>, name: string) => {
        const value = evnt.currentTarget.value;

        if (!isEditing) {
            setIsEditing(true);
        }

        try {
            await schema.validateAt(name, { [name]: value });

            // No validation errors, clear the error for the current field
            setFieldErrors({ ...fieldErrors, [name]: '' });

            if (name === 'title') {
                setTitle(value);
            } else if (name === 'companyDescription') {
                setCompanyDescription(value);
            }
            // Notify parent about errors (pass true if there are any errors)
            props.onErrorsChange(false);
        } catch (err: any) {
            setFieldErrors({ ...fieldErrors, [name]: err.errors[0] });

            // Notify parent about errors (pass true if there are any errors)
            props.onErrorsChange(true);
        }
    };

    useEffect(() => {
        const fetchDescription = async () => {
            const jobDescription = await getJobDescriptionAPI(props.jobOfferId);

            setDescription(jobDescription.data);
            setJobDescription(jobDescription.data.jobDescription);
            setTitle(jobDescription.data.title);
            setCompanyDescription(jobDescription.data.companyDescription);
        }
        fetchDescription();

    }, []);

    useEffect(() => {
        var descript: jobDescriptionDTO = {
            id: props.jobOfferId,
            isManager: description?.isManager!,
            title: title,
            jobDescription: jobDescription,
            companyDescription: companyDescription
        }

        setDescription(descript);
        props.jobValues(descript);

    }, [title, companyDescription, jobDescription]);

    useEffect(() => {
        if(props.action === AlertAction.UPDATE) props.jobValues(description!);
    }, [props.action]);

    return (
        <div className="jobAdvMain">
            {props.editMode || props.jobOfferId === newJobOffer ?
                <TextField
                    type='text'
                    class="jobAdvInput jobAdvTitle"
                    field="title"
                    displayName="headerTitle"
                    value={title}
                    formFloating={true}
                    onChange={(evnt) => handleOnChange(evnt, 'title')}
                    onBlur={(evnt) => handleOnChange(evnt, 'title')}
                    validationMessage={fieldErrors.title}
                    isMandatory={true}
                />
                :
                <p className="jobAdvTitle">{title}</p>
            }
            {props.editMode || props.jobOfferId === newJobOffer ?
                <TextField
                    type='text'
                    as='textarea'
                    class="jobAdvInput"
                    field="companyDescription"
                    displayName="companyDescription"
                    formFloating={true}
                    onChange={(evnt) => handleOnChange(evnt, 'companyDescription')}
                    onBlur={(evnt) => handleOnChange(evnt, 'companyDescription')}
                    disabled={!description?.isManager} // only managers can edit the link
                    value={companyDescription}
                    validationMessage={fieldErrors.companyDescription}
                    isMandatory={true}
                />
                :
                <p>{companyDescription}</p>
            }
            <div className="jobAdvInput">
                {props.editMode || props.jobOfferId === newJobOffer ?
                    <label>{t('jobDescription')}</label>
                    : null}
                {props.editMode || props.jobOfferId === newJobOffer ?
                    <RichTextEditor
                        value={jobDescription}
                        onChange={setJobDescription}
                        readOnly={false}
                        className='jobAdvRteInput'
                    />
                    : null}
                {!props.editMode && props.jobOfferId !== newJobOffer ?
                    <RichTextEditor
                        value={jobDescription}
                        onChange={setJobDescription}
                        readOnly={true}
                        className='jobAdvRteInput jobAdvRteReadOnlyInput'
                    />
                    : null}
            </div>
        </div>
    )
}

interface JobOfferDescriptionProperties {
    jobOfferId: string;
    jobValues(job: jobDescriptionDTO): void;
    showAlert(show: boolean): void;
    action: AlertAction;
    editMode?: boolean;
    onErrorsChange: (hasErrors: boolean) => void;
}