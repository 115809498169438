import React from "react";
import { useContext } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import AuthenticationContext from "../auth/AuthenticationContext";
import { FeedbackMode } from "../config/Types/GeneralEnumDefinitions";


export default function FeedbackToast() {
    const { success, setSuccess, message, mode, setMode, duration } = useContext(AuthenticationContext)

    return (
        <>
            {(mode === FeedbackMode.Success) &&
                <ToastContainer position="top-end" containerPosition="fixed top-right" style={{ "marginTop": "20px", "marginRight": "20px", "width": "fitContent" }}>
                    <Toast bg="success" onClose={() => setSuccess(false)} show={success} delay={duration} autohide>
                        <Toast.Header style={{ "display": "flex", "justifyContent": "space-between" }} closeButton={false}><strong>Action</strong><small>now</small></Toast.Header>
                        <Toast.Body style={{ "color": "white" }}><i className="bi bi-egg-fried"></i> {message}</Toast.Body>
                    </Toast>
                </ToastContainer>
            }
            {(mode === FeedbackMode.Error) &&
                <ToastContainer position="top-end" containerPosition="fixed top-right" style={{ "marginTop": "20px", "marginRight": "20px", "width": "fitContent" }}>
                    <Toast bg="danger" onClose={() => setMode(FeedbackMode.Success)} show={mode === FeedbackMode.Error} delay={duration} autohide>
                        <Toast.Header style={{ "display": "flex", "justifyContent": "space-between" }} closeButton={false}><strong>Action</strong><small>now</small></Toast.Header>
                        <Toast.Body style={{ "color": "white" }}><i className="bi bi-egg-fried"></i> {message}</Toast.Body>
                    </Toast>
                </ToastContainer>
            }
        </>
    )
}