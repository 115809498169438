import React from "react";
import { claim } from "../core/dto/auth.models";
import { FeedbackMode } from "../config/Types/GeneralEnumDefinitions";

const AuthenticationContext = React.createContext<{
    claims: claim[];
    update(claims: claim[]): void;

    success: boolean;
    setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    mode: FeedbackMode;
    setMode: React.Dispatch<React.SetStateAction<number>>;
    duration: number;
    setDuration: React.Dispatch<React.SetStateAction<number>>;
}>({
    claims: [],
    update: () => { },
    success: false,
    setSuccess: () => { },
    message: "",
    setMessage: () => { },
    mode: FeedbackMode.None,
    setMode: () => { },
    duration: 0,
    setDuration: () => { }
});

export default AuthenticationContext;
