import React from "react"

export default function Button(props: buttonProps){
    return <button
        type={props.type}
        disabled={props.disabled}
        className={props.class}
        onClick={props.onClick}
        hidden={props.hidden}
    >
        {props.children}
    </button>
}

interface buttonProps{
    children?: React.ReactNode;
    onClick?(): void;
    type: "button" | "submit";
    class?: string;
    disabled: boolean;
    hidden: boolean;
}

Button.defaultProps = {
    type: "button",
    disabled: false,
    hidden: false
}