import React, { useEffect, useState } from "react"
import { FloatingLabel, Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"

export default function DropdownField(props: dropdownFieldProps) {

    const { t } = useTranslation();

    const [enumType, setEnumType] = useState<string[]>([]);

    // Creates the list of options for the dropdown
    // There is an option for index, which is used to create a dropdown with a range of values with a starting index and a index-size
    // update the enumType when the props change
    useEffect(() => {
        setEnumType(() => {
            const filteredKeys = Object.keys(props.enumType)
                .filter(k => typeof props.enumType[k as any] === "number")
                .filter(k => {
                    if (props.index === undefined) {
                        return true;
                    } else {
                        const enumKey = k as keyof typeof props.enumType;
                        return (
                            props.enumType[enumKey] === props.index ||
                            (props.enumType[enumKey] >= props.index + 1 &&
                                props.enumType[enumKey] < props.index + props.indexSize!)
                        );
                    }
                });
            if (props.index !== undefined) {
                return [t("None"), ...filteredKeys];
            } else {
                return filteredKeys;
            }
        });
    }, [props.enumType, props.index, props.indexSize]);

    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const enumNumber = props.enumType[e.target.value as keyof typeof props.enumType];
        const newE = { ...e, target: { ...e.target, value: enumNumber, name: e.target.name } };
        props.onChange(newE);
    }

    // Find the selected key based on the selected value
    const selectedKey = Object.keys(props.enumType).find(
        (key) => props.enumType[key as keyof typeof props.enumType] === props.selected
    ) || '';

    return (
        <>
            <div className={`${props.class} ? 'form-floating' : ''}`}>
                <FloatingLabel label={
                    <>
                        {t(props.displayName)}
                        {props.isMandatory === true && <b> *</b>}
                    </>
                    } onChange={onChange} >
                    <Form.Select
                        id={props.field}
                        name={props.field}
                        disabled={props.disabled}
                        value={selectedKey}
                        onChange={onChange}
                    >
                        {Object.keys(enumType).map((key) => {
                            // Remove the selected attribute from the option element
                            return <option key={key} value={enumType[Number(key)]}>{t(enumType[Number(key)])}</option>
                        })}
                    </Form.Select>
                </FloatingLabel>
            </div>
            {props.validationMessage && <label className="text-danger">{props.validationMessage}</label>}
        </>
    )
}

interface dropdownFieldProps {
    displayName: string;
    field: string
    selected?: Number;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    validationMessage?: string;
    class: string;
    index?: number;
    indexSize?: number;
    disabled?: boolean;
    enumType: any;
    isMandatory?: boolean;
}

DropdownField.defaultProps = {
    class: 'mb-3',
    disabled: false,
    indexSize: 100,
    isMandatory: false
}