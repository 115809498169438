import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import "./ModalContent.scss";

export default function ModalContent(props: ModalContentProperties) {
    const { t } = useTranslation();
    const [show, setShow] = useState(props.show);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    return (
        <>
            <Modal
                {...props.size === undefined ? { fullscreen: true } : { size: props.size }}
                show={show}
                onHide={() => {
                    setShow(false);
                    props.onHideCallbackSecondary && props.onHideCallbackSecondary();
                    props.onClose && props.onClose();
                }}
                centered
            >
                <Modal.Header closeButton>
                    {props.title != null ? <Modal.Title>{t(props.title)}</Modal.Title> : null}
                </Modal.Header>
                <Modal.Body>{props.children}</Modal.Body>

                {/* only display footer if buttons are available */}
                {props.primaryButton != null || props.secondaryButton != null || props.thirdButton != null ? <Modal.Footer>
                    <div className="buttons">
                        {props.primaryButton != null && (
                            <button
                                className="btn btn-primary me-3"
                                onClick={() => {
                                    setShow(false);
                                    props.onHideCallbackPrimary && props.onHideCallbackPrimary();
                                }}
                            >
                                {t(props.primaryButton)}
                            </button>
                        )}

                        {props.thirdButton != null && (
                            <button
                                className="btn btn-warning me-3"
                                onClick={() => {
                                    setShow(false);
                                    props.onHideCallbackThird && props.onHideCallbackThird();
                                }}
                            >
                                {t(props.thirdButton)}
                            </button>
                        )}

                        {props.secondaryButton != null && (
                            <button
                                className="btn btn-secondary"
                                onClick={() => {
                                    setShow(false);
                                    props.onHideCallbackSecondary && props.onHideCallbackSecondary();
                                }}
                            >
                                {t(props.secondaryButton)}
                            </button>
                        )}

                    </div>
                </Modal.Footer>
                    : null}
            </Modal>
        </>
    );
}

interface ModalContentProperties {
    title?: string;
    size: "sm" | "lg" | "xl" | undefined;
    children: any;
    show: boolean;
    onHideCallbackSecondary?: () => void;
    onHideCallbackPrimary?: () => void;
    onHideCallbackThird?: () => void;
    primaryButton?: string;
    secondaryButton?: string;
    thirdButton?: string;
    onClose?: () => void;
}

ModalContent.defaultProps = {
    size: undefined,
    onHideCallbackSecondary: () => { },
    onHideCallbackPrimary: () => { },
    onHideCallbackThird: () => { },
    primaryButton: null,
    secondaryButton: null,
    thirdButton: null,
};