import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import "@fortawesome/fontawesome-free-brands";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommenting, faGlobe, faPhone, faPlay } from "@fortawesome/free-solid-svg-icons";
import { userHeaderDTO, userInformationDTO } from "../../core/dto/user.models";
import { useNavigate } from "react-router-dom";
import { getAge, formatUrlLink } from "../../core/helperConst";
import { getBackgroundPhotoAPI, getHeaderInformationAPI, getProfilePhotoAPI, getProfileVideoAPI } from "../../core/apiFunctions";
import defaultImage from "../../img/logo.png";
import "./CVHeader.scss";

export default function CVHeader(props: CvHeaderProperties) {
    const [header, setHeader] = useState<userHeaderDTO>();
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768)
    const [profilePicture, setProfilePicture] = useState<string>();
    const [backgroundImage, setBackgroundImage] = useState<string>("");
    const [profileVideo, setProfileVideo] = useState<string>();
    const [showVideoPlayed, setShowVideoPlayer] = useState<boolean>();

    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const fetchHeader = async () => {
            await getProfilePhotoAPI(props.userId).then((blob) => {
                setProfilePicture(URL.createObjectURL(blob.data));
            }).catch((error) => {
                // Profilepicture not found
                setProfilePicture(defaultImage);
            });

            await getBackgroundPhotoAPI(props.userId).then((blob) => {
                if (blob.data)
                    setBackgroundImage(URL.createObjectURL(blob.data));
            }).catch((error) => {
                // Backgroundimage not found
            });

            await getProfileVideoAPI(props.userId).then((blob) => {
                setProfileVideo(URL.createObjectURL(blob.data));
            }).catch((error) => {
                // Video not found
            });

            const header = await getHeaderInformationAPI(props.userId);
            setHeader(header.data);
        }
        fetchHeader();


    }, []);

    const preventDragHandler = (e: any) => {
        e.preventDefault();
    }

    return (
        <>
            <div
                className={ (backgroundImage ? "cvHeaderBackgroundImage" : "cvHeader") + " parentCV"}
                style={ backgroundImage ? {backgroundImage: `url(${backgroundImage})`}  : {}}
                >
                <div className="card-profile">
                    <div className="card-content">
                    <span className="menuContainer cvProfile">
                        <div>
                            <p className={(header?.blurred? " cvContentBlured disable-text-selection": "")}>{header?.name} ({getAge(header?.age)})</p>
                            <p>{header?.jobTitle}</p>
                        </div>
                        <span>
                            {profileVideo && header?.blurred === false ?
                            <button onClick={() => setShowVideoPlayer(true)}><FontAwesomeIcon icon={faPlay} /></button>
                            : null}
                            <div className="cvProfileImage" onDragStart={preventDragHandler}>
                                <img className={(header?.blurred? " cvContentBlured disable-text-selection": "")} alt="profile" src={profilePicture} />
                            </div>
                        </span>
                        <div>                   
                            <div className="cvSocial exclude-from-pdf">
                                {props.userInformation?.linkedIn?
                                <a target='_blank' href={header?.blurred === false? formatUrlLink(props.userInformation?.linkedIn): undefined} className={header?.blurred === false? '': 'cvSocialBlured'}>
                                    <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
                                </a>
                                : null}
                                {props.userInformation?.xing?
                                <a target='_blank' href={header?.blurred === false? formatUrlLink(props.userInformation?.xing): undefined} className={header?.blurred === false? '': 'cvSocialBlured'}>
                                    <FontAwesomeIcon icon={["fab", "xing"]} />
                                </a>
                                : null}
                                {props.userInformation?.website?
                                <a target='_blank' href={header?.blurred === false? formatUrlLink(props.userInformation?.website): undefined} className={header?.blurred === false? '': 'cvSocialBlured'}>
                                    <FontAwesomeIcon icon={faGlobe} />
                                </a>
                                : null}
                            </div>
                                {
                                isMobile && //Mobile order
                            <div className="chatIconMobileContainer exclude-from-pdf">
                                <a className="chatIconMobile" onClick={() => navigate('/chat/' + props.userId)}>
                                    <FontAwesomeIcon icon={faCommenting} />
                                </a>
                            </div>
                                }
                        </div>
                    </span>
                </div>
                
                </div>
                {
                            !isMobile && //Desktop order
                        <div className="chatIcon exclude-from-pdf">
                            <a href="#"  onClick={() => navigate('/chat/' + props.userId)}>
                                        <FontAwesomeIcon icon={faCommenting} />
                            </a>
                        </div>
                }
            </div>

            <Modal
                show={showVideoPlayed}
                onHide={() => setShowVideoPlayer(false)}
                size="lg"
                centered
            >
                <Modal.Header closeButton className="d-flex justify-content-between align-items-center">
                    <Modal.Title className="profileVideoText">{t("profileVideo")}</Modal.Title>
                </Modal.Header>
            <Modal.Body>
                <ReactPlayer
                    url={profileVideo}
                    playing={true}
                    controls={true}
                    width='100%'
                />
            </Modal.Body>
            </Modal>
        </>
    )
}

interface CvHeaderProperties {
    userId: string;
    userInformation?: userInformationDTO;
}