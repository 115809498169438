import React from "react";
import { Row, Col } from 'react-bootstrap';
import reCREWterLogo from "../../img/logo_white.png";
import linkedInLogo from "../../img/linkedin.png";
import facebookLogo from "../../img/facebook.png";
import { IconContext } from "react-icons";
import { BsInstagram, BsFacebook, BsLinkedin } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import "./Footer.scss";

export default function Footer() {
    const { t } = useTranslation();

    return (
        <div id="footerContainer">
            <div className="footerMax">
                <div className="noCopyright">
                    <Col className="logo" sm={12} md={4} lg={4}>
                        <img src={reCREWterLogo} alt="Digital Recrewter Logo" />
                    </Col>
                    <Col className="information" sm={12} md={4} lg={4}>
                        <h3>{t("information")}</h3>
                        <Link className="link" to="/terms" target="_blank">
                            <p>{t("terms")}</p>
                        </Link>
                        <Link className="link" to="/policy" target="_blank">
                            <p>{t("dataProtection")}</p>
                        </Link>
                        <Link className="link" to="/imprint" target="_blank">
                            <p>{t("imprint")}</p>
                        </Link>
                    </Col>
                    <Col className="socialContacts" sm={12} md={4} lg={4}>
                        <Row className="contact">
                            <h3>{t("contact")}</h3>
                            <h4>{t("infos")}</h4>
                            <p>{t("telefon")}</p>
                            <p><a className="link" href={`mailto:${t('mailto')}`}>{t("mailto")}</a></p>
                        </Row>
                        <Row className="socialmedia">
                            <h4>{t("socials")}</h4>
                            <div className="socialLogos">
                                <a href="https://www.linkedin.com/company/digital-recrewter-gmbh/" className="sm-4">
                                    <IconContext.Provider value={{color: "#0e76a8"}}>
                                        <BsLinkedin />
                                    </IconContext.Provider>
                                </a>
                                <a href="https://www.facebook.com/profile.php?id=100092070790825" className="sm-4">
                                    <IconContext.Provider value={{color: "#4267B2"}}>
                                        <BsFacebook />
                                    </IconContext.Provider>
                                </a>
                                <a href="https://www.instagram.com/digitalrecrewter/" className="sm-4">
                                    <IconContext.Provider value={{color: "orange"}}>
                                        <BsInstagram />
                                    </IconContext.Provider>
                                </a>
                            </div>
                        </Row>
                    </Col>
                </div>
                <Row className="copyright">
                    <p>{t("copyright")}</p>
                </Row>
            </div>
        </div>
    )
}