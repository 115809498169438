import React from "react";

export default function TextIcon (props: TextIconProperties){
    return(
        <>
            {
                props.iconUnderText
                ?
                <>
                    {props.children}
                    <img className={props.classIcon} src={props.icon} alt={props.iconAlt} />
                </>
                :
                <>

                    <img className={props.classIcon} src={props.icon} alt={props.iconAlt} />
                    {props.children}
                </>
            }
        </>
    )
}

interface TextIconProperties{
    classIcon: string;
    icon: string;
    iconAlt: string;
    iconUnderText: boolean;
    children: React.ReactNode;
}