import React from "react";
import { useTranslation } from "react-i18next";

export default function DisplayErrors(props: displayErrorProps) {

    const { t } = useTranslation();

    return (
        <>
            {props.errors && props.errors.length > 0 ? <ul className="alert alert-danger mt-3">
                {props.errors.map((error: any, index: number) => {
                    if (error.includes(" ")) {
                        return <li className="ms-3" key={index}>{error}</li>
                    } else {
                        return <li className="ms-3" key={index}>{t(error)}</li>
                    }
                })}
            </ul> : null}
        </>
    )
}

interface displayErrorProps {
    errors?: string[];
}