import React, { useState } from "react";
import { Button, ButtonProps, Modal } from "react-bootstrap";

export const AlertModal = (props: AlertModalProps) => {
    return (
        <Modal show={props.show} onHide={props.handleClose} centered={props.isCentered}>
            {props.headerMessage &&
            <Modal.Header closeButton>
                <Modal.Title>{props.headerMessage}</Modal.Title>
            </Modal.Header>
            }
            <Modal.Body>{props.message}</Modal.Body>
            <Modal.Footer>
                {props.buttons.map((button) => (
                    <Button {...button.props}>{button.text}</Button>
                ))}
            </Modal.Footer>
        </Modal>
    )
    }

    interface AlertModalProps {
    isCentered?: boolean;
    show: boolean;
    handleClose: () => void;
    headerMessage?: string;
    message: string;
    buttons: Array<{
        text: string;
        props: ButtonProps
    }>;
    }

    AlertModal.defaultProps = {
        isCentered: true
    }