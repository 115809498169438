import React from "react";
import { useTranslation } from "react-i18next";
import ComingSoonImage from "../img/icon/coming-soon.png";
import TextIcon from "../utils/TextIcon";
import "./ComingSoon.scss"

export default function ComingSoon (props: ComingSoonProperties){
    const { t } = useTranslation();
    return(
        <>
            <div className="comingSoonContainer">
                <TextIcon classIcon={"commingSoon"} icon={ComingSoonImage} iconAlt={"comming soon image"} iconUnderText={true} children={<h2>{t(props.title)}</h2>} />
            </div>
        </>
    )
}

interface ComingSoonProperties{
    title: string;
}