import React, { useContext, useEffect, useState } from "react";
import IconMenuLink from "../utils/IconMenuLink";
import dashboardIcon from "../img/icon/dashboard.png";
import settingsIcon from "../img/icon/settings.png";
import logoutIcon from "../img/icon/logout.png";
import { NavDropdown } from "react-bootstrap";
import { logout } from "../core/handleJWT";
import { getCompanyNameForRecrewterAPI, getProfilePhotoAPI } from "../core/apiFunctions";
import AuthenticationContext from "../auth/AuthenticationContext";
import { checkRole, getEmail, getLicence, getName } from "../core/claimFunctions";
import { UserRoles } from "../config/Types/GeneralEnumDefinitions";
import "./LoggedInUser.scss";
import { useTranslation } from "react-i18next";

export default function LoggedInUser() {

    const { claims } = useContext(AuthenticationContext);
    const [profilePhoto, setProfilePhoto] = useState<string>();
    const [companyName, setCompanyName] = useState<string>();

    const profilePictureKey = "profile-picture";
    const companyNameKey = "company-name";

    useEffect(() => {
        getAndSetProfilePhoto();
        getAndSetComapnyName();
    }, []);

    const setProfilePhotoFromServer = () => {
        getProfilePhotoAPI()
            .then((response) => {
                // create a local URL from the blob
                const url = URL.createObjectURL(response.data);
                setProfilePhoto(url);
                localStorage.setItem(profilePictureKey, url);
            })
            .catch((error) => {
            });
    }

    const getAndSetProfilePhoto = () => {
        const picture = localStorage.getItem(profilePictureKey);

        // get the profile picture if not already in local storage
        if (picture === null || picture === undefined) {
            setProfilePhotoFromServer();
        }
        else {
            // check if under the local url is an image and sets it new new required: is required when user refreshes the page manually
            const img = new Image();
            img.src = picture!;
            if (img.height === 0) {
                setProfilePhotoFromServer();
            }
        }

        setProfilePhoto(picture!);

        // add event listener to update the company name if it changes
        window.addEventListener(profilePictureKey, () => {
            setProfilePhoto(localStorage.getItem(profilePictureKey)!);
        });
    }

    const setMailasCompanyName = () => {
        const mail = getEmail(claims);
        setCompanyName(mail);
        localStorage.setItem(companyNameKey, mail);
    }

    const getAndSetComapnyName = () => {
        // check if user is recrewter
        // if yes, get company name

        if (checkRole(claims, UserRoles.Recrewter)) {
            getCompanyNameForRecrewterAPI().then((response) => {
                if (response.data === "") {
                    setMailasCompanyName();
                }
                else {
                    setCompanyName(response.data);
                    localStorage.setItem(companyNameKey, response.data);
                }
            })
        }
        else {
            setMailasCompanyName();
        }

        // add event listener to update the company name if it changes
        window.addEventListener(companyNameKey, () => {
            setCompanyName(localStorage.getItem(companyNameKey)!);
        });
    }

    useEffect(() => {
        getAndSetProfilePhoto();
        getAndSetComapnyName();
    }, []);

    
    const { t } = useTranslation();

    return (
        <>
            <div className="infoLoggedInUser">
                <strong>
                    <p className="nameCompany">
                        {companyName}
                    </p>
                </strong>
                <p className="nameRole">
                    {
                        checkRole(claims, UserRoles.Admin)
                        ?
                        t("administrator")
                        :
                        checkRole(claims, UserRoles.Recrewter)
                        ?
                        t("recruiter") 
                        :
                        checkRole(claims, UserRoles.Employee)
                        ?
                        t("employee")
                        : ''
                    }
                </p>                
                <p className="nameUser">{getName(claims)}</p>
                <p className="versionText">{getLicence(claims)}</p>
            </div>
            <NavDropdown title={
                <div className="iconLoggedInUser">
                    <img src={profilePhoto} className="profilImage" alt="avatar" />
                </div>
            } id="collasible-nav-dropdown" align="end">
                <NavDropdown.Item onClick={(e) => {
                    logout();
                }}>
                    <IconMenuLink classLink="nav-link logout-link" classImage="icon" whereTo="/" translation="menuLogout" icon={logoutIcon} iconAlt="Logout Icon" />
                </NavDropdown.Item>
            </NavDropdown>
        </>
    )
}