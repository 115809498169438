// Convert enum to percentage
export const enumToPercentage = (value: any, type: object) => (value / ((Object.keys(type).length / 2) - 1)) * 100;


export const enumToString = (enumObject: { [x: string]: any; }, value: any) => {
    const key = Object.keys(enumObject).find((k) => enumObject[k] === value);
    return key ? key : "";
  };

// Formtat url
export const formatUrlLink = (url?: string) => url? (url.startsWith('http://') || url.startsWith('https://')? url: 'https://'+url): '';

// Calculate age from DateTime
export const getAge = (birthDate: string) => Math.floor((new Date() as any - new Date(birthDate).getTime()) / 3.15576e+10)

// Handle on change for DropDown
export const handleOnChange = (stateValue: any, setStateValue: any) => (e: any) => {
    const { name, value } = e.target;
    setStateValue({ ...stateValue!, [name]: value });
};


// Determine which action is being performed
export type AlertAction = (typeof AlertAction)[keyof typeof AlertAction];
export const AlertAction = {
    NONE: 'NONE',
    PUBLISH: 'PUBLISH',
    SAVE: 'SAVE',
    EDIT: 'EDIT',
    REMOVE: 'REMOVE',
    HIDE: 'HIDE',
    UPDATE: 'UPDATE',
    PREVIEW: 'PREVIEW'
}