import React, { useContext, useEffect, useState } from "react";
import { companyDetailsDTO } from "../../core/dto/user.models";
import * as yup from 'yup'
import Button from "../../utils/Button";
import { getCompanyDetailsAPI, getCompanyHeaderAPI, getCompanyLogoAPI, isCompanyProfileCompletedAPI, submitCompanyDetailsAPI, submitFileAPI } from "../../core/apiFunctions";
import TextField from "../../utils/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import { useSetState } from "../../utils/UseSetState";
import { useTranslation } from "react-i18next";
import { FileType } from "../../config/Types/GeneralEnumDefinitions";
import ProfileHeader from "./ProfileHeader";
import DropdownField from "../../utils/DropdownField";
import AuthenticationContext from "../../auth/AuthenticationContext";
import { State, Country } from "../../config/Types/PlaceEnumDefinitions";
import useFeedback from "../../utils/useFeedback";

export default function CompanyDetails(props: CompanyDetailsProperties) {

    const companyNameKey = "company-name";

    const { t } = useTranslation();
    const { claims } = useContext(AuthenticationContext);

    const fieldErrorEmptyValues = {
        name: '', address: '', postcode: '', state: '', country: '', uid: '', city: '',
        email: '', phoneNumber: '', description: ''
    }

    const schema = yup.object({
        name: yup.string().required(t('NameRequired')),
        address: yup.string().required(t('AddressRequired')),
        postcode: yup.string().required(t('PostcodeRequired')),
        state: yup.string().required(t('StateRequired')),
        city: yup.string().required(t('CityRequired')),
        country: yup.string().required(t('CountryRequired')),
        uid: yup.string().required(t('UIDRequired')),
        email: yup.string().email(t('EmailNotValid')).required(t('EmailRequired')),
        phoneNumber: yup.string().required(t('PhoneNumberRequired')),
        description: yup.string().required(t('DescriptionRequired'))
    });

    const [isEditing, setIsEditing] = useState<boolean>(false);

    const [companyDetailsInital, setCompanyDetailsInitial] = useState<companyDetailsDTO>();
    const [companyDetails, setCompanyDetails] = useState<companyDetailsDTO>();

    const [errors, setErrors] = useState<string[]>([]);
    const [fieldErrors, setFieldErrors, getFieldErrors] = useSetState(fieldErrorEmptyValues);
    const { showSuccess }  = useFeedback(); 

    useEffect(() => {
        getCompanyDetailsAPI().then(response => {
            setCompanyDetailsInitial(response.data);
            setCompanyDetails(response.data);

            // Inform parent the state of the modal according to this component
            if (claims.length > 0) {

                // Check in backend and inform parent
                checkCompletionCompanyProfile();
            }
        }).catch(error => {
            setErrors(error.response.data);
        });
    }, []);

    // validates all fields and sets validaton messages as errors
    const validateNestedSchema = async () => {
        const errors = await schema.validate(companyDetails, { abortEarly: false }).catch((err) => {
            return err;
        });

        if (errors instanceof yup.ValidationError) {
            const validationErrors: any = {};
            errors.inner.forEach((error: any) => {
                validationErrors[error.path] = error.message;
            });
            setFieldErrors(validationErrors);
        }
    }

    // validate the changed field and sets the error message
    const handleOnChange = (e: any) => {
        if (!isEditing) {
            setIsEditing(true);
        }

        const { name, value } = e.target;
        // verify input with yup schema
        if (name in fieldErrors) {
            schema.validateAt(name, { [name]: value })
                .then(() => {
                    setFieldErrors({ ...fieldErrors, [name]: '' });
                })
                .catch(err => {
                    setFieldErrors({ ...fieldErrors, [name]: err.errors[0] });
                })
        }

        setCompanyDetails({ ...companyDetails!, [name]: value });
    }

    // reset the form to the original values
    const setOrigValues = () => {
        setCompanyDetails(companyDetailsInital);
        setIsEditing(false);
        setErrors([]);

        // Check in backend and inform parent
        checkCompletionCompanyProfile();

        setFieldErrors(fieldErrorEmptyValues);
    }

    // submit the form
    const submit = async () => {
        try {
            await validateNestedSchema();
            if (errors.length === 0 && Object.values(await getFieldErrors()).every(x => x === '')) {
                await submitCompanyDetailsAPI(companyDetails!);

                setCompanyDetailsInitial(companyDetails);

                // update company name in loggedInUser-component
                localStorage.setItem(companyNameKey, companyDetails!.name);
                // TODO: only works in dev mode, not in prod
                // fireEvent(window, new StorageEvent(companyNameKey));

                // Check in backend and inform parent
                checkCompletionCompanyProfile();

                setIsEditing(false);
                showSuccess(t("successfullySaved"));
            }
        } catch (error: any) {
            setErrors(error.response.data);
        }
    }

    function checkCompletionCompanyProfile() {
        // Check if the profile is complete in the backend
        isCompanyProfileCompletedAPI().then((result) => {
            props.isCompanyProfileCompleted(result.data);
        });
    }

    return (
        <div className="card mt-0">
            <div className="card-header header-background">
                <h5 className="card-title">{t("companyProfile")}</h5>
                <ProfileHeader
                    apiCallToLoadProfile={getCompanyLogoAPI}
                    profilePhotoType={FileType.CompanyProfilePicture}
                    apiCallToLoadHeader={getCompanyHeaderAPI}
                    headerPhotoType={FileType.CompanyHeader}
                    apiCallToSubmitFile={submitFileAPI} />
            </div>

            <div className="card-body">
                <div className="row">
                    <TextField field="description" value={companyDetails?.description} as="textarea"
                        formFloating={false} onChange={handleOnChange} placeholder={t("companyDescriptionPlaceholder")} height="125px"
                        validationMessage={fieldErrors.description} isMandatory={true}/>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <TextField field="name" value={companyDetails?.name} displayName="companyName"
                            formFloating={true} onChange={handleOnChange} validationMessage={fieldErrors.name} isMandatory={true}/>
                    </div>
                    <div className="col-md-4">
                        <TextField field="address" value={companyDetails?.address} displayName="address"
                            formFloating={true} onChange={handleOnChange} validationMessage={fieldErrors.address} isMandatory={true}/>
                    </div>
                    <div className="col-md-4">
                        <TextField field="postcode" value={companyDetails?.postcode} displayName="postcode"
                            formFloating={true} onChange={handleOnChange} validationMessage={fieldErrors.postcode} isMandatory={true}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <TextField field="city" value={companyDetails?.city} displayName="city"
                            formFloating={true} onChange={handleOnChange} validationMessage={fieldErrors.city} isMandatory={true}/>
                    </div>
                    <div className="col-md-4">
                        <DropdownField displayName="country" field="country" selected={companyDetails?.country!} onChange={handleOnChange}
                            enumType={Country} validationMessage={fieldErrors.country} isMandatory={true}/>
                    </div>
                    <div className="col-md-4">
                    {
                            companyDetails?.country === Country.Switzerland
                            ?
                                <DropdownField displayName="canton" field="state" enumType={State} selected={companyDetails?.state!} onChange={handleOnChange}
                                    disabled={companyDetails?.country !== Country.Switzerland} index={21600} indexSize={27}/>
                                :
                                (
                                    companyDetails?.country === Country.Germany
                                    ?
                                        <DropdownField displayName="state" field="state" enumType={State} selected={companyDetails?.state!} onChange={handleOnChange}
                                        disabled={companyDetails?.country !== Country.Germany} index={8400} indexSize={17} />
                                    :
                                        (
                                            companyDetails?.country === Country.Austria
                                            ?
                                            <DropdownField displayName="state" field="state" enumType={State} selected={companyDetails?.state!} onChange={handleOnChange}
                                            disabled={companyDetails?.country !== Country.Austria} index={1500} indexSize={10} />
                                            :
                                            <DropdownField displayName="state" field="" enumType={State} selected={0} onChange={handleOnChange}
                                            disabled={true} />
                                        )
                                )
                    }
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <TextField field="uid" value={companyDetails?.uid} displayName="uid"
                            formFloating={true} onChange={handleOnChange} validationMessage={fieldErrors.uid} isMandatory={true}/>
                    </div>
                    <div className="col-md-4">
                        <TextField field="email" value={companyDetails?.email} displayName="eMailCompany"
                            formFloating={true} onChange={handleOnChange} validationMessage={fieldErrors.email} isMandatory={true}/>
                    </div>
                    <div className="col-md-4">
                        <TextField field="phoneNumber" value={companyDetails?.phoneNumber} displayName="companyPhone"
                            formFloating={true} onChange={handleOnChange} validationMessage={fieldErrors.phoneNumber} isMandatory={true}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <TextField field="website" value={companyDetails?.website} displayName="website"
                            formFloating={true} onChange={handleOnChange} />
                    </div>
                    <div className="col-md-6">
                        <TextField field="linkedin" value={companyDetails?.linkedIn} displayName="linkedIn"
                            formFloating={true} onChange={handleOnChange} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <TextField field="xing" value={companyDetails?.xing} displayName="xing"
                            formFloating={true} onChange={handleOnChange} />
                    </div>
                    <div className="col-md-6">
                        <TextField field="youTubeUrl" value={companyDetails?.youTubeUrl} displayName="youtube"
                            formFloating={true} onChange={handleOnChange} />
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-12">
                        <Button class="btn btn-primary" hidden={!isEditing} type="button" onClick={submit}>
                            <FontAwesomeIcon icon={faSave} /> {t("save")}
                        </Button>
                        <Button class="btn btn-danger ms-3" hidden={!isEditing} type="button" onClick={setOrigValues}>
                            <FontAwesomeIcon icon={faUndo} /> {t("cancel")}
                        </Button>
                    </div>
                </div>

                {/* only show errors when available */}
                {errors.length > 0 && <div className="row">
                    <div className="col-md-12">
                        <div className="alert alert-danger" role="alert">
                            <ul>
                                {errors.map((error: any, index: number) => {
                                    return <li key={index}>{error}</li>
                                }
                                )}
                            </ul>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}


interface CompanyDetailsProperties {
    isCompanyProfileCompleted: (show: boolean) => void;
}