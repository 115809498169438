import React from "react";
import { NavDropdown } from "react-bootstrap";
import i18n from '../../config/i18n';
import IconDropdown from "../../utils/IconDropdown";
import germanIcon from "../../img/icon/DE_flag.png";
import englishIcon from "../../img/icon/UK_flag.png";
import { useTranslation } from "react-i18next";

export default function LanguageSwitch(props: LanguageSwitchProps) {

    // Do not delete this. Whithout this line the translation will not work correct.
    const { t } = useTranslation();

    const german = "Deutsch";
    const english = "English";
    const germanshort = "de";
    const englishshort = "en";

    const getLanguage = () => {
        const lang = i18n.language || window.localStorage.i18nextLng;
        if (lang.includes(germanshort)) {
            return german;
        } else if (lang.includes(englishshort)) {
            return english;
        }
    }

    const setLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
    }

    return (

        <IconDropdown class={props.class} icon={
            getLanguage() === german
            ? germanIcon
            : englishIcon
        } iconAlt="Language icon" children={
            <>
                {
                    getLanguage() === german
                    ?
                    <NavDropdown.Item active={getLanguage() === english} onClick={() => setLanguage(englishshort)}>
                        <p><img className="flag" src={englishIcon} alt="English flag" />{english}</p>
                    </NavDropdown.Item>
                    :
                    <NavDropdown.Item active={getLanguage() === german} onClick={() => setLanguage(germanshort)}>
                        <p><img className="flag" src={germanIcon} alt="German flag" />{german}</p>
                    </NavDropdown.Item>
                }
            </>
        } />
    )
}

interface LanguageSwitchProps{
    class: string;
}