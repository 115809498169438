import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { YearsOfExperience } from '../../config/Types/GeneralEnumDefinitions';
import { getSkillsAPI } from '../../core/apiFunctions';
import { skillDTO } from '../../core/dto/user.models';
import { enumToPercentage } from '../../core/helperConst';
import ProgressWithToolTip from '../../utils/ProgressWithToolTip';

export default function CVSkills(props: CVSkillsProperties) {

    const { t } = useTranslation();
    const [skills, setSkills] = useState<skillDTO[]>();
    //const sample = [{"id":"0a88ca53-8b7b-45cf-a744-8811de988661","name":"Managing","years":2},{"id":"ec2646d1-309c-4912-acdd-41a52fa1e50b","name":"Hundeführen","years":3}];

    useEffect(() => {
        const fetchSkills = async () => {
            const skills = await getSkillsAPI(props.userId);
            setSkills(skills.data);
        }
        fetchSkills();
        //setSkills(sample);
    }, []);

    return (
        <>
            <div className="cvCard">
                <div className="cvCardHeader">
                    <p>{t('skills')}</p>
                </div>
                <div>
                    {skills?.map((skill: skillDTO, index) => (
                        <ProgressWithToolTip key={index} value={enumToPercentage(skill.years, YearsOfExperience)} title={skill.name} toolTip={t(YearsOfExperience[skill.years])} />
                    ))}
                </div>
            </div>
        </>
    )
}

interface CVSkillsProperties {
    userId: string;
}