import React, { useEffect, useState } from "react";
import { getCompanyDetailsByJobOfferAPI } from "../../core/apiFunctions";
import { AiOutlineCheck } from "react-icons/ai";
import Button from 'react-bootstrap/Button';
import { companyDetailsDTO } from "../../core/dto/user.models";
import { useTranslation } from "react-i18next";
import { AlertAction } from "../../core/helperConst";
import './JobOfferHeader.scss';
import { isGuid } from "../../core/helperFunctions";
import { jobDescriptionDTO } from "../../core/dto/dto.models";
import useFeedback from "../../utils/useFeedback";

export default function JobOfferHeader(props: JobOfferHeaderProperties) {

    const { t } = useTranslation();

    // When a new Joboffer is created, in the url the jobofferId is 'create'
    const newJobOffer = "create";
    const { showSuccess }  = useFeedback();

    const [header, setHeader] = useState<companyDetailsDTO & { coverImage?: string, image?: string }>();
    const [companyHeader, setCompanyHeader] = useState<string>();
    const [isPublished, setIsPublished] = useState<boolean>(props.isPublished);
    const [showButtons, setShowButtons] = useState(
        props.jobOfferId === newJobOffer ? true : false
    );

    useEffect(() => {
        const fetchHeader = async () => {
            const companydetails = await getCompanyDetailsByJobOfferAPI(props.jobOfferId);

            // getCompanyHeaderAPI(companydetails.data.id).then((response) => {
            //     const url = URL.createObjectURL(response.data);
            //     setCompanyHeader(url);
            // });

            setHeader(companydetails.data);
        }
        fetchHeader();

    }, []);

    useEffect(() => {
        setIsPublished(props.isPublished);
    }, [props.isPublished]);

    function handlePreviewButtonClick() {
        setShowButtons(false);
        // Update parent with newest values
        props.showAlertPreview(!isGuid(props.jobOfferId));
        props.alertAction(AlertAction.PREVIEW);
    }

    const handlePublishClick = () => {
        if (props.alertAction) {
          props.alertAction(AlertAction.PUBLISH);
          showSuccess(t('successfullyPublished'));
        }
    };

    return (
        <div className="jobAdvHeaderContainer">
            <img src={companyHeader} alt="company cover" />
            <div className="menuContainer">
                <div className="jobAdvHeader">
                    <div className="jobAdvHeaderInfo">
                        <h3>{header?.name}</h3>
                        <p>{header?.address}</p>
                    </div>
                    {showButtons && (
                        <div className="jobAdvHeaderAlert">
                            <div>
                                <Button
                                    variant="success"
                                    onClick={handlePreviewButtonClick}
                                    disabled={props.disableButton}
                                >
                                    {t("preview")}
                                </Button>
                                <Button
                                    variant="danger"
                                    onClick={() => props.alertAction(AlertAction.REMOVE)}
                                >
                                    {t("delete")}
                                </Button>
                            </div>
                        </div>
                    )}
                    {
                        props.showAlert && props.isRecrewter &&
                        <div className="jobAdvHeaderAlert">
                            {
                                !isPublished &&
                                <>
                                    <p>{t('jobAdvHeaderAlertMsgLine1')}</p>
                                    <p>{t('jobAdvHeaderAlertMsgLine2')}</p>
                                    <AiOutlineCheck />
                                </>
                            }
                            <div>
                                {
                                    props.jobOfferId !== newJobOffer && !isPublished &&
                                    <Button onClick={() => handlePublishClick()}>{t('publish')}</Button>
                                }
                                {
                                    isPublished &&
                                    <Button onClick={() => props.alertAction ? props.alertAction(AlertAction.HIDE) : undefined}>{t('hideJobOffer')}</Button>
                                }
                                {
                                    props.jobOfferId !== newJobOffer &&
                                    <Button variant="danger" onClick={() => props.alertAction ? props.alertAction(AlertAction.REMOVE) : undefined}>{t('delete')}</Button>
                                }
                            </div>
                            <div>
                                {
                                    props.jobOfferId !== newJobOffer && props.action !== AlertAction.EDIT &&
                                    <Button variant="outline-dark" onClick={() => props.alertAction ? props.alertAction(AlertAction.EDIT) : undefined}>{t('edit')}</Button>
                                }
                                {
                                    props.jobOfferId !== newJobOffer && props.action === AlertAction.EDIT &&
                                    <Button variant="light" onClick={() => props.alertAction ? props.alertAction(AlertAction.UPDATE) : undefined}>{t('save')}</Button>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

interface JobOfferHeaderProperties {
    isRecrewter: boolean;
    jobOfferId: string;
    isPublished: boolean;
    showAlert?: boolean;
    alertAction(action: AlertAction): void;
    action: AlertAction;
    jobValues(job: jobDescriptionDTO): void;
    showAlertPreview(show: boolean): void;
    disableButton: boolean;
}