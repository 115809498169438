import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AcademicDegree, FieldOfStudy } from '../../config/Types/GeneralEnumDefinitions';
import { getEducationsAPI } from '../../core/apiFunctions';
import { educationDTO } from '../../core/dto/user.models';

export default function CVEducations(props: CVEducationsProperties) {

    const [educations, setEducations] = useState<educationDTO[]>();
    const { t } = useTranslation();
    //const sample = [{"id":"7de2c04a-f3ea-45ad-be0d-0d4987feb71d","fieldOfStudy":6,"academicDegree":4,"institution":"JKWB","address":"Jöchlerweg 2","startDate":"2023-02-28","endDate":"2023-03-02"},{"id":"d28fae85-7d74-4b7f-812c-e51f9f7e58e5","fieldOfStudy":12,"academicDegree":5,"institution":"KSUK","address":"a sdfsd fasd as df","startDate":"2023-02-27","endDate":"2023-02-27"}];

    useEffect(() => {
        const fetchEducations = async () => {
            const educations = await getEducationsAPI(props.userId);
            setEducations(educations.data);
        }
        fetchEducations();
        //setEducations(sample);
    }, []);


    return (
        <>
            <div className="cvCard">
                <div className="cvCardHeader">
                    <p>{t('education')}</p>
                </div>
                <div>
                {educations?.map((education, index) => {
                    const dotDimentinos = (index+1)*5 >= 25? 25: (index+1)*5;

                    return (
                        <div key={index} className="cvTimeLine">
                            <div>
                                <div></div>
                                <div className='cvTimeLineDot' style={{ width: `calc(40px - ${dotDimentinos}px)`, height: `calc(40px - ${dotDimentinos}px)` }}></div>
                                <div></div>
                            </div>
                            <div>
                                <p>{new Date(education.startDate).getMonth() + 1}/{new Date(education.startDate).getFullYear()} - {new Date (education.endDate).getMonth() + 1}/{new Date(education.endDate).getFullYear()}</p>
                                <p>{t(AcademicDegree[education.academicDegree])} | {t(FieldOfStudy[education.fieldOfStudy])}</p>
                                <p>{education.institution}, {education.address}</p>
                            </div>
                        </div>
                    )
                })}
                </div>
            </div>
        </>
    )
}

interface CVEducationsProperties {
    userId: string;
}