import React from "react"
import { useTranslation } from 'react-i18next';

export default function ContentImprint (){
    const { t } = useTranslation();
    return (
        <div className="container">
            <h5><strong>{t("titleImprint")}</strong></h5>
            <br/>
            <h5><strong>{t("titleResponsibleWebsite")}</strong></h5>
            <br/>
            <p>{t("contentResponsibleWebsite")}</p>
            <br/>
            <h5><strong>{t("titleTechnicalInformation")}</strong></h5>
            <br/>
            <p>{t("contentTechnicalInformation")}</p>
            <br/>
        </div>
    )
}