import { useContext, useState } from 'react';
import AuthenticationContext from './AuthenticationContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import { Form, Formik } from 'formik';
import FormikTextField from '../utils/FormikTextField';
import * as yup from 'yup'
import YupPassword from 'yup-password'
import Button from '../utils/Button';
import DisplayErrors from '../utils/DisplayErrors';
import Mainmenu from '../components/Menu/Mainmenu';
import Header from '../components/Header/Header';
import { UserRoles } from '../config/Types/GeneralEnumDefinitions';
import { loginAPI } from '../core/apiFunctions';
import { getClaims, saveToken } from '../core/handleJWT';
import { userCredentialsDTO } from '../core/dto/auth.models';
import './AuthPage.scss'
import { checkUserAuthorized } from '../core/claimFunctions';
import { useTranslation } from 'react-i18next';
import ModalContent from '../utils/ModalContent';
import RequestNewPasswordContent from './RequestNewPasswordContent';
YupPassword(yup)

export default function AuthLoginPage() {

    const navigate = useNavigate();
    const { state } = useLocation();
    const { t } = useTranslation();

    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

    const { email, role } = state as { email: string, role: UserRoles };
    const [errors, setErrors] = useState<string[]>([]);
    const { update } = useContext(AuthenticationContext);

    async function submit(credentials: userCredentialsDTO) {
        try {
            setErrors([]);
            const response = await loginAPI(credentials);
            saveToken(response.data);
            var claims = getClaims();
            update(claims);

            // Only navigate to dashboard if the user has completed his profile
            if (checkUserAuthorized(claims)) {
                navigate('/dashboard');
            }
            else {
                navigate('/settings');
            }
        }
        catch (error: any) {
            if (error && error.response) {
                setErrors(error.response.data);
            }
        }
    }

    const validationSchema = yup.object({
        password: yup.string().required(t("passwordRequired"))
    });

    return (
        <>
            {
                showForgotPasswordModal &&
                <ModalContent
                    size="lg"
                    show={showForgotPasswordModal}
                    children={<RequestNewPasswordContent handleCloseModal={() => setShowForgotPasswordModal(false)} />}
                    title={t("forgotPassword")}
                />
            }

            <Mainmenu />
            <Header title={t("password")}
                text=""
                imageUrl="doctors-walking-away.png" />

            <div className="authContainer">

                <Formik
                    initialValues={{ email: email, password: '' }}
                    onSubmit={async (values: userCredentialsDTO) => {
                        await submit(values);
                    }}
                    validationSchema={validationSchema}
                    validateOnBlur={false}
                >

                    {formikProps => (
                        <Form className="login">
                            <h3>{t("welcomeBack")} :</h3>
                            <h6>{t("loginAs")}: {email}</h6>

                            <div className="chooseRoleContainer">
                                {role === UserRoles.Employee &&
                                    <img className="chooseRole" src={require('../img/doctor.png')} alt="employee" />
                                }

                                {role === UserRoles.Recrewter &&
                                    <img className="chooseRole" src={require('../img/businessman.png')} alt="recrewter" />
                                }

                                {role === UserRoles.Admin &&
                                    <img className="chooseRole" src={require('../img/admin.png')} alt="admin" />
                                }
                            </div>

                            <FormikTextField displayName="password" field="password" type="password" autoFocus={true} />
                            <DisplayErrors errors={errors} />

                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <Button class="btn btn-primary" type="submit">{t("btnSend")}</Button>
                                    <Link className="btn btn-secondary ms-3" to="/">{t("cancel")}</Link>
                                </div>
                                <span className="forgot-link" onClick={() => setShowForgotPasswordModal(true)}>{t("forgotPassword")}</span>
                            </div>


                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}