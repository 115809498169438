import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import { Form, Formik } from 'formik';
import FormikTextField from '../utils/FormikTextField';
import Button from '../utils/Button';
import DisplayErrors from '../utils/DisplayErrors';
import './AuthPage.scss'
import Mainmenu from '../components/Menu/Mainmenu';
import Header from '../components/Header/Header';
import { FeedbackMode, UserRoles } from '../config/Types/GeneralEnumDefinitions';
import { registerAPI } from '../core/apiFunctions';
import { userCredentialsDTO } from '../core/dto/auth.models';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup'
import useFeedback from '../utils/useFeedback';

export default function AuthRegisterPage() {

    const navigate = useNavigate();
    const { state } = useLocation();
    const { t } = useTranslation();

    const { email } = state as { email: string };
    const [errors, setErrors] = useState<string[]>([]);
    
    const { showSuccess } = useFeedback();

    const [choosenRole, setChoosenRole] = useState<UserRoles>();
    async function submit(credentials: userCredentialsDTO) {
        try {
            setErrors([]);
            if (!choosenRole) {
                setErrors(['Please choose a role']);
                return;
            }

            const response = await registerAPI(credentials, choosenRole);
            if (response.status === 200) {
                showSuccess(t("registerSuccess"), FeedbackMode.Success, 10000);
            }

            navigate('/verifyEmail');
        }
        catch (error: any) {
            if (error && error.response) {
                setErrors(error.response.data);
            }
        }
    }

    const validationSchema = yup.object({
        // At least 8 characters, at least one letter, one number and one special character
        password: yup.string()
        .required(t("newPasswordRequired"))
        .min(8, t("passwordTooShort"))
        .matches(/[0-9]/, t("passwordNumberRequired"))
        .matches(/[a-zA-Z]/, t("passwordLetterRequired"))
        .matches(/(?=.*[^\da-zA-Z])/, t("passwordSpecialCharacterRequired")),

        // verifypassword needs to be the same as password
        verifyPassword: yup.string().required(t("confirmPasswordRequired"))
            .oneOf([yup.ref('password'), null], t("errorNonMatchingPassword"))
    });

    return (
        <>
            <Mainmenu />
            <Header title={t("password")}
                text=""
                imageUrl="doctors-walking-away.png" />

            <div className="authContainer">

                <Formik
                    initialValues={{ email: email, password: '' }}
                    onSubmit={async (values: userCredentialsDTO) => {
                        await submit(values);
                    }}
                    validationSchema={validationSchema}
                    validateOnBlur={false}
                >

                    {formikProps => (
                        <Form className="login">
                            <h3>{t("register")}</h3>
                            <h6>{t("registerAs")} {email}</h6>

                            <div className="chooseRoleContainer">
                                <div className="cc-selector">
                                    <input id="recrewter" type="radio" name="choosenRole" value="recrewter" onChange={() => setChoosenRole(UserRoles.Recrewter)} />
                                    <label className="drinkcard-cc recrewter chooseRole" htmlFor="recrewter">{t("recrewter")}</label>
                                    <input id="employee" type="radio" name="choosenRole" value="employee" onChange={() => setChoosenRole(UserRoles.Employee)} />
                                    <label className="drinkcard-cc employee chooseRole" htmlFor="employee">{t("menuEmployee")}</label>
                                </div>
                            </div>

                            <FormikTextField displayName="password" field="password" type="password" autoFocus={true} />
                            <FormikTextField displayName="verifyPassword" field="verifyPassword" type="password" />
                            <DisplayErrors errors={errors} />
                            <Button class="btn btn-primary me-3" type="submit">{t("btnSend")}</Button>
                            <Link className="btn btn-secondary" to="/">{t("cancel")}</Link>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}