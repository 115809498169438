import React from "react";
import ContentTermsAndConditions from "../components/ContentTermsAndConditions";
import LandingPageHeader from "../landingpage/components/LandingPageHeader";

export default function TermsAndConditions() {
    return (
        <>
            <div className="appContainer mb-5">
                <LandingPageHeader />
                <ContentTermsAndConditions />
            </div>
        </>
    )
}