import { ErrorMessage, Field } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import './FormikTextField.scss'

export default function FormikTextField(props: textFieldProps) {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => {
        setShowPassword(!showPassword);
    };
    const inputType = props.type === 'password' && !showPassword ? 'password' : 'text';

    return (
        <div className={`${props.class}`}>
            {props.icon && <img src={props.icon} alt={props.iconAlt} />}

            <div className={`input-wrapper form-floating`}>
                <Field
                    autoFocus={props.autoFocus}
                    as={props.as}
                    isrequired={props.isRequired}
                    type={inputType}
                    className={`form-control ${props.type === 'password' ? 'with-icon' : ''}`}
                    id={props.field}
                    name={props.field}
                    placeholder={props.placeholder}
                />
                
                {props.type === 'password' && (
                    <span className="input-icon" onClick={togglePassword}>
                        {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                    </span>
                )}

                <label htmlFor={props.field} className="floating-label">
                    {props.displayName ? t(props.displayName) : ""}
                </label>
            </div>

            <ErrorMessage name={props.field}>
                {msg => <div className="text-danger">{msg}</div>}
            </ErrorMessage>
        </div>
    );
}

interface textFieldProps {
    autoFocus?: boolean;
    field: string;
    placeholder?: string;
    displayName?: string;
    icon?: string;
    iconAlt?: string;
    type: 'text' | 'password';
    class: string;
    isRequired?: string;
    as?: string;
}

FormikTextField.defaultProps = {
    type: 'text',
    class: 'mb-3',
    autofocus: false,
    isRequired: "true",
}
