import { useState, useEffect } from "react";

export function isGuid(str: string) {
    const guidRegex = /^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/i;
    return guidRegex.test(str);
}

export function convertDateTimeToDate(dateTime: string) {
    return dateTime.split("T")[0];
}

export function isObjectEmpty(obj: any) {
    return Object.keys(obj).length <= 0;
}

export function hasEmptyProperties(obj: any) {
    for (const key in obj) {
        if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
            return true;
        }
    }
    return false;
}

export function useDebounce(value: any, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

export function downloadFile(url: string, fileName: string) {
    // create "a" HTLM element with href to file & click
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    // preventdefault with click
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
}

export function downloadUseStateToJson(object: any, name: string) {
    const file = new Blob([JSON.stringify(object)], { type: 'application/json' });
    const fileURL = URL.createObjectURL(file);
    downloadFile(fileURL, name + '.json');
}