import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DropdownField from "../../utils/DropdownField";
import Button from "../../utils/Button";
import {
  employeeSearchDTO,
  employeeSearchFilterDTO,
} from "../../core/dto/dto.models";
import {
  employeeSearchFilterAPI,
  getBackgroundPhotoAPI,
  getProfilePhotoAPI,
  getEmployeeFromUserIdAPI,
} from "../../core/apiFunctions";
import SearchResults from "./SearchResults";
import { handleOnChange } from "../../core/helperConst";
import {
  Title,
  EmploymentType,
  JobPosition,
  Workload,
  Salary,
  Availability,
  EducationLevel,
} from "../../config/Types/GeneralEnumDefinitions";
import {
  MedicalDivision,
} from "../../config/Types/MedicalEnumDefinitions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Accordion } from "react-bootstrap";
import "./EmployeeSearch.scss";
import { State, Country } from "../../config/Types/PlaceEnumDefinitions";
import { getNameIdentifier } from "../../core/claimFunctions";
import AuthenticationContext from "../../auth/AuthenticationContext";
import defaultImage from "../../img/logo.png";

export default function EmployeeSearch() {
  const { t } = useTranslation();
  const { claims } = useContext(AuthenticationContext);
  const currentRecrewter = getNameIdentifier(claims);
  const getEmptyForm = () => {
    return {
      title: Title.None,
      country: Country.None,
      state: State.None,
      employmentType: EmploymentType.None,
      jobPosition: JobPosition.None,
      workload: Workload.None,
      salary: Salary.None,
      division: MedicalDivision.None,
      //jobType: JobType.None,
      educationLevel: EducationLevel.None,
      availability: Availability.None,
      description: "",
    };
  };

  const [searchResults, setSearchResults] = useState<employeeSearchDTO[]>([]);
  const [topMatches, setTopMatches] = useState<employeeSearchDTO[]>([]);
  const [filterSettings, setFilterSettings] = useState<employeeSearchFilterDTO>(
    getEmptyForm()
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [favoriteCards, setFavoriteCards] = useState<employeeSearchDTO[]>([]);

  const [favorites, setFavorites] = useState<Record<string, string[]>>(() => {
    // Initialize from local storage or use an empty object
    const storedFavorites = localStorage.getItem("favorites");
    if (storedFavorites) {
      const parsedFavorites = JSON.parse(storedFavorites);
      return parsedFavorites || {};
    } else {
      return {};
    }
  });

  useEffect(() => {
    // Initialize favorites state from local storage or use an empty object
    const storedFavorites = localStorage.getItem("favorites");
    if (storedFavorites) {
      const parsedFavorites = JSON.parse(storedFavorites);
      setFavorites(parsedFavorites);
    }
  }, [currentRecrewter]);

  useEffect(() => {
    // Store the updated favorites object in local storage
    localStorage.setItem("favorites", JSON.stringify(favorites));
  }, [favorites, currentRecrewter]);

  const fetchFavoriteCardsFromBackend = async () => {
    try {
      // Make an API call to fetch favorite cards from the backend
      const userIds = favorites[currentRecrewter] || [];
      const favoriteCardsFromBackend = await Promise.all(
        userIds.map(async (userId) => {
          try {
            // Fetch employee data for each userId
            const response = await getEmployeeFromUserIdAPI(userId);
            const employeeData = response.data;
            // Fetch profile photo
            const blobProfile = await getProfilePhotoAPI(userId);
            employeeData.profilePicture = URL.createObjectURL(blobProfile.data);
            // Fetch headerPicture photo
            try {
              const blobBackground = await getBackgroundPhotoAPI(userId);
              employeeData.headerPicture = URL.createObjectURL(
                blobBackground.data
              );
            } catch (error) {
              // Set a default background photo URL if there is no customized headerPicture
              employeeData.headerPicture =
                "linear-gradient(45deg, rgb(83, 91, 235),rgb(76, 11, 174))";
            }
            return employeeData;
          } catch (error) {
            return null; // Handle error or skip invalid data
          }
        })
      );
      // Filter out null values (failed requests) and update the state
      const updatedFavoriteCards = [
        ...favoriteCardsFromBackend.filter((card) => card !== null),
      ];
      // Filter out null values (failed requests) and update the state
      setFavoriteCards((prevFavorites) => [
        ...prevFavorites,
        ...favoriteCardsFromBackend,
      ]);
      return updatedFavoriteCards; // Return the updated favoriteCards array
    } catch (error) {
      throw error; // Propagate the error up
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch favorite cards from the backend when the component mounts
        await fetchFavoriteCardsFromBackend();
      } catch (error) {
        return null;
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Load last search filter criteria from local storage
      const lastSearchFilters = localStorage.getItem("lastSearchFilters");
      if (lastSearchFilters) {
        const parsedFilters = JSON.parse(lastSearchFilters);
        setFilterSettings(parsedFilters[currentRecrewter] || getEmptyForm());
      }
      } catch (error) {
        return null;
      }
    };
    fetchData();
  }, [currentRecrewter]);

  // Handle onChange for dropdowns
  const onChange = handleOnChange(filterSettings, setFilterSettings);

  // Send enum values from dropdown to backend
  const submit = () => {
    setIsSubmitting(true);

    var topMatches = true;
    var employeeList: employeeSearchDTO[] = [];

    // true = top matches
    employeeSearchFilterAPI(filterSettings, topMatches)
      .then(async (response) => {
        for (let employee of response.data) {
            try {
                const blobProfile = await getProfilePhotoAPI(employee.userId);
                employee.profilePicture = URL.createObjectURL(blobProfile.data);
            } catch (error) {
                employee.profilePicture = defaultImage;
            }

          try {
            const blobBackground = await getBackgroundPhotoAPI(employee.userId);
            employee.headerPicture = URL.createObjectURL(blobBackground.data);
          } catch (error: any) {
            // When there is no customized headerPicture (background), styling in "SearchResults.scss > .resultsCardHeader (background-image)" is used
          }

          employeeList.push(employee);
        }
        setTopMatches(employeeList);
      })
      .then(async () => {
        // false = normal search
        employeeSearchFilterAPI(filterSettings!, !topMatches).then(
          async (response) => {
            var otherEmployees: employeeSearchDTO[] = [];
            for (let employee of response.data) {
              // prevent duplicates
              if (!employeeList.some((x) => x.userId === employee.userId)) {
                try{
                    const blobProfile = await getProfilePhotoAPI(employee.userId);
                    employee.profilePicture = URL.createObjectURL(blobProfile.data);
                }
                catch(error){
                    employee.profilePicture = defaultImage;
                }

                otherEmployees.push(employee);
              }
            }
            setSearchResults(otherEmployees);
            setIsSubmitting(false);
          }
        );
      });
    // Load existing last search filters from local storage
    const existingLastSearchFilters = JSON.parse(localStorage.getItem("lastSearchFilters") || "{}");

    existingLastSearchFilters[currentRecrewter] = filterSettings;

    localStorage.setItem("lastSearchFilters", JSON.stringify(existingLastSearchFilters));
    };

  const handleStarClickInParent = async (
    employee: employeeSearchDTO,
    userId: string
  ) => {
    setFavoriteCards((prevFavorites) => {
      const userToAddTopMatches = topMatches.find(
        (user) => user.userId === userId
      );
      const userToAddSearchResults = searchResults.find(
        (user) => user.userId === userId
      );

      let updatedFavorites = [...prevFavorites];
      updatedFavorites = updatedFavorites.filter(
        (favUser) => favUser.userId !== userId
      );

      setFavorites((prev) => {
        const updatedStars = { ...prev }; // Create a new object for favorites
        const currentRecrewterFavorites = favorites[currentRecrewter] || [];

        if (currentRecrewterFavorites.includes(userId)) {
          const filteredFavorites = currentRecrewterFavorites.filter(
            (id) => id !== userId
          );
          updatedStars[currentRecrewter] = filteredFavorites;
        } else {
          updatedStars[currentRecrewter] = [
            ...currentRecrewterFavorites,
            userId,
          ];

          if (userToAddTopMatches) {
            updatedFavorites.push(userToAddTopMatches);
          }

          if (
            userToAddSearchResults &&
            !topMatches.some((user) => user.userId === userId)
          ) {
            updatedFavorites.push(userToAddSearchResults);
          }
        }
        return updatedStars;
      });
      return updatedFavorites;
    });
  };

  return (
    <>
      <div className="employeeSearchContainer">
        <div className="card-body">
          <div>
            <h4 className="findSpecialists">{t("perfectAddition")}</h4>
            <p className="recruitEfficiently">{t("recruitEfficiently")}</p>
          </div>
          <form className="employeeSearchFilters">
            <div className="row">
              <div className="col-md-4">
                <DropdownField
                  displayName="headerCountry"
                  field="country"
                  enumType={Country}
                  selected={filterSettings?.country!}
                  onChange={onChange}
                />
              </div>
              <div className="col-md-4">
                {filterSettings?.country === Country.Switzerland ? (
                  <DropdownField
                    displayName="canton"
                    field="state"
                    enumType={State}
                    selected={filterSettings?.state!}
                    onChange={onChange}
                    disabled={filterSettings?.country !== Country.Switzerland}
                    index={21600}
                    indexSize={27}
                  />
                ) : filterSettings?.country === Country.Germany ? (
                  <DropdownField
                    displayName="state"
                    field="state"
                    enumType={State}
                    selected={filterSettings?.state!}
                    onChange={onChange}
                    disabled={filterSettings?.country !== Country.Germany}
                    index={8400}
                    indexSize={17}
                  />
                ) : filterSettings?.country === Country.Austria ? (
                  <DropdownField
                    displayName="state"
                    field="state"
                    enumType={State}
                    selected={filterSettings?.state!}
                    onChange={onChange}
                    disabled={filterSettings?.country !== Country.Austria}
                    index={1500}
                    indexSize={10}
                  />
                ) : (
                  <DropdownField
                    displayName="state"
                    field=""
                    enumType={State}
                    selected={0}
                    onChange={onChange}
                    disabled={true}
                  />
                )}
              </div>
              <div className="col-md-4">
                <DropdownField
                  displayName="headerEmployementType"
                  field="employmentType"
                  enumType={EmploymentType}
                  selected={filterSettings?.employmentType!}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <DropdownField
                  displayName="headerPosition"
                  field="jobPosition"
                  enumType={JobPosition}
                  selected={filterSettings?.jobPosition!}
                  onChange={onChange}
                />
              </div>
              <div className="col-md-4">
                <DropdownField
                  displayName="headerPensum"
                  field="workload"
                  enumType={Workload}
                  selected={filterSettings?.workload!}
                  onChange={onChange}
                />
              </div>
              <div className="col-md-4">
                <DropdownField
                  displayName="headerSalary"
                  field="salary"
                  enumType={Salary}
                  selected={filterSettings?.salary!}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <DropdownField
                  displayName="headerDivision"
                  field="division"
                  enumType={MedicalDivision}
                  selected={filterSettings?.division}
                  onChange={onChange}
                />
              </div>
              {/* <div className="col-md-4">
                <DropdownField
                  displayName="headerJobType"
                  field="jobType"
                  enumType={JobType}
                  selected={filterSettings?.jobType!}
                  index={filterSettings?.division!}
                  onChange={onChange}
                  disabled={filterSettings?.division === 0}
                />
              </div> */}
              <div className="col-md-4">
                <DropdownField
                  displayName="headerEducationLevel"
                  field="educationLevel"
                  enumType={EducationLevel}
                  selected={filterSettings?.educationLevel!}
                  index={filterSettings?.educationLevel!}
                  onChange={onChange}
                />
              </div>
              <div className="col-md-4">
                <DropdownField
                  displayName="headerAvailability"
                  field="availability"
                  enumType={Availability}
                  selected={filterSettings?.availability!}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 employeeSearchButtons">
                <Button
                  class="btn btn-primary"
                  type="button"
                  onClick={submit}
                  disabled={isSubmitting}
                >
                    <div className="search-reset-button">
                        {isSubmitting ? (
                        <div className="spinner-border spinner-border-sm" role="status" />
                        ) : (
                        <FontAwesomeIcon icon={faSearch} />
                        )}
                        {t("menuSearch")}
                    </div>
                </Button>

                <Button
                  class="btn btn-danger ms-3"
                  type="button"
                  onClick={() => setFilterSettings(getEmptyForm())}
                >
                    <div className="search-reset-button" >
                      <FontAwesomeIcon icon={faRefresh} />
                      {t("reset")}
                    </div>
                </Button>
              </div>
            </div>
          </form>
          {
            <div className="row gx-0">
              <h4 className="titleTopMatch">{t("topMatches")}</h4>
              {/* This will contain only the top results */}
              {topMatches.length !== 0 ? (
                <SearchResults
                  results={topMatches}
                  handleStarClick={handleStarClickInParent}
                  isFavorite={favorites}
                />
              ) : (
                <p>{t("noMatches")}</p>
              )}
              {/* This will contain all other results */}
              <Accordion>
                <Accordion.Item
                  eventKey={
                    searchResults.length > 0 ? searchResults[0].userId : ""
                  }
                >
                  <Accordion.Header>
                    <>{t("otherMatches")}</>
                  </Accordion.Header>
                  <Accordion.Body className="equalZeroPadding">
                    <div className="row">
                      <SearchResults
                        results={searchResults}
                        handleStarClick={handleStarClickInParent}
                        isFavorite={favorites}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion>
                <Accordion.Item
                  eventKey={
                    searchResults.length > 0 ? searchResults[0].userId : ""
                  }
                >
                  <Accordion.Header
                    className="btn btn-primary equalZeroPadding"
                    style={{ width: "100%" }}
                  >
                    <span style={{ marginRight: "0.5em" }}>
                      {t("listFavourites")}
                    </span>
                    <FontAwesomeIcon
                      icon={faStar}
                      size="lg"
                      style={{ color: "#FFD43B" }}
                    />
                  </Accordion.Header>
                  <Accordion.Body className="equalZeroPadding">
                    <div className="row">
                      {favoriteCards.length !== 0 ? (
                        <>
                          <SearchResults
                            results={favoriteCards}
                            handleStarClick={handleStarClickInParent}
                            isFavorite={favorites}
                          />
                        </>
                      ) : (
                        <p>{t("noMatches")}</p>
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          }
        </div>
      </div>
    </>
  );
}
