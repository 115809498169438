import React, { useEffect } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { userDetailsForAdminDTO } from "../core/dto/admin.models";
import { activateUserAPI, getProfilePhotoAPI } from "../core/apiFunctions";
import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import { FcCancel, FcApproval  } from "react-icons/fc";
import { BsCalendarDate, BsChatLeftFill } from "react-icons/bs";
import { userDetailsDTO } from "../core/dto/user.models";
import { useTranslation } from "react-i18next";
import "./UserList.scss";
import { convertDateTimeToDate } from "../core/helperFunctions";

export default function UserEntry(props: userListProps) {

    const navigate = useNavigate();

    const [profilePicture, setProfilePicture] = React.useState<string>();

    const { t } = useTranslation();

    useEffect(() => {
        getProfilePhotoAPI(props.user.id).then((response) => {
            setProfilePicture(URL.createObjectURL(response.data));
        });

    }, [props.user.id])

    return (
        <div className="userList">
            <img src={profilePicture} alt="profile" className="profile-photo" />
            <div className="userListInfo">
                <div>{props.user.firstName} {props.user.lastName}</div>

                {props.displayMail && <div><AiOutlineMail />&nbsp; <a onClick={(e) => e.stopPropagation()}
                    href={"mailto:" + props.user.email}>{props.user.email}</a></div>}
                {props.displayPhone && <div><AiOutlinePhone />&nbsp; <a onClick={(e) => e.stopPropagation()}
                    href={"tel:" + props.user.phoneNumber}>{props.user.phoneNumber}</a></div>}
                <div><BsCalendarDate /> {convertDateTimeToDate(props.user.createdDate)}</div>
                {props.displayActivationStatus && (
                    <div>
                        {(props.user as userDetailsForAdminDTO).isActive ? (
                            <>
                                <FcApproval className="mb-1" /> <span>{t("Activated")}</span>
                            </>
                        ) : (
                            <>
                                <FcCancel className="mb-1" /> <span>{t("Deactivated")}</span>
                            </>
                        )}
                    </div>
                )}

            </div>
            <ButtonGroup>
                {/* <Button variant="outline-danger" size='sm'><FontAwesomeIcon icon={faTrash} /> <span>Delete</span></Button> */}
                {/* <Button variant="outline-success" size='sm'><FontAwesomeIcon icon={faPen} /> <span>Edit</span></Button> */}
                {/* <Button variant="outline-primary" size='sm'><FontAwesomeIcon icon={faSmile} /> <span>Action</span></Button> */}
                {props.displayChat &&
                    <Button variant="outline-primary" size='sm' onClick={(e) => {
                        e.stopPropagation();
                        navigate("/chat/" + props.user.id);
                    }}><BsChatLeftFill /> <span>Chat</span></Button>
                }
            </ButtonGroup>
        </div>
    )
}

export interface userListProps {
    user: userDetailsForAdminDTO | userDetailsDTO;
    displayMail?: boolean;
    displayPhone?: boolean;
    displayChat?: boolean;

    displayRemoveConnection?: boolean;

    displayActivationStatus: boolean;
}

UserEntry.defaultProps = {
    displayMail: false,
    displayPhone: false,
    displayChat: true,

    displayRemoveConnection: false,
    displayActivationStatusToggle: false
}