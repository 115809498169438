import React, { useContext, useEffect, useState } from "react";
import JobOfferCompanyPortfolio from "../components/JobOffer/JobOfferCompanyDetails";
import JobOfferDescription from "../components/JobOffer/JobOfferDescription";
import JobOfferHeader from "../components/JobOffer/JobOfferHeader";
import Menu from "../components/Menu/Menu";
import { AlertAction } from "../core/helperConst";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { internalJobDTO, jobDescriptionDTO, jobOfferDTO } from "../core/dto/dto.models";
import { AlertModal } from "../utils/AlertModel";
import { UserRoles } from "../config/Types/GeneralEnumDefinitions";
import "./JobOffer.scss";
import {
  createJobOfferAPI,
  deleteJobOfferAPI,
  getJobOfferAPI,
  isJobOfferPublishedAPI,
  toggleJobOfferAPI,
  updateJobOfferAPI,
} from "../core/apiFunctions";
import { getRoleId } from "../core/claimFunctions";
import AuthenticationContext from "../auth/AuthenticationContext";

// Jobs / Stellen
export default function JobOffer() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Get the role of the user
  const { claims } = useContext(AuthenticationContext);
  const role = getRoleId(claims);
  const [isRecrewter] = useState<boolean>(
    role === UserRoles.Recrewter ? true : false
  );

  // When a new Joboffer is created, in the url the jobofferId is 'create'
  const newJobOffer = "create";
  // Get the jobofferId from the url
  const { jobofferId } = useParams<{ jobofferId: string }>();

  // Store the joboffer details from a subcomponent
  const [jobOfferCompanyDetails, setJobOfferCompanyDetails] = useState<internalJobDTO>();
  const [jobDescription, setJobDescription] = useState<jobDescriptionDTO>();
  const [jobOffer, setJobOffer] = useState<jobOfferDTO>();

  const [isPublished, setIsPublished] = useState<boolean>(false);

  // if the jobofferId is 'create' then the joboffer doesn't need to show an alert
  const [showAlert, setShowAlert] = useState<boolean>(
    jobofferId === newJobOffer ? false : true
  );
  const [alert, setAlert] = useState<AlertAction>(AlertAction.NONE);
  const [editMode, setEditMode] = useState<boolean>(
    alert === AlertAction.EDIT ? true : false
  );
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [hasErrors, setHasErrors] = useState(false);

  const handleErrorsChange = (hasErrors: boolean) => {
  // Update hasErrors state
  setHasErrors(hasErrors);
  };

  // Get the joboffer details from a subcomponent
  const fetchJobOfferCompanyDetailsComponent = (job: internalJobDTO) => {
    setJobOfferCompanyDetails(job);
  };

  // Get the joboffer details from a subcomponent
  const fetchJobOfferDescriptionComponent = (jobOffer: jobDescriptionDTO) => {
    setJobDescription(jobOffer);
  };

  const handleAlertAction = (action: AlertAction) => {
    setAlert(action);

    if (action === AlertAction.UPDATE || action === AlertAction.PREVIEW) handleUpdate();
    if (action === AlertAction.SAVE) handleSave();
    if (action === AlertAction.EDIT) setEditMode(true);
    if (action === AlertAction.REMOVE) setShowDeleteAlert(true);
    if (action === AlertAction.PUBLISH) handlePublishJobOffer();
    if (action === AlertAction.HIDE) handlePublishJobOffer();
  };
  const handleShowAlert = (show: boolean) => {
    setShowAlert(show);
  };

  async function handleUpdate() {
    setEditMode(false);
    handleAlertAction(AlertAction.SAVE);
  }

  async function handleRemove() {
    setIsPublished(false);
    await deleteJobOfferAPI(jobofferId !== undefined ? jobofferId : "").catch(
      () => {
        // joboffer not found
      }
    );
    navigate("/jobs");
  }

  async function handleSave() {
    if (jobOffer !== undefined) {

      var jobForUpload: jobOfferDTO = {
          id: jobOffer.id ?? "",
          title: jobOffer.title,
          description: jobOffer.description,
          workload: jobOffer.workload,
          salary: jobOffer.salary,
          employmentType: jobOffer.employmentType,
          country: jobOffer.country,
          state: jobOffer.state,
          jobPosition: jobOffer.jobPosition,
          educationLevel: jobOffer.educationLevel,
          division: jobOffer.division,
          availability: jobOffer.availability,
          jobType: jobOffer.jobType,
          companyUrl: jobOffer.companyUrl,
          discriminator: "Internal",
          createdDate: jobOffer.createdDate,
          lastUpdatedDate: jobOffer.lastUpdatedDate,
          isPublished: jobOffer.isPublished,
          recrewterId: jobOffer.recrewterId,
          companyDescription: jobOffer.companyDescription
      };

      // check if the joboffer is new according to the url parameter
      if (jobofferId === newJobOffer) {
        // Save the job
        createJobOfferAPI(jobForUpload)
          .then((response) => {
            jobForUpload.id = response.data;
            setJobOffer(jobForUpload);

            if (jobForUpload.id !== undefined){
                navigate(`/jobs/${jobForUpload.id}`);
            }
          });
      }
      // The joboffer is not new
      else {
        // Update the joboffer
        jobForUpload.id = jobofferId !== undefined ? jobofferId : "";

        updateJobOfferAPI(jobForUpload)
          .then((response) => {
            // Update the job
            jobForUpload.id = response.data;
            updateJobOfferAPI(jobForUpload);
          });
      }
    }
  }

  function handlePublishJobOffer() {
    //call toggle
    toggleJobOfferAPI(jobofferId!).then((response) => {
      setIsPublished(response.data === true ? true : false);
    });
  }

  useEffect(() => {
    const fetchIsPublished = async () => {
      isJobOfferPublishedAPI(jobofferId!).then((response) => {
        setIsPublished(response.data);
      });
    };
    fetchIsPublished();

    const fetchInitalData = async () => {
      await getJobOfferAPI(jobofferId!).then((response) => {
        var jobDto: internalJobDTO = {
            id: response.data.id ?? "",
            companyUrl: response.data.companyUrl,
            title: response.data.title,
            description: response.data.description,
            workload: response.data.workload,
            salary: response.data.salary,
            employmentType: response.data.employmentType,
            country: response.data.country,
            state: response.data.state,
            jobPosition: response.data.jobPosition,
            educationLevel: response.data.educationLevel,
            division: response.data.division,
            availability: response.data.availability,
            jobType: response.data.jobType,
            discriminator: response.data.discriminator,
            createdDate: response.data.createdDate,
            lastUpdatedDate: response.data.lastUpdatedDate,
            isPublished: isPublished
        };
        setJobOfferCompanyDetails(jobDto);

        var jobofferDto: jobDescriptionDTO = {
          id: response.data.id ?? "",
          isManager: response.data.isManager === true ? true : false,
          title: response.data.title ?? "",
          jobDescription: response.data.description ?? "",
          companyDescription: response.data.companyDescription ?? "",
        };
        setJobDescription(jobofferDto);
      });
    };
    fetchInitalData();
  }, []);

  useEffect(() => {
    var jobwithjoboffer: jobOfferDTO = {
        isPublished: isPublished,
        recrewterId: "",
        workload: jobOfferCompanyDetails?.workload,
        salary: jobOfferCompanyDetails?.salary,
        employmentType: jobOfferCompanyDetails?.employmentType,
        country: jobOfferCompanyDetails?.country,
        jobPosition: jobOfferCompanyDetails?.jobPosition,
        jobType: jobOfferCompanyDetails?.jobType,
        division: jobOfferCompanyDetails?.division,
        availability: jobOfferCompanyDetails?.availability,
        state: jobOfferCompanyDetails?.state,
        educationLevel: jobOfferCompanyDetails?.educationLevel,
        companyUrl: jobOfferCompanyDetails?.companyUrl,
        id: jobofferId,
        title: jobDescription?.title,
        description: jobDescription?.jobDescription,
        companyDescription: jobDescription?.companyDescription,
        discriminator: jobOfferCompanyDetails?.discriminator ?? "Offer",
        createdDate: jobOfferCompanyDetails?.createdDate,
        lastUpdatedDate: jobOfferCompanyDetails?.lastUpdatedDate
    };

    setJobOffer(jobwithjoboffer);
  }, [jobOfferCompanyDetails, jobDescription]);

  return (
    <>
      <Menu />
      <AlertModal
        message={t("deleteJobOfferAlertMsg")}
        handleClose={() => setShowDeleteAlert(false)}
        show={showDeleteAlert}
        buttons={[
          {
            text: t("yes"),
            props: {
              onClick: () => {
                handleRemove();
                setShowDeleteAlert(false);
              },
            },
          },
          {
            text: t("no"),
            props: {
              onClick: () => setShowDeleteAlert(false),
              variant: "light",
            },
          },
        ]}
      />
      {jobofferId && (
        <>
          <JobOfferHeader
            isRecrewter={isRecrewter}
            jobOfferId={jobofferId}
            showAlert={showAlert}
            alertAction={handleAlertAction}
            action={alert ? alert : AlertAction.NONE}
            isPublished={isPublished}
            jobValues={fetchJobOfferDescriptionComponent}
            showAlertPreview={handleShowAlert}
            disableButton={hasErrors}
          />
          <div className="menuContainer">
            <div className="jobAdv">
              <JobOfferDescription
                jobOfferId={jobofferId}
                showAlert={handleShowAlert}
                action={alert ? alert : AlertAction.NONE}
                editMode={editMode}
                jobValues={fetchJobOfferDescriptionComponent}
                onErrorsChange={handleErrorsChange}
              />
              <JobOfferCompanyPortfolio
                jobOfferId={jobofferId}
                showAlert={handleShowAlert}
                action={alert ? alert : AlertAction.NONE}
                editMode={editMode}
                jobValues={fetchJobOfferCompanyDetailsComponent}
                alertAction={handleAlertAction}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}
