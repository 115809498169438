import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function NotificationContent({ setNotification, toggleNotificationsAPI, isNotificationEnabled }: NotificationContentProps) {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t("notification.settings");
    }, [t]);

    return (
        <div className="form-check form-switch public-toggle progressWithToolTip">
            <input
                className="form-check-input mb-1"
                type="checkbox"
                id="flexSwitchNotification"
                checked={isNotificationEnabled}
                onChange={(e) => {
                    setNotification(e.target.checked);
                    toggleNotificationsAPI();
                }}
            />
            <label className="form-check-label ms-2" htmlFor="flexSwitchNotification">{ isNotificationEnabled ? t("notifications.enabled") : t("notifications.disabled")}</label>
        </div>
    );
}

interface NotificationContentProps {
    setNotification: (enabled: boolean) => void;
    toggleNotificationsAPI: () => void;
    isNotificationEnabled: boolean;
}