import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

export default function StatisticsManagement() {

    const { t } = useTranslation();

    return (
        <Container className="d-flex align-items-center justify-content-center" >
            <Row>
                <Col>
                    <h3 className="text-center">{t("pageUnderConstruction")}</h3>
                </Col>
            </Row>
        </Container>
    )
}
