import { faCommenting } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  JobPosition,
  Workload,
  Salary,
  Availability,
  EducationLevel,
} from "../../config/Types/GeneralEnumDefinitions";
import {
  MedicalDivision,
} from "../../config/Types/MedicalEnumDefinitions";
import { employeeSearchDTO } from "../../core/dto/dto.models";
import { enumToPercentage } from "../../core/helperConst";
import ProgressWithToolTip from "../../utils/ProgressWithToolTip";
import { getNameIdentifier } from "../../core/claimFunctions";
import "./SearchResults.scss";
import AuthenticationContext from "../../auth/AuthenticationContext";

export default function SearchResults({
  results,
  handleStarClick,
  isFavorite,
}: {
  results?: employeeSearchDTO[];
  handleStarClick: (employee: employeeSearchDTO, userId: string) => void;
  isFavorite: Record<string, string[]>;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { claims } = useContext(AuthenticationContext);
  const currentRecrewter = getNameIdentifier(claims);
  const cardClickEvent = (userId: string) => {
    navigate("/cv/" + userId);
  };

  return (
    <>
      {results?.map((employee, index) => (
        <div key={index} className="col-lg-4 col-md-6 col-12 resultsContainer">
          <div className="resultsCard">
            <div
              className="resultsCardHeader"
              style={{
                backgroundImage:
                  employee.headerPicture && `url(${employee.headerPicture})`,
              }}
            >
              <a onClick={() => cardClickEvent(employee.userId)}>
                <div className="resultsProfileImage">
                  <img alt={"profilepicture"} src={employee.profilePicture} />
                </div>
              </a>
              <div
                className={`starIcon ${
                  isFavorite[currentRecrewter]?.includes(employee.userId)
                    ? "starIconClicked"
                    : "starIconNotClicked"
                }`}
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon
                  key={index}
                  icon={faStar}
                  size="xl"
                  onClick={() => handleStarClick(employee, employee.userId)}
                />
              </div>
              <div>
                <a onClick={() => navigate("/chat/" + employee.userId)}>
                  <FontAwesomeIcon icon={faCommenting} />
                </a>
              </div>
            </div>

            <a onClick={() => cardClickEvent(employee.userId)}>
              <div className="resultsAbout">
                <p className="resultsTitle">{t("aboutMe")}</p>
                <p>{employee.aboutMe}</p>
              </div>

              <div>
                <p className="resultsTitle">{t("myDreamJob")}</p>
                <div className="resultsDreamJob">
                  {/* <div>
                    <p>{t("jobTitle")}:</p>
                    <p>{t(JobType[employee.dreamJob.jobType])}</p>
                  </div> */}
                  <div>
                    <p>{t("headerEducationLevel")}:</p>
                    <p>{t(EducationLevel[employee.dreamJob.educationLevel])}</p>
                  </div>
                  <div>
                    <p>{t("division")}:</p>
                    <p>{t(MedicalDivision[employee.dreamJob.division])}</p>
                  </div>
                  <div>
                    <p>{t("position")}:</p>
                    <p>{t(JobPosition[employee.dreamJob.jobPosition])}</p>
                  </div>
                </div>
                <ProgressWithToolTip
                  value={enumToPercentage(employee.dreamJob.workload, Workload)}
                  title={t("pensum")}
                  toolTip={
                    enumToPercentage(employee.dreamJob.workload, Workload) + "%"
                  }
                />
                <ProgressWithToolTip
                  value={enumToPercentage(employee.dreamJob.salary, Salary)}
                  title={t("headerSalary")}
                  toolTip={t(Salary[employee.dreamJob.salary])}
                />
                <div className="resultsDreamJob">
                  <div>
                    <p>{t("startDate")}:</p>
                    <p>{t(Availability[employee.dreamJob.availability])}</p>
                  </div>
                  <div className="buttonProfile">
                    <button type="button" className="btn btn-primary">
                      {t("viewFullProfile")}
                    </button>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      ))}
    </>
  );
}
