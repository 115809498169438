import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export default function IconMenuLink(props: iconMenuLinkProps) {
    const { t } = useTranslation();
    return (
        <div className="element">
            <NavLink className={props.classLink} to={props.whereTo}>
                <p>
                        {
                            props.icon
                                ? <img className={props.classImage} src={props.icon} alt={props.iconAlt} />
                                : null
                        }
                        {
                            props.translation
                                ? t(props.translation)
                                : null
                        }
                        {props.additionalKey !== undefined && props.additionalKey > 0 && ( // Render unread count if it exists
                        <span className="unread-countMenu" >
                            <span >{props.additionalKey}</span>
                        </span>
                        )}
                </p>               
            </NavLink>
        </div>
    )
}

interface iconMenuLinkProps {
    classImage?: string,
    classLink?: string,
    icon?: string,
    iconAlt?: string,
    translation?: string,
    whereTo: string
    additionalKey?: number
}

IconMenuLink.defaultProps = {
}