import React from "react";
import EmployeeSearch from "../components/Employee Search/EmployeeSearch";
import Menu from "../components/Menu/Menu";

export default function Personal() {
return (
        <>
            <Menu />
            <EmployeeSearch />
        </>
    )
}