import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { ReactElement, useState } from "react";
import { UserRoles } from "../config/Types/GeneralEnumDefinitions";
import { checkUserAuthorized, checkRoles, checkLoggedInStatus } from "../core/claimFunctions";
import AuthenticationContext from "./AuthenticationContext";

export default function AuthorizedExtended(props: authorizedProps){

    const [isAuthorized, setIsAuthorized] = useState(false);
    const [needsToLogIn, setNeedsToLogIn] = useState(false);

    const {claims} = useContext(AuthenticationContext);


    useEffect(() => {

        if (props.roles) {

            const isRoleCorrect = checkRoles(claims, props.roles);
            const isUserAuthorized = checkUserAuthorized(claims);
            const isloggedIn = checkLoggedInStatus(claims);

            setNeedsToLogIn(!isloggedIn);
            setIsAuthorized(isloggedIn && isRoleCorrect && isUserAuthorized);

        }
    }, [claims, props.roles]);
    return(
        <>
            {isAuthorized && !needsToLogIn   ?
                props.authorized : ( needsToLogIn ? props.relogIn : props.notAuthorized)
            }
        </>
    )
}
interface authorizedProps{
    authorized: ReactElement;
    notAuthorized: ReactElement;
    relogIn?: ReactElement;
    roles: UserRoles[];
}