import React, { useContext, useEffect, useState } from "react";
import Authorized from "../auth/Authorized";
import Menu from "../components/Menu/Menu";
import AuthenticationContext from "../auth/AuthenticationContext";
import { Row, Col, Form } from "react-bootstrap";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { extendedJobOfferDTO, jobApplicationDTO, externalJobOfferDTO } from "../core/dto/dto.models";
import { getName, getRoleId } from "../core/claimFunctions";
import { getCompanyJobOffersAPI, getAllInternalJobOffers, getAllExternalJobOffers, getDescriptionLanguages } from "../core/apiFunctions";
import { Title, UserRoles } from "../config/Types/GeneralEnumDefinitions";
import { useNavigate } from "react-router-dom";
import Button from "../utils/Button";
import { HiPlus } from "react-icons/hi";
import JobAccordion from "../components/JobOffer/JobAccordion";
import ExternalJobAccordion from "../components/JobOffer/ExternalJobAccordion";
import Pagination from "../utils/Pagination";
import JobsCHLogo from "../img/JobsCHLogo.png";
import { useDebounce } from "../core/helperFunctions";
import "./Jobs.scss";

export default function Jobs() {
    const { t } = useTranslation();
    const { claims } = useContext(AuthenticationContext);

    const navigate = useNavigate();

    const [jobOfferEntities, setJobOfferEntities] = useState<extendedJobOfferDTO[]>([]);
    const [externalJobOfferEntities, setExternalJobOfferEntities] = useState<externalJobOfferDTO[]>([]);
    const [applicationEntities, setApplicationEntities] = useState<jobApplicationDTO[]>([]);

    const [totalAmountOfInternalPages, setTotalAmountOfInternalPages] = useState(0);
    const [internalRecordsPerPage, setInternalRecordsPerPage] = useState(25);
    const [internalPage, setInternalPage] = useState(1);

    const [totalAmountOfExternalPages, setTotalAmountOfExternalPages] = useState(0);
    const [externalRecordsPerPage, setExternalRecordsPerPage] = useState(25);
    const [externalPage, setExternalPage] = useState(1);

    const [totalAmountOfCompanyPages, setTotalAmountOfCompanyPages] = useState(0);
    const [companyRecordsPerPage, setCompanyRecordsPerPage] = useState(25);
    const [companyPage, setCompanyPage] = useState(1);
    const [currentRole] = useState(getRoleId(claims));

    const [searchTerm, setSearchTerm] = useState("");
    const [sortByInternal, setSortByInternal] = useState("job");
    const [sortByExternal, setSortByExternal] = useState("title");
    const [sortByCompany, setSortByCompany] = useState("name");
    const [sortDirection, setSortDirection] = useState("ASC");

    const [loadingInternal, setLoadingInternal] = useState(false);
    const [loadingExternal, setLoadingExternal] = useState(false);
    const [descriptionLanguage, setDescriptionLanguage] = useState("");
    const [descriptionLanguages, setDescriptionLanguages] = useState<string[]>([]);

    const debouncedDescriptionLanguage = useDebounce(descriptionLanguage, 500);
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect(() => {
        if (UserRoles.Employee === currentRole) {
            loadInternalJobOffers();
        }
    }, [internalPage, internalRecordsPerPage, sortByInternal, sortDirection, debouncedSearchTerm]);

    useEffect(() => {
        if (UserRoles.Employee === currentRole) {
            loadExternalJobOffers();
            loadDescriptionLanguages();
        }
    }, [externalPage, externalRecordsPerPage, sortByExternal, sortDirection, debouncedDescriptionLanguage, debouncedSearchTerm]);

    useEffect(() => {
        if (UserRoles.Recrewter === currentRole) {
            loadCompanyJobOffers();
        }
    }, [companyPage, companyRecordsPerPage, sortByCompany, sortDirection]);

    function loadInternalJobOffers() {
        setLoadingInternal(true);
        getAllInternalJobOffers(internalPage, internalRecordsPerPage, sortByInternal, sortDirection, debouncedSearchTerm)
            .then((response: AxiosResponse<extendedJobOfferDTO[]>) => {
                const totalAmountOfRecords = parseInt(response.headers['totalamountofrecords'], 10);
                setTotalAmountOfInternalPages(Math.ceil(totalAmountOfRecords / internalRecordsPerPage));

                var offers: extendedJobOfferDTO[] = [];
                response.data.forEach((jobOffer) => {
                    const name = getName(claims);
                    if (jobOffer.salutation === Title.None) {
                        jobOffer.applyUrl = "/chat/" + jobOffer.applyUrl + "/" + encodeURIComponent(
                            t("message.hello.general") + t("message.intentionApply") + "\"" + jobOffer.title + "\" " + t("message.at") + " " + jobOffer.companyName + ".");
                    } else {
                        jobOffer.applyUrl = "/chat/" + jobOffer.applyUrl + "/" + encodeURIComponent(
                            t("message.hello") + Title[jobOffer.salutation ?? Title.None] + " " + name + ". " + t("message.intentionApply") +
                            "\"" + jobOffer.title + "\" " + t("message.at") + " " + jobOffer.companyName + ".");
                    }
                    offers.push(jobOffer);
                });

                setJobOfferEntities(offers);
                setLoadingInternal(false);
            }).catch(() => setLoadingInternal(false));
    }

    function loadExternalJobOffers() {
        setLoadingExternal(true);
        getAllExternalJobOffers(externalPage, externalRecordsPerPage, sortByExternal, sortDirection, debouncedSearchTerm, debouncedDescriptionLanguage)
            .then((response: AxiosResponse<externalJobOfferDTO[]>) => {
                const totalAmountOfRecords = parseInt(response.headers['totalamountofrecords'], 10);
                setTotalAmountOfExternalPages(Math.ceil(totalAmountOfRecords / externalRecordsPerPage));

                setExternalJobOfferEntities(response.data);
                setLoadingExternal(false);
            }).catch(() => setLoadingExternal(false));
    }

    function loadCompanyJobOffers() {
        getCompanyJobOffersAPI(companyPage, companyRecordsPerPage, sortByCompany, sortDirection)
            .then((response: AxiosResponse<jobApplicationDTO[]>) => {
                const totalAmountOfRecords = parseInt(response.headers['totalamountofrecords'], 10);
                setTotalAmountOfCompanyPages(Math.ceil(totalAmountOfRecords / companyRecordsPerPage));

                setApplicationEntities(response.data);
            });
    }

    function loadDescriptionLanguages() {
        getDescriptionLanguages()
            .then((response: AxiosResponse<string[]>) => {
                setDescriptionLanguages(response.data);
            });
    }

    const handleInternalPageChange = (page: number) => {
        setInternalPage(page);
    };

    const handleInternalRecordsPerPageChange = (recordsPerPage: number) => {
        setInternalRecordsPerPage(recordsPerPage);
        setInternalPage(1);
    };

    const handleExternalPageChange = (page: number) => {
        setExternalPage(page);
    };

    const handleExternalRecordsPerPageChange = (recordsPerPage: number) => {
        setExternalRecordsPerPage(recordsPerPage);
        setExternalPage(1);
    };

    const handleCompanyPageChange = (page: number) => {
        setCompanyPage(page);
    };

    const handleCompanyRecordsPerPageChange = (recordsPerPage: number) => {
        setCompanyRecordsPerPage(recordsPerPage);
        setCompanyPage(1);
    };

    const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const handleSearch = () => {
        setInternalPage(1);
        setExternalPage(1);
        loadInternalJobOffers();
        loadExternalJobOffers();
    };

    const handleDescriptionLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setDescriptionLanguage(e.target.value);
        setExternalPage(1);
    };

    return (
        <>
            <Menu />
            <Authorized role={UserRoles.Employee}
                authorized={
                    <div className="p-5">
                        <Row className="mb-5 justify-content-center">
                            <Col md={6} className="d-flex">
                                <Form.Control
                                    type="text"
                                    placeholder={t('searchOverAllJobs')}
                                    value={searchTerm}
                                    onChange={handleSearchTermChange}
                                    className="me-2"
                                />
                                <Button class="btn btn-primary" onClick={handleSearch}>{t('search')}</Button>
                            </Col>
                        </Row>
                        {loadingInternal ? (
                            <Row className="justify-content-center">
                                <h2 style={{ marginBottom: '20px' }}>Digital reCREWter {t("jobOffers")}</h2>
                                <div className="spinner-border spinner-border-l" style={{ color: "var(--primary)" }} />
                            </Row>
                        ) : (
                            <>
                                <Row>
                                    {jobOfferEntities.length > 0 &&
                                        <JobAccordion data={jobOfferEntities} alwaysOpen={false} />
                                    }
                                </Row>
                                <Row className="d-flex justify-content-center">
                                    <Pagination
                                        currentPage={internalPage}
                                        totalAmountOfPages={totalAmountOfInternalPages}
                                        radius={2}
                                        onChange={handleInternalPageChange}
                                    />
                                </Row>
                            </>
                        )}
                        <Row className="d-flex justify-content-center mb-3">
                            <Col className="d-flex flex-column align-items-center">
                                <h2>{t('jobsFrom')}</h2>
                                <img style={{ width: "200px" }} src={JobsCHLogo} alt="JobsCH Logo" />
                            </Col>
                        </Row>
                        {loadingExternal ? (
                            <Row className="justify-content-center">
                                <div className="spinner-border spinner-border-l" style={{ color: "var(--primary)" }} />
                            </Row>
                        ) : (
                            <>
                                {externalJobOfferEntities.length > 0 && descriptionLanguages.length > 0 && (
                                    <Row className="mb-3">
                                        <Col md={3}>
                                            <Form.Group controlId="descriptionLanguage">
                                                <Form.Label><strong>{t('descriptionLanguage')}</strong></Form.Label>
                                                <Form.Select
                                                    value={descriptionLanguage}
                                                    onChange={handleDescriptionLanguageChange}
                                                >
                                                    <option value="">{t('all')}</option>
                                                    {descriptionLanguages.map((language, index) => (
                                                        <option key={index} value={language}>{t(language)}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    {externalJobOfferEntities.length > 0 &&
                                        <ExternalJobAccordion data={externalJobOfferEntities} alwaysOpen={false} />
                                    }
                                </Row>
                                <Row className="d-flex justify-content-center">
                                    <Pagination
                                        currentPage={externalPage}
                                        totalAmountOfPages={totalAmountOfExternalPages}
                                        radius={2}
                                        onChange={handleExternalPageChange}
                                    />
                                </Row>
                            </>
                        )}
                    </div>
                }
            />
            <Authorized role={UserRoles.Recrewter}
                authorized={
                    <div className="p-5">
                        <Row className="createJobOfferContainer">
                            <Col className="createJobOffer">
                                <Button class="btn btn-primary" onClick={() => navigate("/jobs/create")}><HiPlus /> {t("button.createJobOffer")}</Button>
                            </Col>
                        </Row>
                        <Row>
                            {applicationEntities.length > 0 &&
                                <JobAccordion data={applicationEntities} alwaysOpen={true} />
                            }
                        </Row>
                    </div>
                }
            />
        </>
    );
}
