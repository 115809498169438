import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { languageSkillDTO } from "../../../core/dto/user.models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faSave,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../utils/Button";
import TextField from "../../../utils/TextField";
import {
  getLanguageSkillsAPI,
  submitLanguageSkillsAPI,
} from "../../../core/apiFunctions";
import DropdownField from "../../../utils/DropdownField";
import { LanguageNiveau } from "../../../config/Types/GeneralEnumDefinitions";
import { useSetState } from "../../../utils/UseSetState";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import DisplayErrors from "../../../utils/DisplayErrors";
import useFeedback from "../../../utils/useFeedback";

export default function LanguageSkills() {
  useEffect(() => {
    getLanguageSkillsAPI()
      .then((response) => {
        setLanguageSkillsInitial(response.data);
        setLanguageSkills(response.data);
      })
      .catch((error) => {
        setErrors(error.response.data);
      });
  }, []);

  const [errors, setErrors] = useState<string[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [languageSkillsInitial, setLanguageSkillsInitial] = useState<
    languageSkillDTO[]
  >([]);
  const [languageSkills, setLanguageSkills] = useState<languageSkillDTO[]>([]);
  const [fieldErrors, setFieldErrors, getFieldErrors] = useSetState<any[]>([]);
  const { showSuccess }  = useFeedback();

  const initFieldErrors = () => {
    setFieldErrors(
      languageSkills.map(() => {
        return {
          language: "",
          niveau: "",
        };
      })
    );
  };

  const schema = yup.object({
    language: yup.string().required("Language is required"),
    niveau: yup.number().min(1, "Niveau is required"),
  });

  // reset the form to the original values
  const setOrigValues = () => {
    setLanguageSkills(languageSkillsInitial);
    initFieldErrors();

    setIsEditing(false);
  };

  const validateNestedSchema = async () => {
    // foreach each languageSkill, validate the fields
    const errors = await Promise.all(
      languageSkills.map(async (languageSkill) => {
        const errors = await schema
          .validate(languageSkill, { abortEarly: false })
          .catch((err) => {
            return err;
          });

        if (errors instanceof yup.ValidationError) {
          const validationErrors: any = {};
          errors.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message;
          });
          return validationErrors;
        }
        return {};
      })
    );

    setFieldErrors(errors);
  };

  const submit = async () => {
    try {
      await validateNestedSchema();
      // getFieldErrors is needed, because react needs internal state to be updated
      const hasErrors = (await getFieldErrors()).some((fieldError) => {
        return Object.keys(fieldError).length > 0;
      });

      if (!hasErrors) {
        await submitLanguageSkillsAPI(languageSkills);
        setLanguageSkillsInitial(languageSkills);

        setIsEditing(false);
        showSuccess(t("successfullySaved"));
      }
    } catch (error: any) {
      setErrors(error.response.data);
    }
  };

  const handleOnChange = (i: number, e: any) => {
    if (!isEditing) {
      setIsEditing(true);
    }

    // check if fielderrors are initialized
    if (fieldErrors.length === 0) {
      initFieldErrors();
    }

    const { name, value } = e.target;
    const allSkills = [...languageSkills];
    allSkills[i] = { ...allSkills[i], [name]: value };
    setLanguageSkills(allSkills);

    // validate single field at index i
    schema
      .validateAt(name, allSkills[i])
      .then(() => {
        setSingleFieldError(i, name, "");
      })
      .catch((err) => {
        const errors = err.errors.map((err: any) => err);
        setSingleFieldError(i, name, errors[0]);
      });
  };

  const setSingleFieldError = (i: number, name: string, value: string) => {
    const allFieldErrors = [...fieldErrors];
    const modifiedFieldError = { ...fieldErrors[i], [name]: value };
    allFieldErrors[i] = modifiedFieldError;
    setFieldErrors(allFieldErrors);
  };

  const handleOnAdd = () => {
    const newSkill = {
      id: "",
      language: "",
      niveau: LanguageNiveau.None,
    };
    setLanguageSkills([...languageSkills, newSkill]);
    setFieldErrors([
      ...fieldErrors,
      {
        language: "",
        niveau: "",
      },
    ]);

    if (!isEditing) {
      setIsEditing(true);
    }
  };

  const handleOnRemove = (i: number) => {
    const allSkills = [...languageSkills];
    allSkills.splice(i, 1);
    setLanguageSkills(allSkills);
    const allFieldErrors = [...fieldErrors];
    allFieldErrors.splice(i, 1);
    setFieldErrors(allFieldErrors);

    // check if current state is the same as the initial state
    if (JSON.stringify(languageSkillsInitial) === JSON.stringify(allSkills)) {
      setIsEditing(false);
    }

    if (!isEditing) {
      setIsEditing(true);
    }
  };

  const { t } = useTranslation();

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{t("languageSkills")}</h5>

        {languageSkills.map((languageSkill, index) => {
          return (
            <div key={index}>
              <Row>
                <Col md={6}>
                  <TextField
                    displayName="language"
                    field="language"
                    formFloating={true}
                    onChange={(event) => handleOnChange(index, event)}
                    value={languageSkill?.language}
                    validationMessage={fieldErrors[index]?.language}
                  />
                </Col>
                <Col md={5}>
                  <DropdownField
                    displayName="niveau"
                    field="niveau"
                    selected={languageSkill?.niveau}
                    onChange={(event) => handleOnChange(index, event)}
                    enumType={LanguageNiveau}
                    validationMessage={fieldErrors[index]?.niveau}
                  />
                </Col>
                <Col md={1}>
                  <button
                    type="button"
                    className="btn m-2"
                    onClick={() => handleOnRemove(index)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </Col>
              </Row>
            </div>
          );
        })}

        <Row>
          <Col>
            <button className="btn" onClick={handleOnAdd}>
              <FontAwesomeIcon icon={faPlus} /> {t("add")}
            </button>
          </Col>
        </Row>

        <div className="row">
          <div className="col-12">
            <Button
              class="btn btn-primary"
              hidden={!isEditing}
              type="button"
              onClick={submit}
            >
              <FontAwesomeIcon icon={faSave} /> {t("save")}
            </Button>
            <Button
              class="btn btn-danger ms-3"
              hidden={!isEditing}
              type="button"
              onClick={setOrigValues}
            >
              <FontAwesomeIcon icon={faUndo} /> {t("cancel")}
            </Button>
          </div>
        </div>

        <DisplayErrors errors={errors} />
      </div>
    </div>
  );
}
