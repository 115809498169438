import { Claims } from "../config/Claims";
import { UserRoles } from "../config/Types/GeneralEnumDefinitions";
import { claim } from "./dto/auth.models";

export function checkLoggedInStatus(claims : claim[]): boolean {
    if (claims) {
        return claims.some(c => c.name === Claims.UserRole);
    }
    return false;
}

export function getEmail(claims : claim[]): string {
    if (claims) {
        const emailClaim = claims.find(c => c.name === Claims.Email);
        if (emailClaim) {
            return emailClaim.value;
        }
    }
    return "";
}

export function getNameIdentifier(claims : claim[]): string {
    if (claims) {
        const nameIdentifierClaim = claims.find(c => c.name === Claims.NameIdentifier);
        if (nameIdentifierClaim) {
            return nameIdentifierClaim.value;
        }
    }
    return "";
}

export function checkRole(claims : claim[], role: UserRoles): boolean {
    if (claims) {
        return claims.findIndex(c => c.name === Claims.UserRole && c.value === UserRoles[Number(role)]) > -1;
    }
    return false;
}

export function checkRoles(claims : claim[], roles: UserRoles[]): boolean {
    if (claims) {
        // equivalent to c# any
        return roles.some(r => checkRole(claims, r));
    }
    return false;
}

export function getRoleId(claims : claim[]): UserRoles | undefined {
    if (claims) {
        // get index of all roles
        var roleIndexFromEnum = Object.keys(UserRoles).filter((k) => isNaN(Number(k)));

        // get role from claims
        var claimRole = claims.find(c => c.name === Claims.UserRole)?.value ?? "";

        // return role id
        return roleIndexFromEnum.findIndex((k) => k === claimRole);
    }

    // no role matches claim
    return undefined;
}

export function getName(claims : claim[]): string {
    if (claims) {
        var name = claims.find(c => c.name === Claims.Name);
        var surname = claims.find(c => c.name === Claims.Surname);

        if (name && surname) {
            return surname.value + " " + name.value;
        }

        if (name) {
            return name.value;
        }

        if (surname) {
            return surname.value;
        }
    }
    return "";
}

export function checkUserAuthorized(claims : claim[]): boolean {
    if(claims){
        return claims.findIndex(c => c.name === Claims.AuthorizationDecision && c.value === "True") > -1;
    }
    return false;
}

export function checkUserProfileComplete(claims : claim[]): boolean {
    if(claims){
        return claims.findIndex(c => c.name === Claims.UserProfileComplete && c.value === "True") > -1;
    }
    return false;
}

export function getLicence(claims : claim[]): string {
    if (claims) {
        const licenceClaim = claims.find(c => c.name === Claims.Licence);
        if (licenceClaim) {
            return licenceClaim.value;
        }
    }
    return "";
}