import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Menu from "../Menu/Menu";
import CVInfo from "./CVInfo";
import CVFiles from "./CVFiles";
import CVSkills from "./CVSkills";
import CVHeader from "./CVHeader";
import CVDreamJob from "./CVDreamJob";
import CVAbout from "./CVAbout";
import CVEducations from "./CVEducations";
import CVWorkExperience from "./CVWorkExperience";
import CVLanguageSkills from "./CVLanguageSkills";
import { userInformationDTO } from "../../core/dto/user.models";
import { getUserInformationAPI } from "../../core/apiFunctions";
import { UserRoles } from "../../config/Types/GeneralEnumDefinitions";
import "./CV.scss"
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import useFeedback from "../../utils/useFeedback";

export default function CV() {
    const [userInformation, setUserInformation] = useState<userInformationDTO>();
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768)
    const [isEmployee, setIsEmployee] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>(true);
    const { userId } = useParams<{ userId: string }>();
    const cvContainerRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation();
    const { showSuccess, showError }  = useFeedback();

    const handleWindowSizeChange = () => {
        setIsMobile(window.innerWidth <= 768);
    }

    const handleExportToPDF = async () => {
        if (cvContainerRef.current) {
          try {
            setLoading(true);

            // Find all elements with the class exclude-from-pdf
            const elementsToExclude = document.querySelectorAll(".exclude-from-pdf");

            // Hide each element for the PDF export
            elementsToExclude.forEach((element) => {
                if (element instanceof HTMLElement) {
                element.style.display = "none";
                }
            });

            // Use html2canvas to convert the container content to a canvas
            const canvas = await html2canvas(cvContainerRef.current, { scale: 2 });

            // Create a PDF using jspdf
            const pdf = new jsPDF("p", "mm", "a4", true);

            // Calculate the scaled dimensions to maintain aspect ratio
            const maxWidth = pdf.internal.pageSize.getWidth() - 20; // Adjust the margin as needed
            const imgWidth = maxWidth;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            // Add the canvas image to the PDF
            pdf.addImage(canvas.toDataURL("image/png"), "PNG", 10, 20, imgWidth, imgHeight);

            // Show each element again after the PDF export
            elementsToExclude.forEach((element) => {
                if (element instanceof HTMLElement) {
                element.style.display = "block";
                }
            });
            pdf.setFont("helvetica");
            pdf.setTextColor(71, 0, 157); //$primary: #47009d = (71, 0, 157); 
            // Add the title text centered horizontally
            const title = t("digitalRecrewterCV");
            const textWidth = pdf.getTextWidth(title);
            const centerX = (pdf.internal.pageSize.getWidth() - textWidth) / 2;
            pdf.text(title, centerX, 15);

            // Save the PDF
            pdf.save("your-cv.pdf");

            setLoading(false);
            showSuccess(t("successfullySaved"));
          } catch (error) {
            setLoading(false);
            showError(t("errorOccuredWhileExporting"));
          }
        }
      };

    useEffect(() => {
        if (userId) {
            const fetchUserInformation = async () => {
                const userInformation = await getUserInformationAPI(userId);

                setUserInformation(prevState => {
                    return {
                        ...prevState,
                        ...userInformation.data
                    }
                });
                setIsEmployee(userInformation.data.role === UserRoles.Employee);
                setLoading(false);
            }
            fetchUserInformation();
        }

        // Handle window resize
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [userId]);

    if (loading) {
        return (
            <>
                <Menu />
                {/* Loading indicator using Spinner component */}
                <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                    <div className="spinner-border spinner-border-l" style={{ color: "var(--primary)" }} role="status" />
                </div>
            </>
        );
    }

    return (
        <>
            <Menu/>
            {
                userId &&
                <div ref={cvContainerRef}>
                    <CVHeader userId={userId} userInformation={userInformation} />
                    <div className="menuContainer">
                    {!isMobile && (
                    <div className="text-end mt-3">
                       <Button className="btn btn-primary mb-3 exclude-from-pdf" onClick={handleExportToPDF}>
                          {t("exportToPDF")}
                       </Button>
                    </div>
                    )}
                        <Row className="cvRow gx-0 gx-md-4" >
                            {
                                isEmployee ?
                                    !isMobile ? //Desktop order
                                    <>
                                        <Col md="5" lg="4">
                                            <CVDreamJob userId={userId} />
                                            <CVInfo onInformationRecieved={setUserInformation} userId={userId} />
                                            <CVSkills userId={userId} />
                                            <CVFiles userId={userId} />
                                            <CVLanguageSkills userId={userId} />
                                        </Col>
                                        <Col md="7" lg="8">
                                            <CVAbout userId={userId}/>
                                            <CVEducations userId={userId} />
                                            <CVWorkExperience userId={userId} />
                                        </Col>
                                    </>
                                    :  //Mobile order
                                        <Col>
                                            <CVAbout userId={userId}/>
                                            <CVDreamJob userId={userId} />
                                            <CVInfo onInformationRecieved={setUserInformation} userId={userId} />
                                            <CVSkills userId={userId} />
                                            <CVEducations userId={userId} />
                                            <CVLanguageSkills userId={userId} />
                                            <CVWorkExperience userId={userId} />
                                            <CVFiles userId={userId} />
                                        </Col>
                                :
                                    !isMobile ? //Desktop order
                                    <>
                                        <Col md="5" lg="4">
                                            <CVInfo onInformationRecieved={setUserInformation} userId={userId} isEmployee={false}/>
                                        </Col>
                                        <Col md="7" lg="8">
                                            <CVAbout userId={userId} isEmployee={false}/>
                                        </Col>
                                    </>
                                    :  //Mobile order
                                        <Col>
                                            <CVAbout userId={userId} isEmployee={false}/>
                                            <CVInfo onInformationRecieved={setUserInformation} userId={userId} isEmployee={false}/>
                                        </Col>
                            }
                        </Row>
                    </div>
                </div>
            }
        </>
    )
}