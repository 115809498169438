import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageNiveau } from '../../config/Types/GeneralEnumDefinitions';
import { getLanguageSkillsAPI } from '../../core/apiFunctions';
import { languageSkillDTO } from '../../core/dto/user.models';
import { enumToPercentage } from '../../core/helperConst';
import ProgressWithToolTip from '../../utils/ProgressWithToolTip';

export default function CVLanguageSkills(props: CVLanguageSkillsProperties) {

    const { t } = useTranslation();
    const [languages, setLanguages] = useState<languageSkillDTO[]>();
    //const sample = [{"id":"0b22fdf5-7109-4e37-9be7-43d74a478bed","language":"Deutsch","niveau":2},{"id":"cec2b868-cca5-4662-a8bc-6216724630dc","language":"Spanisch","niveau":3}];

    useEffect(() => {
        const fetchLanguages = async () => {
            const languages = await getLanguageSkillsAPI(props.userId);
            setLanguages(languages.data);
        }
        fetchLanguages();
        //setLanguages(sample);
    }, []);


    return (
        <>
        <div className="cvCard">
            <div className="cvCardHeader">
                <p>{t('labelLanguage')}</p>
            </div>
            <div>
                {languages?.map((language: languageSkillDTO, index) => (
                    <ProgressWithToolTip key={index} value={enumToPercentage(language.niveau, LanguageNiveau)} title={language.language} toolTip={t(LanguageNiveau[language.niveau])} />
                ))}
            </div>
        </div>
        </>
    )
}

interface CVLanguageSkillsProperties {
    userId: string;
}