export enum MedicalDivision
{
    None = 0,
    AnesthesiaTechnician=100,
    Pharmacist=200,
    Physician=300,
    Audiometrist=400,
    Chiropractor=500,
    Dietician=600,
    OccupationalTherapist=700,
    Nutritionist=800,
    MedicalSpecialist=900,
    HealthCareSpecialistFaBe=1000,
    HealthCareSpecialistFaGe = 1100,
    FieldMedical = 1200,
    HealthEconomist=1300,
    HealthEducator=1400,
    HealthScientist=1500,
    Midwife=1600,
    Naturopath=1700,
    ClinicalPsychologist=1800,
    HospitalManager=1900,
    NursePractitioner=2000,
    SpeechTherapist=2100,
    Masseur=2200,
    MedicalAffairsDirectorManager=2300,
    MedicalScientificLiaison=2400,
    MedicalScientificRelations=2500,
    MedicalTechnicalAssistant=2600,
    MedicalDocumentationAssistant=2700,
    MedicalDirector=2800,
    MedicalAssistant=2900,
    MedicalLaboratoryAssistant=3000,
    Neurologist=3100,
    EmergencyParamedic=3200,
    Oncologist=3300,
    Optician=3400,
    Optometrist=3500,
    OrthopedicShoemaker=3600,
    Osteopath=3700,
    PathologyAssistant=3800,
    NursingSpecialist=3900,
    NursingAssistant=4000,
    PharmaceuticalIndustryEmployee=4100,
    Pharmacologist=4200,
    PharmaceuticalTechnicalAssistant=4300,
    Physiotherapist=4400,
    Psychiatrist=4500,
    Psychotherapist=4600,
    RegulatoryAffairsDirectorManager=4700,
    AdvancedEmergencyMedicalTechnician=4800,
    EmergencyMedicalTechnician=4900,
    SafetySpecialist=5000,
    SocialWorkerInHealthCare=5100,
    Dentist=5200,
    DentalAssistant=5300
}

export enum JobType
{
    None = 0,

    // Division: Pharmacist
    DrugAnalyst = 201,
    DrugInspector = 202,
    PharmaceuticalCareAssistant = 203,
    Toxicologist = 204,
    HospitalPharmacist = 205,
    CommunityPharmacist = 206,

    // Divison: Doctor
    GeneralPractitioner = 301,
    Anesthesiologist = 302,
    AllergistAndClinicalImmunologist = 303,
    InternalMedicine1 = 304,
    Angiologist = 305,
    OccupationalMedicine = 306,
    Ophthalmologist = 307,
    Surgeon = 308,
    Dermatologist = 309,
    Diabetologist = 310,
    Endocrinologist = 311,
    Gastroenterologist = 312,
    VascularSurgeon = 313,
    Gynecologist = 314,
    Hematologist = 315,
    HandSurgeon = 316,
    CardiothoracicSurgeon = 317,
    FamilyDoctor = 318,
    InfectiousDiseaseSpecialist = 319,
    IntensiveCareMedicine = 320,
    Internist = 321,
    Cardiologist = 322,
    Pediatrician = 323,
    ChildAndAdolescentPsychiatristAndPsychotherapist = 324,
    PediatricSurgeon = 325,
    ClinicalPharmacologistAndToxicologist = 326,
    MedicalGeneticist = 327,
    OralAndMaxillofacialSurgeon = 328,
    Nephrologist = 329,
    Neuropathologist = 330,
    Neurosurgeon = 331,
    Neurologist = 332,
    Oncologist = 333,
    Orthopedist = 334,
    OphthalmicSurgeon = 335,
    OrthopedicSurgeonAndTraumatologistOfTheMusculoskeletalSystem = 336,
    Psychiatrist = 337,
    Pathologist = 338,
    PharmaceuticalMedicine = 339,
    PhysicalMedicineAndRehabilitation = 340,
    PlasticReconstructiveAndAestheticSurgeon = 341,
    Pulmonologist = 342,
    PreventionAndPublicHealthCare = 343,
    Radiologist = 344,
    RadiationOncologist = 345,
    ForensicPathologist = 346,
    Rheumatologist = 347,
    ThoracicSurgeon = 348,
    TropicalAndTravelMedicine = 349,
    Urologist = 350,
    Venereologist = 351,
    VisceralSurgeon = 352,


    // Division: MedicalAssistant
    MedicalAssistantForFunctionalDiagnostics = 2801,
    MedicalAssistantForLaboratoryDiagnostics = 2802,
    MedicalAssistantForRadiology = 2803,
    MedicalAssistantForPhysiotherapy = 2804,
    MedicalAssistantForGeneralMedicine = 2805,
    MedicalAssistantForOrthopedics = 2806,
    MedicalAssistantForOphthalmology = 2807,
    MedicalAssistantForENT = 2808,
    MedicalAssistantForPediatrics = 2809,
    MedicalAssistantForAnesthesia = 2810,
    MedicalAssistantForTraumaSurgery = 2811,
    MedicalAssistantForSurgery = 2812,
    MedicalAssistantForGynecology = 2813,
    MedicalAssistantForInternalMedicine = 2814,
    MedicalAssistantForNeurology = 2815,
    MedicalAssistantForPsychiatry = 2816,
    MedicalAssistantForDermatology = 2817,
    MedicalAssistantForUrology = 2818,
    MedicalAssistantForGastroenterology = 2819,
    MedicalAssistantForPalliativeMedicine = 2820,
    MedicalAssistantForHygieneAndEnvironmentalMedicine = 2821,
    MedicalAssistantForMedicalMicrobiology = 2822
}