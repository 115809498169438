import React, { useEffect, useState } from "react";
import * as yup from 'yup'
import { skillDTO } from "../../../core/dto/user.models";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash, faSave, faUndo } from '@fortawesome/free-solid-svg-icons'
import Button from "../../../utils/Button";
import TextField from "../../../utils/TextField";
import { getSkillsAPI, submitSkillsAPI } from "../../../core/apiFunctions";
import DropdownField from "../../../utils/DropdownField";
import { YearsOfExperience } from "../../../config/Types/GeneralEnumDefinitions";
import { useSetState } from "../../../utils/UseSetState";
import { useTranslation } from "react-i18next";
import DisplayErrors from "../../../utils/DisplayErrors";
import useFeedback from "../../../utils/useFeedback";


export default function Skills() {

    useEffect(() => {
        getSkillsAPI().then(response => {
            setSkillsInitial(response.data);
            setSkills(response.data);

        }).catch(error => {
            setErrors(error.response.data);
        });
    }, []);

    const [errors, setErrors] = useState<string[]>([]);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const [skillsInitial, setSkillsInitial] = useState<skillDTO[]>([]);
    const [skills, setSkills] = useState<skillDTO[]>([]);
    const [fieldErrors, setFieldErrors, getFieldErrors] = useSetState<any[]>([]);
    const { showSuccess }  = useFeedback();

    const initFieldErrors = () => {
        setFieldErrors(skills.map(() => {
            return {
                name: '',
                years: '',
            }
        }));
    }

    const schema = yup.object({
        name: yup.string().required('Name is required'),
        years: yup.number().min(1, 'Years is required'),
    });

    // reset the form to the original values
    const setOrigValues = () => {
        setSkills(skillsInitial);
        initFieldErrors();

        setIsEditing(false);
    }

    const validateNestedSchema = async () => {
        // foreach each skill, validate the fields
        const errors = await Promise.all(skills.map(async (skill) => {
            const errors = await schema.validate(skill, { abortEarly: false }).catch((err) => {
                return err;
            });

            if (errors instanceof yup.ValidationError) {
                const validationErrors: any = {};
                errors.inner.forEach((error: any) => {
                    validationErrors[error.path] = error.message;
                });
                return validationErrors;
            }
            return {};
        }));

        setFieldErrors(errors);
    }

    const submit = async () => {
        try {
            await validateNestedSchema();
            // getFieldErrors is needed, because react needs internal state to be updated
            const hasErrors = (await getFieldErrors()).some((fieldError) => {
                return Object.keys(fieldError).length > 0;
            });

            if (!hasErrors) {
                await submitSkillsAPI(skills);
                setSkillsInitial(skills);

                setIsEditing(false);
                showSuccess(t("successfullySaved"));
            }
        } catch (error: any) {
            setErrors(error.response.data);
        }
    }

    const handleOnChange = (i: number, e: any) => {
        if (!isEditing) {
            setIsEditing(true);
        }

        // check if fielderrors are initialized
        if (fieldErrors.length === 0) {
            initFieldErrors();
        }

        const { name, value } = e.target;
        const allSkills = [...skills];
        allSkills[i] = { ...allSkills[i], [name]: value };
        setSkills(allSkills);

        // validate single field at index i
        schema.validateAt(name, allSkills[i])
            .then(() => {
                setSingleFieldError(i, name, '');
            })
            .catch((err) => {
                const errors = err.errors.map((err: any) => err);
                setSingleFieldError(i, name, errors[0]);
            });
    }

    const setSingleFieldError = (i: number, name: string, value: string) => {
        const allFieldErrors = [...fieldErrors];
        const modifiedFieldError = { ...fieldErrors[i], [name]: value };
        allFieldErrors[i] = modifiedFieldError;
        setFieldErrors(allFieldErrors);
    }

    const handleOnAdd = () => {
        const newSkill = {
            id: '',
            name: '',
            years: YearsOfExperience.None,
        }
        setSkills([...skills, newSkill]);
        setFieldErrors([...fieldErrors, {
            name: '',
            years: '',
        }]);

        if (!isEditing) {
            setIsEditing(true);
        }
    }

    const handleOnRemove = (i: number) => {
        const allSkills = [...skills];
        allSkills.splice(i, 1);
        setSkills(allSkills);
        const allFieldErrors = [...fieldErrors];
        allFieldErrors.splice(i, 1);
        setFieldErrors(allFieldErrors);

        // check if current state is the same as the initial state
        if (JSON.stringify(skillsInitial) === JSON.stringify(allSkills)) {
            setIsEditing(false);
        }

        if (!isEditing) {
            setIsEditing(true);
        }
    }

    const { t } = useTranslation();

    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">{t("skills")}</h5>

                {
                    skills.map((skill, index) => {
                        return (
                            <div key={index}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <TextField displayName="skill" field="name" formFloating={true}
                                            onChange={event => handleOnChange(index, event)} value={skill?.name}
                                            validationMessage={fieldErrors[index]?.name} />
                                    </div>
                                    <div className="col-md-5">
                                        <DropdownField displayName="yearsOfExperience" field="years" selected={skill?.years}
                                            onChange={event => handleOnChange(index, event)} enumType={YearsOfExperience}
                                            validationMessage={fieldErrors[index]?.years} />
                                    </div>
                                    <div className="col-md-1">
                                        <button type="button" className="btn m-2" onClick={() => handleOnRemove(index)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                <div className="row">
                    <div className="col-12">
                        <button className="btn" onClick={handleOnAdd}>
                            <FontAwesomeIcon icon={faPlus} /> {t("add")}
                        </button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <Button class="btn btn-primary" hidden={!isEditing} type="button" onClick={submit}>
                            <FontAwesomeIcon icon={faSave} /> {t("save")}
                        </Button>
                        <Button class="btn btn-danger ms-3" hidden={!isEditing} type="button" onClick={setOrigValues}>
                            <FontAwesomeIcon icon={faUndo} /> {t("cancel")}
                        </Button>
                    </div>
                </div>

                <DisplayErrors errors={errors} />
            </div>
        </div>
    )
}