import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAboutMeAPI, getCompanyDetailsAPI } from "../../core/apiFunctions";

export default function CVAbout(props: CVAboutUserProperties) {
    const [aboutUser, setAboutUser] = useState<String>();
    const [aboutCompany, setAboutCompany] = useState<String>();
    const { t } = useTranslation();

    useEffect(() => {
        if (props.isEmployee) {
            const fetchAboutUser = async () => {
                const aboutUser = await getAboutMeAPI(props.userId);
                setAboutUser(aboutUser.data.aboutMe);
            }
            fetchAboutUser();
        }
        else {
            const fetchAboutCompany = async () => {
                getCompanyDetailsAPI(props.userId).then(response => {
                    setAboutCompany(response.data.description);
                });
            }
            fetchAboutCompany();
        }
    }, []);


    return (
        <>
            <div className="cvCard">
                <div className="cvCardHeader">
                    <p>{props.isEmployee ? t('aboutMe') : t('description')}</p>
                </div>
                {
                    props.isEmployee ?
                        <pre>{aboutUser}</pre>
                    :
                        <pre>{aboutCompany}</pre>
                }
            </div>
        </>
    )
}

interface CVAboutUserProperties {
    userId: string;
    isEmployee?: boolean;
}

CVAbout.defaultProps = {
    isEmployee: true
}