import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import * as yup from 'yup'
import YupPassword from "yup-password";
import FormikTextField from "../utils/FormikTextField";
import { Button } from "react-bootstrap";
import DisplayErrors from "../utils/DisplayErrors";
import { setNewPasswordAPI } from "../core/apiFunctions";
import useFeedback from "../utils/useFeedback";
import { FeedbackMode } from "../config/Types/GeneralEnumDefinitions";
YupPassword(yup)

export default function ResetPasswordContent({ handleCloseModal, email, token }: PasswordChangeContentProps) {
    const { t } = useTranslation();
    const [errors, setErrors] = useState<string[]>([]);
    const { showSuccess } = useFeedback();

    const validationSchema = yup.object({
        // At least 8 characters, at least one letter, one number and one special character
        newPassword: yup.string()
            .required(t("newPasswordRequired"))
            .min(8, t("passwordTooShort"))
            .matches(/[0-9]/, t("passwordNumberRequired"))
            .matches(/[a-zA-Z]/, t("passwordLetterRequired"))
            .matches(/(?=.*[^\da-zA-Z])/, t("passwordSpecialCharacterRequired")),

        confirmNewPassword: yup.string()
            .required(t("confirmPasswordRequired"))
            .oneOf([yup.ref('newPassword'), null], t("errorNonMatchingPassword"))
    });

    async function setNewPassword(values: { newPassword: string; }) {
        try {
            const response = await setNewPasswordAPI({ newPassword: values.newPassword, token: token, email: email });
            if (response.status === 200) {
                handleCloseModal();
                showSuccess(t("passwordChanged"), FeedbackMode.Success, 5000);
            }
            else {
                setErrors(response.data);
            }
        }
        catch (error: any) {
            if (error && error.response) {
                setErrors(error.response.data);
            }
        }
    }

    return (
        <Formik
            initialValues={{ newPassword: '' }}
            onSubmit={async (values) => {
                await setNewPassword(values);
            }}
            validationSchema={validationSchema}
            validateOnBlur={false}
        >

            {() => (
                <Form>
                    <FormikTextField displayName={t("newPassword")} field="newPassword" type="password" />
                    <FormikTextField displayName={t("verifyPassword")} field="confirmNewPassword" type="password" />
                    <DisplayErrors errors={errors} />
                    <div className="buttons">
                        <Button className="btn btn-primary me-3" type="submit">{t("changePassword")}</Button>
                        <Button className="btn btn-secondary" onClick={handleCloseModal}>{t("cancel")}</Button>
                    </div>
                </Form>
            )}

        </Formik>

    );
}

interface PasswordChangeContentProps {
    handleCloseModal: () => void;
    email: string;
    token: string;
}