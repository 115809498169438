import "./FullUserPage.scss";

import { Col, Nav, Row, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { checkRole, checkRoles, checkUserProfileComplete, getNameIdentifier } from "../../core/claimFunctions";
import { deleteUserAPI, getUserDetailsAPI, submitUserDetailsAPI, toggleNotificationsAPI } from "../../core/apiFunctions";

import AboutMe from "./cv/AboutMe";
import AuthenticationContext from "../../auth/AuthenticationContext";
import Authorized from "../../auth/Authorized";
import Certificate from "./cv/Certificate";
import CompanyDetails from "./CompanyDetails";
import DreamJob from "./DreamJob";
import Educations from "./cv/Educations";
import EmployeeManagement from "./admin/EmployeeManagement";
import LanguageSkills from "./cv/LanguageSkills";
import Menu from "../Menu/Menu";
import ModalContent from "../../utils/ModalContent";
import RecrewterManagement from "./admin/RecrewterManagement";
import Skills from "./cv/Skills";
import UserDetails from "./UserDetails";
import { UserRoles } from "../../config/Types/GeneralEnumDefinitions";
import WorkExperience from "./cv/WorkExperience";
import { useTranslation } from "react-i18next";
import { logout } from "../../core/handleJWT";
import PasswordChangeContent from "./PasswordChangeContent";
import NotificationContent from "./NotificationContent";
import StatisticsManagement from "./admin/StatisticsManagement";
import ComingSoon from "../../utils/CommingSoon";

export default function FullUserPage() {

    const { t } = useTranslation();
    let navigate = useNavigate();
    const { claims } = useContext(AuthenticationContext);

    // variables to stear behavior of modal
    const showModalWindowVisibility = "ignoreModalWindowVisibility";
    const [ignoreMissingDataModal, setIgnoreMissingDataModal] = useState(localStorage.getItem(showModalWindowVisibility) === "true");

    const [isVisible, setIsVisible] = useState(checkUserProfileComplete(claims));
    const [missingData, setMissingData] = useState(false);
    const [profileCompleted, setProfileCompleted] = useState(true);
    const [companyProfileCompleted, setCompanyProfileCompleted] = useState(true);
    const [notificationEnabled, setNotificationEnabled] = useState(true);
    const [showDeleteMyAccountModal, setShowDeleteMyAccountModal] = useState(false);
    const [showNotificationModal ,setShowNotificationModal] = useState(false);
    const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);

    const userId = getNameIdentifier(claims);

    useEffect(() => {
        var userDetails = getUserDetailsAPI();
        userDetails.then((response) => {
            setNotificationEnabled(response.data.notificationsEnabled);
        });
    }, []);

    useEffect(() => {

        if (checkRole(claims, UserRoles.Employee)) setMissingData(!profileCompleted);

        // Recrewter companyprofile has to be completed as well
        if (checkRole(claims, UserRoles.Recrewter)) setMissingData(!profileCompleted || !companyProfileCompleted);

    }, [profileCompleted, companyProfileCompleted]);

    function ignoreModalWindow(ignore: boolean) {
        setIgnoreMissingDataModal(ignore);
        if (ignore) localStorage.setItem(showModalWindowVisibility, "true");
        else localStorage.setItem(showModalWindowVisibility, "false");
    }

    async function pauseUser() {
        const response = await getUserDetailsAPI();
        response.data.isPublic = false;
        await submitUserDetailsAPI(response.data);
        window.location.reload();
    }

    return (
        <>
            <Menu />
            {
                missingData
                    ?
                    (
                        // Admin don't have to complete profile
                        !profileCompleted && (checkRole(claims, UserRoles.Employee) || checkRole(claims, UserRoles.Recrewter))
                            ?
                            <ModalContent size="lg" show={missingData} children={
                                <>{t("noPageChangeMissingDataProfile")}</>
                            } onHideCallbackSecondary={() => setMissingData(false)} onHideCallbackPrimary={() => setMissingData(false)}
                                title={"missingData"} primaryButton={"ok"} />
                            :
                            (
                                // Only Recrewter has to complete company profile
                                !companyProfileCompleted && checkRole(claims, UserRoles.Recrewter)
                                    ?
                                    <ModalContent size="lg" show={missingData} children={
                                        <>{t("noPageChangeMissingDataCompanyProfile")}</>
                                    } onHideCallbackSecondary={() => setMissingData(false)} onHideCallbackPrimary={() => setMissingData(false)}
                                        title={"missingData"} primaryButton={"ok"} />
                                    :
                                    null
                            )
                    )
                    :
                    (
                        // Show modal only if user didn't ignore it
                        !isVisible && !ignoreMissingDataModal &&
                        <ModalContent size="lg" show={!ignoreMissingDataModal} children={
                            <>{t("reminder.setVisible")}</>
                        } onHideCallbackSecondary={() => ignoreModalWindow(true)}
                            title={"visibility"} primaryButton={t("ok")} secondaryButton={t("dontShowAgain")} />
                    )
            }
            {
                showDeleteMyAccountModal &&
                <ModalContent
                    size="lg"
                    show={showDeleteMyAccountModal}
                    children={checkRole(claims, UserRoles.Employee) ? t("deleteMyAccountWarningEmployee") : t("deleteMyAccountWarning")}
                    onHideCallbackSecondary={() => setShowDeleteMyAccountModal(false)}
                    onHideCallbackPrimary={() => deleteUserAPI().then(() => logout())}
                    onHideCallbackThird={checkRole(claims, UserRoles.Employee) ? () => pauseUser() : undefined}
                    onClose={() => setShowDeleteMyAccountModal(false)}
                    title={"deleteMyAccount"}
                    primaryButton={t("delete")}
                    secondaryButton={t("cancel")}
                    thirdButton={checkRole(claims, UserRoles.Employee) ? t("pause") : undefined}
                />
            }
            {
                showPasswordResetModal &&
                <ModalContent
                onClose={() => setShowPasswordResetModal(false)}
                    size="lg"
                    show={showPasswordResetModal}
                    children={<PasswordChangeContent handleCloseModal={() => setShowPasswordResetModal(false)} />}
                    title={t("resetPassword")}
                />
            }
            {
                showNotificationModal &&
                <ModalContent
                    size="sm"
                    show={showNotificationModal}
                    onClose={() => setShowNotificationModal(false)}
                    children={
                        <NotificationContent
                            setNotification={(enabled) => setNotificationEnabled(enabled)}
                            toggleNotificationsAPI={toggleNotificationsAPI}
                            isNotificationEnabled={notificationEnabled}
                        />
                    }
                    title={t("notifications")}
                />
            }

            <div className="container-fluid settings">
                <Tab.Container defaultActiveKey="profile">
                    <Row className="p-5">
                        <Col md={3}>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="profile">{t("userProfile")}</Nav.Link>
                                </Nav.Item>
                                {checkRoles(claims, [UserRoles.Employee]) &&
                                    <>
                                        <Nav.Item>
                                            <Nav.Link eventKey="dreamJob">{t("myDreamJob")}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="cv">{t("myCv")}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => { userId !== "" ? navigate("/cv/" + userId) : navigate("/settings") }}>{t("previewProfile")}</Nav.Link>
                                        </Nav.Item>
                                    </>
                                }
                                {checkRoles(claims, [UserRoles.Recrewter]) &&
                                    <>
                                        <Nav.Item>
                                            <Nav.Link eventKey="company">{t("myCompany")}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => { userId !== "" ? navigate("/cv/" + userId) : navigate("/settings") }}>{t("previewProfile")}</Nav.Link>
                                        </Nav.Item>
                                    </>
                                }
                                {checkRoles(claims, [UserRoles.Admin]) &&
                                    <>
                                        {/* TODO */}
                                        {/* <Nav.Item>
                                            <Nav.Link eventKey="admin">{t("administrators")}</Nav.Link>
                                        </Nav.Item> */}
                                        <Nav.Item>
                                            <Nav.Link eventKey="admin-recrewters">{t("companyAndRecrewter")}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="admin-employees">{t("employeeTitle")}</Nav.Link>
                                        </Nav.Item>
                                        {/* Right now, statistics link directs to StatisticsManagement component, it will be organized later on */}
                                        <Nav.Item>
                                            <Nav.Link eventKey="admin-statistics">{t("statistics")}</Nav.Link>
                                        </Nav.Item>
                                    </>
                                }
                                <Nav.Item>
                                    <Nav.Link onClick={() => setShowNotificationModal(true)}>{t("notifications")}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => setShowPasswordResetModal(true)}>{t("changePassword")}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => setShowDeleteMyAccountModal(true)}>{t("deleteMyAccount")}</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col md={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="profile">
                                    <UserDetails isProfileVisible={(visible) => setIsVisible(visible)} isProfileCompleted={(show) => setProfileCompleted(show)} />
                                </Tab.Pane>

                                {/* Employee only */}
                                <Tab.Pane eventKey="cv">
                                    <Authorized role={UserRoles.Employee} authorized={<AboutMe />} />
                                    <Authorized role={UserRoles.Employee} authorized={<LanguageSkills />} />
                                    <Authorized role={UserRoles.Employee} authorized={<Skills />} />
                                    <Authorized role={UserRoles.Employee} authorized={<WorkExperience />} />
                                    <Authorized role={UserRoles.Employee} authorized={<Educations />} />
                                    <Authorized role={UserRoles.Employee} authorized={<Certificate />} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="dreamJob">
                                    <Authorized role={UserRoles.Employee} authorized={<DreamJob />} />
                                </Tab.Pane>

                                {/* Recrewter only */}
                                <Tab.Pane eventKey="company">
                                    <Authorized role={UserRoles.Recrewter} authorized={
                                        <CompanyDetails isCompanyProfileCompleted={(show) => setCompanyProfileCompleted(show)} />
                                    } />
                                </Tab.Pane>

                                {/* Admin only */}
                                {/* TODO */}
                                {/* <Tab.Pane eventKey="admin">
                                    <Authorized role={UserRoles.Admin} authorized={<AdminManagement />} />
                                </Tab.Pane> */}
                                <Tab.Pane eventKey="admin-recrewters">
                                    <Authorized role={UserRoles.Admin} authorized={<RecrewterManagement />} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="admin-employees">
                                    <Authorized role={UserRoles.Admin} authorized={<EmployeeManagement />} />
                                </Tab.Pane>
                                    {/* Statistics Tab.Pane */}
                                <Tab.Pane eventKey="admin-statistics">
                                <Authorized role={UserRoles.Admin} authorized={<StatisticsManagement />} />
                                <ComingSoon title={""}/>
                                </Tab.Pane>
                                {/* TODO */}
                                {/* <Tab.Pane eventKey="account">
                                </Tab.Pane> */}
                                
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </>
    )
}