import React from "react";
import ContentPrivacyPolicy from "./ContentPrivacyPolicy";
import LandingPageHeader from "../landingpage/components/LandingPageHeader";

export default function PrivacyPolicy() {
    return (
        <>
            <div className="appContainer mb-5">
                <LandingPageHeader />
                <ContentPrivacyPolicy />
            </div>
        </>
    )
}