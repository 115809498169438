import React, { useContext } from "react";
import AuthenticationContext from "../../auth/AuthenticationContext";
import { checkLoggedInStatus } from "../../core/claimFunctions";
import Mainmenu from "./Mainmenu";
import PlattformMenu from "./PlattformMenu";

export default function Menu() {

    const { claims } = useContext(AuthenticationContext);

    return (
        <>
            <Mainmenu />
            {/* {checkLoggedInStatus(claims) && <PlattformMenu />} */}
            <PlattformMenu />
        </>
    )
}