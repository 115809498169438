import React from "react";
import { useTranslation } from "react-i18next";
import Mainmenu from "../../components/Menu/Mainmenu";
import Message from "./components/Message";

export default function EmailConfirmed() {
    const { t } = useTranslation();

return (
    <>
        <Mainmenu />
        <Message message={t("emailConfirmed")} redirectText={t("login")} redirectUrl={"/auth"} />
    </>
)
}