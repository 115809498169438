import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { FileType } from "../config/Types/GeneralEnumDefinitions";
import { downloadFileAPI, submitFileAPI } from "../core/apiFunctions";
import { downloadFile } from "../core/helperFunctions";

export default function FileUploadField(props: fileUploadFieldProps) {
    const [filePath, setFilePath] = React.useState<string>('');
    const [fileName, setFileName] = React.useState<string>('');

    useEffect(() => {
        setFilePath(props.filePath);
        setFileName(props.fileName);
    }, [props.filePath, props.fileName]);

    useEffect(() => {
        if (props.uploadTigger) {
            manualUpload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.uploadTigger]);

    const manualUpload = () => {
        const response = fetch(filePath);
        const blobPromise = response.then(r => r.blob());
        blobPromise.then(blob => {
            const file = new File([blob], fileName);
            const event = { target: { files: [file] } };
            upload(event as any);
        });
    }

    const upload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const dto = {
                file: event.target.files[0],
                type: props.type
            };

            submitFileAPI(dto)
                .then((response) => {
                    const event = { targets: [{ value: dto.file.name, name: props.field }, { value: response.data, name: "filePath" }] };
                    props.onChange(event);

                    if (props.onUploadDone) {
                        props.onUploadDone(response.data, dto.file.name);
                    }

                    setFilePath(response.data);
                    setFileName(dto.file.name);
                })
                .catch((error) => {
                    if (props.onError) {
                        if (error.response.data[0].includes("body too large")) {
                            props.onError("error.fileTooLarge");
                        } else {
                            props.onError(error.response.data);
                        }
                    }
                });
        }
    }

    const remove = () => {
        const event = { targets: [{ value: '', name: props.field }, { value: '', name: "filePath" }] };
        props.onChange(event);
        if (props.onRemove) {
            props.onRemove();
        }

        setFilePath('');
        setFileName('');
    };

    const download = () => {
        if (props.onDownload) {
            props.onDownload();
        } else {
            downloadFileAPI(filePath).then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);
                downloadFile(href, fileName);
            })
        }
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.directUpload) {
            upload(event);
        } else {
            const file = event.target.files?.[0];
            if (file) {
                const url = URL.createObjectURL(file);
                setFileName(file.name);
                setFilePath(url);

                if (props.onNewSource) {
                    props.onNewSource(url, file.name);
                }
            }
        }
    }

    return (
        <>
            {fileName ?
                <div className="file-upload-with-value">
                    <label className="file-name">{fileName}</label>

                    <button className="btn btn-sm" onClick={remove}><i className="fas fa-trash-alt"></i>
                        <FontAwesomeIcon icon={faTrash} />
                    </button>

                    <button className="btn btn-sm" onClick={download}><i className="fas fa-download"></i>
                        <FontAwesomeIcon icon={faDownload} />
                    </button>
                </div>
                :
                <div className="file-upload-field mb-3">
                    <input
                        className="form-control"
                        type="file"
                        id={props.field}
                        name={props.field}
                        onChange={onChange}
                        accept={props.accept}
                    />
                </div>
            }
            {props.validationMessage && <label className="text-danger">{props.validationMessage}</label>}
        </>
    )
}

interface fileUploadFieldProps {
    // defines which field is updated by this component e.g. "imagePath", "videoPath", "filePath"
    field: string;

    fileName: string;
    filePath: string;

    // defines which type of file is uploaded
    type: FileType;

    // TODO: implement
    displayName?: string;

    onChange(event: any): void;
    onRemove?(): void;
    onNewSource?(source: string, fileName: string): void;
    onDownload?(): void;
    onUploadDone?(filePath: string, fileName: string): void;
    onError?(error: any): void;

    validationMessage?: string;

    // defines which file types can be uploaded
    accept: string;

    // if true, file will be uploaded directly after selection
    directUpload: boolean;

    // is going to be set by parent component to trigger upload
    uploadTigger?: boolean;
}

// set default for accept
FileUploadField.defaultProps = {
    accept: "*",
    directUpload: true,
}