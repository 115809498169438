import React, { useContext, useEffect, useRef, useState } from "react";
import Menu from "../components/Menu/Menu";
import * as signalR from "@microsoft/signalr";
import { urlChatSignalR } from "../config/endpoints";
import { chatDTO, messageDTO } from "../core/dto/dto.models";
import "./Chat.scss";
import { getClaims, getToken } from "../core/handleJWT";
import {
    getChatsAPI, getCompanyNameForRecrewterAPI, getNewChatAPI, getProfilePhotoAPI, getUserInformationAPI, markChatAsReadAPI,
    removeLastMessageAPI, requestNewUserConnectionAPI, sendMessageAPI, respondUserConnectionAPI
} from "../core/apiFunctions";
import { getName, getNameIdentifier } from "../core/claimFunctions";
import { useSetState } from "../utils/UseSetState";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import AuthenticationContext from "../auth/AuthenticationContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ConnectionStatus } from "../config/Types/GeneralEnumDefinitions";
import WebSocketContext from "../contexts/WebSocketContext";

export default function Chat() {

    // translation
    const { t } = useTranslation();

    // navigation
    let navigate = useNavigate();

    const { claims } = useContext(AuthenticationContext);

    // params for new chat or/and prefill message
    const { participantId, contentForMessage } = useParams<{ participantId: string, contentForMessage: string }>();
    // list of open chats
    const [chats, setChats, getChat] = useSetState<chatDTO[]>([]);
    // selected chat
    const [selectedChat, setSelectedChat] = useState<chatDTO | null>(null);
    // message input for new message
    const [messageField, setMessageField] = useState("");
    // get own nameidentifier
    const [myId] = useState<string | null>(getNameIdentifier(getClaims()));
    // ref to chat window for scrolling to bottom
    const chatContainerRef = useRef<HTMLDivElement>(null);
    // get own profilepricture from localstorage
    const [myProfilePicture] = useState<string | null>(localStorage.getItem("profile-picture"));

    const {
        connection,
        messageReceivedSignalR,
        messageReadSignalR,
        removeLastMessageSignalR,
        setMessageReceivedSignalR,
        setMessageReadSignalR,
        setRemoveLastMessageSignalR,
    } = useContext(WebSocketContext)!;

    function correctMessage(messages: string) {

        // Replace %20 with space
        var messageCorrected = messages.replace(new RegExp("%20", "g"), " ");
        // Replace %22 with "
        messageCorrected = messageCorrected.replace(new RegExp("%22", "g"), "\"");
        // Replace %28' with (
        messageCorrected = messageCorrected.replace(new RegExp("%28", "g"), "(");
        // Replace %29' with )
        messageCorrected = messageCorrected.replace(new RegExp("%29", "g"), ")");
        // Replace %2C with ,
        messageCorrected = messageCorrected.replace(new RegExp("%2C", "g"), ",");
        // Replace %3A with :
        messageCorrected = messageCorrected.replace(new RegExp("%3A", "g"), ":");
        // Replace %3B with ;
        messageCorrected = messageCorrected.replace(new RegExp("%3B", "g"), ";");
        // Replce %2D with -
        messageCorrected = messageCorrected.replace(new RegExp("%2D", "g"), "-");
        // Replace %2F with /
        messageCorrected = messageCorrected.replace(new RegExp("%2F", "g"), "/");
        // Replace %3F with ?
        messageCorrected = messageCorrected.replace(new RegExp("%3F", "g"), "?");
        // Replace %5B with [
        messageCorrected = messageCorrected.replace(new RegExp("%5B", "g"), "[");
        // Replace %5D with ]
        messageCorrected = messageCorrected.replace(new RegExp("%5D", "g"), "]");
        // Replace %5C with \
        messageCorrected = messageCorrected.replace(new RegExp("%5C", "g"), "\\");
        // Replace %7B with {
        messageCorrected = messageCorrected.replace(new RegExp("%7B", "g"), "{");
        // Replace %7D with }
        messageCorrected = messageCorrected.replace(new RegExp("%7D", "g"), "}");
        // Replace %7C with |
        messageCorrected = messageCorrected.replace(new RegExp("%7C", "g"), "|");
        // Replace %7E with ~
        messageCorrected = messageCorrected.replace(new RegExp("%7E", "g"), "~");
        // Replace %60 with `
        messageCorrected = messageCorrected.replace(new RegExp("%60", "g"), "`");
        // Replace %21 with !
        messageCorrected = messageCorrected.replace(new RegExp("%21", "g"), "!");

        // Replace %C3%84 with ä
        messageCorrected = messageCorrected.replace(new RegExp("%C3%A4", "g"), "ä");
        // Replace %C3%BC with ü
        messageCorrected = messageCorrected.replace(new RegExp("%C3%BC", "g"), "ü");
        // Replace %C3%96 with ö
        messageCorrected = messageCorrected.replace(new RegExp("%C3%B6", "g"), "ö");
        // Replace %C3%84 with Ä
        messageCorrected = messageCorrected.replace(new RegExp("%C3%84", "g"), "Ä");
        // Replace %C3%9C with Ü
        messageCorrected = messageCorrected.replace(new RegExp("%C3%9C", "g"), "Ü");
        // Replace %C3%96 with Ö
        messageCorrected = messageCorrected.replace(new RegExp("%C3%96", "g"), "Ö");
        // Replace %C3%9F with ß
        messageCorrected = messageCorrected.replace(new RegExp("%C3%9F", "g"), "ß");

        return messageCorrected;
    }

    useEffect(() => {
        // check if new chat is requested and get all existing chats
        if (participantId) {
            getNewChatAPI(participantId).then(() => {
                getChatsAPI().then((response) => {
                    setChats(response.data);
                    const chat = response.data.find((c) => c.participants.find((p) => p.id === participantId));
                    // set preselected chat if set
                    if (chat) {
                        setSelectedChat(chat);
                        // set predefined message if set
                        if (contentForMessage) {
                            setMessageField(correctMessage(contentForMessage));
                        }
                    }
                });
            });
        } else {
            getChatsAPI().then((response) => {
                setChats(response.data);
                // If no chat is selected and there are chats available, set the first chat as selected
                if (!selectedChat && response.data.length > 0 ) {
                        setSelectedChat(response.data[0]);
                }
            });
        }
    }, []);

    // handle signla R when my message has been read by participant
    useEffect(() => {
        if (messageReadSignalR) {
            markMyMessageAsRead(messageReadSignalR);
            // set myMessageHasBeenReadInChat as undefined
            setMessageReadSignalR('');
        }
    }, [messageReadSignalR])

    // handle signalR new message incoming
    useEffect(() => {
        if (messageReceivedSignalR != null) {
            receiveMessage(messageReceivedSignalR)
        }

    }, [messageReceivedSignalR])

    // handle signalR message to remove last message
    useEffect(() => {
        if (removeLastMessageSignalR) {
            undoParticipantsLastMessage(removeLastMessageSignalR);
            setRemoveLastMessageSignalR('');
        }
    }, [removeLastMessageSignalR])

    useEffect(() => {
        // scroll to bottom of chat window
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo(0, chatContainerRef.current.scrollHeight);
        }

        markParticipantMessageAsRead();
    }, [chats, selectedChat]);

    const receiveMessage = async (message: messageDTO) => {
        // add message to chat
        const chats = await getChat();
        const chat = chats.find((c) => c.id === message.chatId);
        if (chat) {
            message.date = new Date(message.date);
            chat.messages.push(message);
            setChats([...chats]);
        }
    }

    const markMyMessageAsRead = async (chatId: string) => {
        // get chat and mark all messages my messages as read
        const chat = chats.find((c) => c.id === chatId);
        if (chat) {
            chat.messages.forEach((m) => {
                if (m.senderId === myId) {
                    m.isRead = true;
                }
            });
            setChats([...chats]);
        }
    }

    const markParticipantMessageAsRead = () => {
        // mark chat as read if any from other participant message is unread
        if (selectedChat && selectedChat.messages.filter((m) => m.senderId !== myId && !m.isRead).length > 0) {
            // mark chat as read in database
            markChatAsReadAPI(selectedChat.id);

            // send read message to other participant
            if (connection) {
                const participantId = selectedChat.participants.find((p) => p.id !== myId)?.id;
                connection.invoke("MarkChatAsRead", participantId, selectedChat.id);
            }

            selectedChat.messages.forEach((m) => {
                if (m.senderId !== myId) {
                    m.isRead = true;
                }
            });
            setChats([...chats]);
        }
    }

    const send = () => {
        const messageDTO: messageDTO = {
            chatId: selectedChat!.id,
            senderId: myId!,
            content: messageField,
            isRead: false,
            date: new Date()
        };

        // send message to server to save in database
        sendMessageAPI(messageDTO, selectedChat!.id).then((response) => {
            // send message to other participant
            if (connection) {
                const participantId = selectedChat!.participants.find((p) => p.id !== myId)?.id;
                connection.invoke("SendMessage", participantId, messageDTO);
            }

            // add message to chat
            const chat = chats.find((c) => c.id === selectedChat!.id);
            if (chat) {
                chat.messages.push(messageDTO);
                setChats([...chats]);
            }

            // clear message field
            setMessageField("");
        });
    }

    const undoParticipantsLastMessage = (chatId: string) => {
        const chat = chats.find((c) => c.id === chatId);
        if (chat) {
            chat.messages.pop();
            setChats([...chats]);
        }
    }

    const undoMyLastMessage = () => {
        if (selectedChat) {
            removeLastMessageAPI(selectedChat.id).then(() => {
                selectedChat.messages.pop();
                setChats([...chats]);

                // notify other participant that message has been removed
                if (connection) {
                    const participantId = selectedChat!.participants.find((p) => p.id !== myId)?.id;
                    connection.invoke("RemoveLastMessage", participantId, selectedChat.id);
                }
            });
        }
    }

    const profileNameWrapper = (participantId: string) => {
        // if participant is me return my name
        if (participantId === myId) {
            return getName(claims);
        }

        // get participant from id in all chats
        const chat = chats.find((c) => c.participants.find((p) => p.id === participantId));
        const participant = chat?.participants.find((p) => p.id === participantId);

        // if participant is found return name
        if (participant) {
            // check if participant is Recrewtrer
            if (participant.isRecrewter && participant.companyName === undefined) {
                // Check if the name fetch is already in progress
            if (!participant.fetchingName) {
                // Mark that the fetch is in progress
                participant.fetchingName = true;
                getCompanyNameForRecrewterAPI(participantId).then((response) => {
                    participant.companyName = response.data;
                    setChats([...chats]);
                    participant.fetchingName = false;
                });
            }
            }

            // check if participant name is already loaded
            if (participant.name) {
                if (participant.isRecrewter) {
                    return participant.name + " (" + participant.companyName + ")";
                }
                else {
                    return participant.name;
                }
            }
            // get name from server
            if (!participant.fetchingName) {
            // Mark that the fetch is in progress
            participant.fetchingName = true;
            getUserInformationAPI(participantId).then((response) => {
                participant.name = response.data.name;
                // Update the UI
                setChats([...chats]);
                participant.fetchingName = false;
            });
        }
    }
}

    const profilePhotoWrapper = (participantId: string) => {
        // if participant is me return my profile picture
        if (participantId === myId) {
            return myProfilePicture;
        }

        // get participant from id in all chats
        const chatsCopy = [...chats];
        const participant = chatsCopy.find((c) => c.participants.find((p) => p.id === participantId))?.participants.find((p) => p.id === participantId);

        // if participant is found return profile picture
        if (participant) {
            // check if participant photo is already loaded
            if (participant.photoUrl) {
                return participant.photoUrl;
            }  
            // Check if the photo fetch is already in progress
            if (!participant.fetchingPhoto) {
                // Mark that the fetch is in progress
                participant.fetchingPhoto = true;
    
                // Fetch profile picture from server
            getProfilePhotoAPI(participantId).then((response) => {
                const url = URL.createObjectURL(response.data);
                participant.photoUrl = url;
                participant.fetchingPhoto = false; 
                setChats([...chatsCopy]);
                return url;
            });      
            }
        }
    }

    const incomingChats = () => {
        // chats which does not contain any message from me and contains at least one message from other participant
        return chats.filter((c) => c.messages.filter((m) => m.senderId === myId).length === 0 && c.messages.filter((m) => m.senderId !== myId).length > 0);
    }

    // get all chats where i have been answered
    const openChats = () => {
        // all chats minus new chats minus incoming chats
        return chats.filter((c) => c.messages.length > 0 && !newChats().find((nc) => nc.id === c.id) && !incomingChats().find((ic) => ic.id === c.id));
    }

    const newChats = () => {
        // chats where there have no messages at all
        return chats.filter((c) => c.messages.length === 0);
    }

    const countUnreadMessages = (chat: chatDTO) => {
        // count unread messages in chat
        return chat.messages.filter((m) => m.senderId !== myId && !m.isRead).length;
    }

    return (
        <>
            <Menu />
            <div className="p-5">
                {/* dipslay only when there are new participandId in url or if there is already chat with containing messages */}
                {((openChats && openChats().length > 0) || (incomingChats && incomingChats().length > 0)) || (participantId) ?
                    <div className="col-12">
                        <div className="panel-body chat">
                            <div className="row chat-wrapper">
                                <div className="col-md-4" style={{ marginTop: '3.25rem' }}>
                                    <div className="chat-list">
                                        {incomingChats().length > 0 &&
                                            <div className="row">
                                                <div className="col-12 text-center" style={{ marginTop: '1rem' }}>
                                                    <h3>{t('incomingChats')}</h3>
                                                </div>
                                            </div>
                                        }
                                        <div className="row">
                                            {incomingChats().map((chat, index) => (
                                                <div className={`chat-item ${selectedChat?.id === chat.id ? 'selected' : ''}`} key={index} onClick={() => setSelectedChat(chat)}>
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <img className="profile-image" src={profilePhotoWrapper(chat.participants.find(p => p.id !== myId)!.id)!} alt="profile" />
                                                        </div>
                                                        <div className="col-5">
                                                            <p className="profile-name">{profileNameWrapper(chat.participants.find(p => p.id !== myId)!.id)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="chat-list">
                                        {openChats().length > 0 &&
                                            <div className="row">
                                                <div className="col-12 mt-3 text-center">
                                                    <h3>{t('openChats')}</h3>
                                                </div>
                                            </div>
                                        }
                                        <div className="row">
                                            {openChats().map((chat, index) => (
                                                <div className={`chat-item ${selectedChat?.id === chat.id ? 'selected' : ''}`} key={index} onClick={() => setSelectedChat(chat)}>
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <img className="profile-image" src={profilePhotoWrapper(chat.participants.find(p => p.id !== myId)!.id)!} alt="profile" />
                                                        </div>
                                                        <div className="col-7">
                                                            <p className="profile-name">{profileNameWrapper(chat.participants.find(p => p.id !== myId)!.id)}</p>
                                                        </div>
                                                        {countUnreadMessages(chat) > 0 &&
                                                            <div className="col-2">
                                                                <p className="unread-count">{countUnreadMessages(chat)}</p>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">

                                    <div className="row">
                                        <div className="col-12 mt-3 text-center">
                                            {/* if chat selected show this otherwise tell no chat selected */}
                                            {selectedChat ?
                                                <>
                                                    <h3>{t("chat.with")} {profileNameWrapper(selectedChat.participants[0].id)}</h3>

                                                    {/* Button to accept incoming request */}
                                                    {selectedChat.participants[0].connectionStatus === ConnectionStatus.None && !selectedChat.participants[0].isRecrewter &&
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            delay={{ show: 0, hide: 200 }}
                                                            overlay={<Tooltip id="button-tooltip"><>{t("descriptionRequestConnection")}</></Tooltip>}>
                                                            <button className="btn btn-dark mx-2"
                                                                onClick={async () => {
                                                                    await requestNewUserConnectionAPI(selectedChat.participants[0].id);
                                                                    selectedChat.participants[0].connectionStatus = ConnectionStatus.Requested;
                                                                    setChats([...chats]);
                                                                }}>
                                                                {t("requestConnection")}
                                                            </button>
                                                        </OverlayTrigger>
                                                    }

                                                    {/* Button to undo outgoing request */}
                                                    {selectedChat.participants[0].connectionStatus === ConnectionStatus.Requested && !selectedChat.participants[0].isRecrewter &&
                                                        <button className="btn btn-secondary mx-2"
                                                            onClick={async () => {
                                                                await respondUserConnectionAPI(selectedChat.participants[0].id, ConnectionStatus.Declined);
                                                                selectedChat.participants[0].connectionStatus = ConnectionStatus.None;
                                                                setChats([...chats]);
                                                            }}>
                                                            {t("undoRequestConnection")}
                                                        </button>
                                                    }

                                                    {/* Button to view company profile */}
                                                    <OverlayTrigger
                                                        delay={{ show: 0, hide: 200 }}
                                                        overlay={<Tooltip id="button-tooltip"><>{selectedChat.participants[0].isRecrewter ? t("descriptionCompanyProfile") : t("descriptionUserProfile")}</></Tooltip>}>
                                                        <button className="btn btn-primary mx-2"
                                                            onClick={() => {
                                                                navigate("/cv/" +  selectedChat.participants[0].id)
                                                            }}>
                                                            {selectedChat.participants[0].isRecrewter ? t("companyProfile") : t("userProfile")}
                                                        </button>
                                                    </OverlayTrigger>

                                                    {selectedChat.participants[0].isRecrewter && selectedChat.participants[0].companyName !== "Administrator"
                                                        && selectedChat.participants[0].connectionStatus !== ConnectionStatus.None &&
                                                        <>
                                                            {/* Button to accept incoming request from recrewter */}
                                                            <OverlayTrigger
                                                                placement="left"
                                                                delay={{ show: 0, hide: 200 }}
                                                                overlay={<Tooltip id="button-tooltip"><>{t("descriptionAcceptConnection")}</></Tooltip>}>
                                                                <button className="btn btn-success mx-2"
                                                                    disabled={selectedChat.participants[0].connectionStatus === ConnectionStatus.Accepted}
                                                                    onClick={async () => {
                                                                        await respondUserConnectionAPI(selectedChat.participants[0].id, ConnectionStatus.Accepted);
                                                                        selectedChat.participants[0].connectionStatus = ConnectionStatus.Accepted;
                                                                        setChats([...chats]);
                                                                    }}>
                                                                    {/* when connection accepted display text in button otherwise show request connection */}
                                                                    {selectedChat.participants[0].connectionStatus === ConnectionStatus.Accepted ? t("connectionAccepted") : t("acceptConnection")}
                                                                </button>
                                                            </OverlayTrigger>

                                                            {/* Button to decline incoming request from recrewter */}
                                                            <OverlayTrigger
                                                                placement="right"
                                                                delay={{ show: 0, hide: 200 }}
                                                                overlay={<Tooltip id="button-tooltip"><>{t("descriptionDeclineConnection")}</></Tooltip>}>
                                                                <button className="btn btn-danger mx-2"
                                                                    disabled={selectedChat.participants[0].connectionStatus === ConnectionStatus.Declined}
                                                                    onClick={async () => {
                                                                        await respondUserConnectionAPI(selectedChat.participants[0].id, ConnectionStatus.Declined);
                                                                        selectedChat.participants[0].connectionStatus = ConnectionStatus.Declined;
                                                                        setChats([...chats]);
                                                                    }}>
                                                                    {/* when connection declined display text in button otherwise show decline connection */}
                                                                    {selectedChat.participants[0].connectionStatus === ConnectionStatus.Declined ? t("connectionDeclined") : t("declineConnection")}
                                                                </button>
                                                            </OverlayTrigger>
                                                        </>
                                                    }
                                                </>
                                                :
                                                <h3>{t("chat.notSelected")}</h3>
                                            }
                                        </div>
                                    </div>
                                    <div className={`chat-window ${selectedChat ? 'selected' : ''}`} ref={chatContainerRef}>
                                        <div className="messages">
                                            {/* map messages of selected chat sorted by datetime */}
                                            {selectedChat?.messages.sort((a, b) => a.date.getTime() - b.date.getTime()).map((message, index) => (
                                                <div className={`message ${message.senderId === myId ? 'right' : 'left'}`} key={index}>
                                                    <div className="row m-0">
                                                        {/* if sender is opponent */}
                                                        {message.senderId !== myId &&
                                                            <div className="col-10">
                                                                <img src={profilePhotoWrapper(message.senderId)!}
                                                                    alt="profile" className="profile-image" />
                                                            </div>
                                                        }

                                                        <div className="col-90">
                                                            <p className="text">{message.content}</p>
                                                            <p className="date">{message.date.toLocaleString()}</p>
                                                        </div>


                                                        {/* if sender is me and (not last message but or read) */}
                                                        {message.senderId === myId && (index !== selectedChat.messages.length - 1 || message.isRead) &&
                                                            <div className="col-10">
                                                                <img src={myProfilePicture!} alt="profile" className="profile-image" />
                                                            </div>
                                                        }

                                                        {/* if sender is me, last message and not read */}
                                                        {message.senderId === myId && index === selectedChat.messages.length - 1 && !message.isRead &&
                                                            <div className="col-10">
                                                                <button type="button" className="btn px-0" onClick={() => undoMyLastMessage()}>
                                                                    <FontAwesomeIcon icon={faUndo} />
                                                                </button>
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="input-prompt">
                                        <div className="row">
                                            <div className="col-10">
                                                <textarea className="form-control" placeholder={t("chat.placeholderTypeMessage")}
                                                    value={messageField} disabled={!selectedChat} onChange={(e) => setMessageField(e.target.value)}></textarea>
                                            </div>
                                            <div className="col-2 p-0">
                                                <button className="btn btn-primary w-100 h-100" disabled={messageField.length === 0 || !selectedChat}
                                                    onClick={() => send()}>{t("btnSend")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="col-12">
                        <div className="panel-body chat">
                            <div className="row chat-wrapper">
                                <div className="col-12 text-center">
                                    <h3>{t("chat.noMessages")}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </>
    );
}