import React, { useContext, useEffect, useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AuthenticationContext from "../../auth/AuthenticationContext";
import { getRoleId } from "../../core/claimFunctions";
import { EducationLevel, FeedbackMode, UserRoles, Workload } from "../../config/Types/GeneralEnumDefinitions";
import { toggleJobOfferAPI } from "../../core/apiFunctions";
import { useNavigate } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { MedicalDivision } from "../../config/Types/MedicalEnumDefinitions";
import { BsChatDots } from "react-icons/bs";
import { getCompanyJobOffersAPI, deleteJobOfferAPI } from "../../core/apiFunctions";
import ModalContent from "../../utils/ModalContent";
import useFeedback from '../../utils/useFeedback';
import { extendedJobOfferDTO, jobApplicationDTO, jobOfferDTO } from "../../core/dto/dto.models";

export default function JobAccordion(props: AccordionProps) {
    const { t } = useTranslation();
    const { claims } = useContext(AuthenticationContext);
    const role = getRoleId(claims);
    const navigate = useNavigate();
    const { showSuccess, showError } = useFeedback();

    const [jobOffer, setJobOffer] = useState<extendedJobOfferDTO[]>([]);
    const [companyApplication, setCompanyApplication] = useState<jobApplicationDTO[]>([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [jobToDelete, setJobToDelete] = useState<jobApplicationDTO | null>(null);

    const handleDelete = async (url: string) => {
        try {
            await deleteJobOfferAPI(url);
            const updatedJobOffers = companyApplication.filter((offer) => offer.url !== url);
            setCompanyApplication(updatedJobOffers);
            setShowDeleteModal(false);
            setJobToDelete(null);
            showSuccess(t("jobOfferDeletedSuccessfully"), FeedbackMode.Success, 5000);
        } catch (error: any) {
            if (error.response) {
                if (error.response.status === 401 || error.response.status === 404) {
                    showError(t(error.response.data), FeedbackMode.Error, 5000);
                } else {
                    showError(t("errorOccuredWhileDeleting"));
                }
            } else {
                showError(t("errorOccuredWhileDeleting"));
            }
        }
    };

    function isJobOfferDTO(object: any): object is jobOfferDTO {
        return Array.isArray(object) && "youTubeUrl" in object[0];
    }

    function getDefaultActiveKeys(): string[] {
        var items: any[] = [];
        if (isJobOfferDTO(props.data)) {
            items = props.data as jobOfferDTO[];
            return items.map((item) => (item as jobOfferDTO).id ?? "");
        } else {
            items = props.data as jobApplicationDTO[];
            return items.map((item) => (item as jobApplicationDTO).url ?? "");
        }
    }

    useEffect(() => {
        if (isJobOfferDTO(props.data)) {
            setJobOffer(props.data as extendedJobOfferDTO[]);
        } else {
            setCompanyApplication(props.data as jobApplicationDTO[]);
        }
    }, [props.data]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await getCompanyJobOffersAPI(1, 1000, 'jobname', 'ASC');
                setCompanyApplication(data);
            } catch (error) {
                return null;
            }
        };
        fetchData();
    }, [props.data]);

    return (
        <>
            <Accordion
                className="jobAccordion"
                alwaysOpen={props.alwaysOpen}
                defaultActiveKey={getDefaultActiveKeys()}
            >
                {role === UserRoles.Employee && (
                    <>
                        <h2 style={{ marginBottom: '20px' }}>Digital reCREWter {t("jobOffers")}</h2>
                        {props.data.map((values) => (
                            <Card
                                key={(values as jobOfferDTO).id}
                                className="mb-4 shadow bg-secondary rounded-3"
                            >
                                <Accordion.Item eventKey={(values as jobOfferDTO).id ?? ""}>
                                    <Accordion.Header>
                                        <>{values.title}</>
                                    </Accordion.Header>
                                    <Accordion.Body style={{ padding: "0" }}>
                                        <div className="companiesAndUserDetailsList userList">
                                            <div className="row" style={{ width: '100%' }}>
                                                <div className="d-flex flex-column align-items-center col-lg-2 col-md-4 col-sm-6 col-6">
                                                    <h6>{t("headerDivision")}</h6>
                                                    <p>
                                                        {t(
                                                            MedicalDivision[(values as jobOfferDTO).division]
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="d-flex flex-column align-items-center col-lg-2 col-md-4 col-sm-6 col-6">
                                                    <h6>{t("headerEducationLevel")}</h6>
                                                    <p>{t(EducationLevel[(values as jobOfferDTO).educationLevel ?? 0])}</p>
                                                </div>
                                                {/* <div className="d-flex flex-column align-items-center col-lg-2 col-md-4 col-sm-6 col-6">
                                                <h6>{t("headerJobType")}</h6>
                                                <p>{t(JobType[(values as jobOfferDTO).jobType])}</p>
                                                </div> */}
                                                <div className="d-flex flex-column align-items-center col-lg-2 col-md-4 col-sm-6 col-6">
                                                    <h6>{t("labelPensum")}</h6>
                                                    <p>{t(Workload[(values as jobOfferDTO).workload ?? 0])}</p>
                                                </div>
                                                <div className="d-flex flex-column align-items-center col-lg-2 col-md-4 col-sm-6 col-6">
                                                    <h6>{t("urlDetails")}</h6>
                                                    <Button
                                                        variant="outline-primary"
                                                        size="sm"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            navigate("/jobs/" + (values as jobOfferDTO).id);
                                                        }}
                                                    >
                                                        <FiEye />
                                                        <span> {t("preview")}</span>
                                                    </Button>
                                                </div>
                                                <div className="d-flex flex-column align-items-center col-lg-2 col-md-4 col-sm-6 col-6">
                                                    <h6>{t("titleApply")}</h6>
                                                    <Button
                                                        variant="outline-primary"
                                                        size="sm"
                                                        onClick={() =>
                                                            navigate(
                                                              "/chat/" +
                                                                (values as extendedJobOfferDTO).userId +
                                                                "/" +
                                                                t("message.hello.general") +
                                                                t("message.intentionApply") +
                                                                '"' +
                                                                values.title +
                                                                '" ' +
                                                                t("message.at") +
                                                                " " +
                                                                (values as extendedJobOfferDTO).companyName +
                                                                "."
                                                            )
                                                        }
                                                    >
                                                        <BsChatDots />
                                                        <span> {t("recrewter")}</span>
                                                    </Button>
                                                </div>
                                                <div className="d-flex flex-column align-items-center col-lg-2 col-md-4 col-sm-6 col-6">
                                                    <h6>{t("created")}</h6>
                                                    <p>{new Date(values.createdDate!).toLocaleDateString()}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Card>
                        ))}
                    </>
                )}
                {role === UserRoles.Recrewter && (
                    <>
                        <h2 style={{ marginTop: '20px', marginBottom: '20px' }}>{t("myListedJobOffers")}</h2>
                        {companyApplication.map((values) => (
                            <Card key={(values as jobApplicationDTO).url} className="mb-4 shadow bg-secondary roun">
                                <Accordion.Item eventKey={(values as jobApplicationDTO).url ?? ""}>
                                    <Accordion.Header>
                                        <>{values.title}</>
                                    </Accordion.Header>
                                    <Accordion.Body style={{ padding: "0" }}>
                                        <div className="container">
                                            <div className="companiesAndUserDetailsList align-items-start">
                                                <div className="d-flex flex-column align-items-center justify-content-center">
                                                    <h6 style={{ marginBottom: '5px' }}>
                                                        {values.isPublished ? t("published") : t("hidden")}
                                                    </h6>
                                                    <div className="form-check form-switch">
                                                        <input
                                                            key={`flexSwitchCheckDefault-${values.url}`}
                                                            className="form-check-input mb-1"
                                                            type="checkbox"
                                                            id={`flexSwitchCheckDefault-${values.url}`}
                                                            checked={values.isPublished}
                                                            onChange={async (e) => {
                                                                e.stopPropagation();
                                                                await toggleJobOfferAPI(values.url ?? "");
                                                                const updatedValues = companyApplication.map((item) =>
                                                                    item.url === values.url
                                                                        ? { ...item, isPublished: !item.isPublished }
                                                                        : item
                                                                );
                                                                setCompanyApplication(updatedValues);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column align-items-center justify-content-center">
                                                    <h6>{t("created")}</h6>
                                                    <p>{new Date(values.createdDate!).toLocaleDateString()}</p>
                                                </div>
                                                <div className="d-flex flex-column align-items-center justify-content-center">
                                                    <h6>{t("urlDetails")}</h6>
                                                    <Button
                                                        variant="outline-primary"
                                                        size="sm"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            navigate(
                                                                "/jobs/" + (values as jobApplicationDTO).url
                                                            );
                                                        }}
                                                        className="d-flex align-items-center justify-content-center"
                                                    >
                                                        <AiOutlineEdit />
                                                        <span>{t("edit")}</span>
                                                    </Button>
                                                </div>
                                                <div className="d-flex flex-column align-items-center justify-content-center">
                                                    <Button
                                                        className="d-flex align-items-center justify-content-center"
                                                        variant="outline-danger"
                                                        size="sm"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setShowDeleteModal(true);
                                                            setJobToDelete(values);
                                                        }}
                                                    >
                                                        <AiOutlineDelete />
                                                        <span>{t("delete")}</span>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Card>
                        ))}
                        <ModalContent
                            title={t("confirmation")}
                            size="sm"
                            show={showDeleteModal}
                            onHideCallbackSecondary={() => setShowDeleteModal(false)}
                            onHideCallbackPrimary={() => handleDelete(jobToDelete?.url || '')}
                            primaryButton={t("delete")}
                            secondaryButton={t("cancel")}
                        >
                            <p>{t("deleteJobOfferAlertMsg")}</p>
                        </ModalContent>
                    </>
                )}
            </Accordion>
        </>
    );
}

interface AccordionProps {
    data: extendedJobOfferDTO[] | jobApplicationDTO[];
    alwaysOpen?: boolean;
}

JobAccordion.defaultProps = {
    alwaysOpen: false,
};
