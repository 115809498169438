import React from "react";
import { useTranslation } from "react-i18next";
import Mainmenu from "../../components/Menu/Mainmenu";
import Message from "./components/Message";

export default function EmailNotVerified() {
    const { t } = useTranslation();

    return (
        <>
            <Mainmenu />
            <h2>{t("emailNotVerifiedTitle")}</h2>
            <Message message={t("emailNotVerified")} redirectText={t("login")} redirectUrl={"/auth"} />
        </>
    )
}