import React from "react"
import ContentImprint from "./ContentImprint"
import LandingPageHeader from "../landingpage/components/LandingPageHeader"
import "../landingpage//components/LandingPageHeader.scss"

export default function Imprint() {
    return (
        <>
            <div className="appContainer">
                <LandingPageHeader />
                <ContentImprint />
            </div>
        </>
    )
}