import React, { createContext, useEffect, useState, ReactNode, Dispatch, SetStateAction } from 'react';
import * as signalR from '@microsoft/signalr';
import { urlChatSignalR } from '../config/endpoints';
import { getToken } from '../core/handleJWT';
import { messageDTO } from '../core/dto/dto.models';

// Define the context type
interface WebSocketContextType {
    connection: signalR.HubConnection | null;
    messageReceivedSignalR: messageDTO | null;
    messageReadSignalR: string | null;
    removeLastMessageSignalR: string | null;
    setMessageReceivedSignalR: Dispatch<SetStateAction<messageDTO | null>>;
    setMessageReadSignalR: Dispatch<SetStateAction<string | null>>;
    setRemoveLastMessageSignalR: Dispatch<SetStateAction<string | null>>;
}

// Create the context with default value as null
const WebSocketContext = createContext<WebSocketContextType | null>(null);

interface WebSocketProviderProps {
    children: ReactNode;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
    const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
    const [messageReceivedSignalR, setMessageReceivedSignalR] = useState<messageDTO | null>(null);
    const [messageReadSignalR, setMessageReadSignalR] = useState<string | null>(null);
    const [removeLastMessageSignalR, setRemoveLastMessageSignalR] = useState<string | null>(null);

    useEffect(() => {
        const connect = async () => {
            const connection = new signalR.HubConnectionBuilder()
                .withUrl(urlChatSignalR, { accessTokenFactory: () => getToken()! })
                .configureLogging(signalR.LogLevel.Information)
                .withAutomaticReconnect()
                .build();

            connection.on("ReceiveMessage", (message: messageDTO) => setMessageReceivedSignalR(message));
            connection.on("MarkChatAsRead", (chatId: string) => setMessageReadSignalR(chatId));
            connection.on("RemoveLastMessage", (chatId: string) => setRemoveLastMessageSignalR(chatId));

            connection.start().then(() => {
            }).catch((err: any) => console.error(err.toString()));

            setConnection(connection);
        };

        connect();

        return () => {
            if (connection) {
                connection.stop();
            }
        };
    }, []);

    return (
        <WebSocketContext.Provider value={{
            connection,
            messageReceivedSignalR,
            messageReadSignalR,
            removeLastMessageSignalR,
            setMessageReceivedSignalR,
            setMessageReadSignalR,
            setRemoveLastMessageSignalR,
        }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export default WebSocketContext;