import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { ReactElement, useState } from "react";
import { UserRoles } from "../config/Types/GeneralEnumDefinitions";
import { checkRole } from "../core/claimFunctions";
import AuthenticationContext from "./AuthenticationContext";

export default function Authorized(props: authorizedProps) {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const { claims } = useContext(AuthenticationContext);

    useEffect(() => {
        if (props.role) {
            // If role is set, then check if the user has the role
            setIsAuthorized(checkRole(claims, props.role));
        } else {
            // If role is not set, then check if the user is logged in
            setIsAuthorized(claims.length > 0);
        }
    }, [claims, props.role]);

    return (
        <>
            {isAuthorized ? props.authorized : props.notAuthorized}
        </>
    )
}

interface authorizedProps {
    authorized: ReactElement;
    notAuthorized?: ReactElement;
    role?: UserRoles;
}